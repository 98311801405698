import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  setBillNote,
  setBillNoteItemPrice,
  setBillNoteItemPriceWithTax,
  setBillNoteItems,
  setBillNoteLoading,
  setTaxIfNoTax,
  setTotalBillAmount,
} from "../../redux-store/Slices/billNoteSlice";
import dayjs from "dayjs";
import { setTaxes, setTaxesLoading } from "../../redux-store/Slices/taxSlice";
import { setCurrencies } from "../../redux-store/Slices/currencySlice";
import { toast } from "react-toastify";
import { setVendors } from "../../redux-store/Slices/vendorSlice";

const AddBillPage = () => {
  // const {loading} = useSelector((state)=> state.BILL_NOTE_SLICE)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const { currencies } = useSelector((state) => state.CURRENCY_SLICE);
  const { billNote, loading, billItems } = useSelector(
    (state) => state.BILL_NOTE_SLICE
  );
  const { taxes } = useSelector((state) => state.TAX_SLICE);
  const { vendors } = useSelector((state) => state.VENDOR_SLICE);
  const [stateTax, setStateTax] = useState([""]);
  const [stateTaxValue, setStateTaxValue] = useState([""]);
  const [submitting, setSUbmitting] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const deliveryNoteId = params.id;

  const setSubmittingTrue = () => {
    setSUbmitting(true);

    setTimeout(() => {
      setSUbmitting(false);
    }, 2500);
  };

  useEffect(() => {
    getAndSetDeliveryNote();
    getAndSetCurrencies();
    getAndSetVendors();
    getAndSetTaxes();
  }, []);

  useEffect(() => {
    setFormValues();
  }, [billNote]);

  const navigate = useNavigate();

  const getAndSetDeliveryNote = async () => {
    dispatch(setBillNoteLoading({ loading: true }));

    try {
      const axiosRes = await axios.get(
        `${BASE_URL}/api/get_list/delivery_note/${deliveryNoteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;
      if (success === true) {
        console.log(axiosRes.data.data[0]);
        dispatch(setBillNote(axiosRes.data.data[0]));
        dispatch(setBillNoteItems(axiosRes.data.data[0].items));
      }

      dispatch(setBillNoteLoading({ loading: false }));
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching delivery note:", error);
      dispatch(setBillNoteLoading({ loading: false }));
    }
  };

  const setFormValues = () => {
    console.log(billNote.customer);
    formik.setValues({
      ...formik.values,
      customer: billNote.customer?.name,
      dueDate: dayjs(billNote.due_date),
      delivery_note_id: billNote.id,
      product_id: billItems.map((item) => item.product_id),
      fileNo: billNote.file_no,
      soPo: billNote.smo_no,
      items: billItems,
      price: billItems.map((item) => item.price),
    });

    console.log("values setted");
  };

  const getAndSetTaxes = async () => {
    try {
      dispatch(setTaxesLoading({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/tax`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        dispatch(setTaxes({ taxes: axiosRes.data.data, isLoading: false }));
      }

      for (let tax of taxes) {
        setStateTax((prevState) => [...prevState, tax.name]);
        setStateTaxValue((prevState) => [...prevState, tax.tax_rate]);
      }
    } catch (err) {
      toast.warning(err.response.data.message);
    }
  };

  const getAndSetVendors = async () => {
    try {
      dispatch(setVendors({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        dispatch(setVendors({ vendors: axiosRes.data.data, isLoading: false }));
      }

      console.log(vendors);
    } catch (err) {
      toast.warning(err.response.data.message);
    }
  };

  const getAndSetCurrencies = async () => {
    dispatch(setCurrencies({ isLoading: true }));

    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/currency`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        await dispatch(
          setCurrencies({ currencies: axiosRes.data.data, isLoading: false })
        );
        console.log(currencies);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      dispatch(setCurrencies({ isLoading: false })); // Handle error state if needed
    }
  };

  const createBillNote = async (values) => {
    try {
      const {
        vendor,
        currency,
        boxNumber,
        billDate,
        note,
        delivery_note_id,
        product_id,
        expense_category,
        price,
        tax,
      } = values;

      const products = product_id.map((productId, index) => {
        return {
          product_id: productId,
          tax: tax[index] ? tax[index] : [],
        };
      });

      const BILL_NOTE = {
        vendor_id: vendor,
        currency_id: currency,
        bill_date: billDate,
        delivery_note_id,
        box: boxNumber,
        note,
        expense_category,
        products,
        price,
      };

      console.log(BILL_NOTE);

      const axiosRes = await axios.post(
        `${BASE_URL}/api/store/bill_note`,
        BILL_NOTE,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;
      if (success === true) {
        console.log(axiosRes.data);
        navigate("/bills-list");
        toast.success("Created Purchase Bill Successfully");
      }
    } catch (err) {
      console.log(err);
      toast.warning(err.response.data.message);
      if (err.response.data.data.bill_date) {
        toast.warning("Bill Date is missing");
      } else if (err.response.data.data === "-Undefined index: tax") {
        toast.warning("Tax is missing");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      vendor: billNote.vendor_id,
      customer: billNote.customer?.name,
      currency: "",
      dueDate: billNote.due_date,
      fileNo: billNote.file_no,
      billDate: "",
      soPo: billNote.smo_no,
      boxNumber: "",
      note: "",
      // items: billItems,
      expense_category: [],
      price: [],
      tax: [],
    },
    onSubmit: (values) => {
      createBillNote(values);
      console.log(values);
    },
  });

  const handlePriceChange = (e, id, index, item) => {
    const price = e.target.value;

    dispatch(setBillNoteItemPrice({ itemId: id, price }));
    dispatch(setTaxIfNoTax({ itemId: id, tax: [0] }));
    formik.setFieldValue(`price.${index}`, price);
    // dispatch(setTotalBillAmount())
    console.log(item.price);

    console.log(price);
  };

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <Box padding={"2rem"}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <Box mb={"2rem"}>
                  <h1>Create a new Bill</h1>
                </Box>

                <Grid
                  container
                  spacing={1}
                  display="flex"
                  // justifyContent={"center"}
                >
                  <Grid item xs={"auto"}>
                    {/*    Box 1*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            // paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Customer
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                /*{...formik.getFieldProps('vendor')}*/
                                {...formik.getFieldProps("customer")}
                                disabled={true}
                                InputLabelProps={{ style: { color: "red" } }}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency (Purchase)
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <InputLabel>Currency</InputLabel>
                              <Select
                                id={"currency-select"}
                                required
                                {...formik.getFieldProps("currency")}
                                label="Currency"
                              >
                                {currencies &&
                                  currencies.map((item) => (
                                    <MenuItem value={item.id}>
                                      {item.currency_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            File NO#
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                {...formik.getFieldProps("fileNo")}
                                disabled={true}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 2*/}

                    <Box
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      mb={"15px"}
                    >
                      <Typography
                        variant={"h6"}
                        paddingLeft={"16px"}
                        paddingTop={"11px"}
                        paddingRight={"13px"}
                      >
                        Vendor
                      </Typography>
                      <Box minWidth={"170px"}>
                        <FormControl fullWidth>
                          <InputLabel>Vendor</InputLabel>
                          <Select
                            required={true}
                            id={"vendor-select123"}
                            {...formik.getFieldProps("vendor")}
                            label="vendor"
                          >
                            {vendors &&
                              vendors.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <Box display={"flex"} justifyContent={"space-around"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="due-date"
                              defaultValue={dayjs(formik.values.dueDate).format(
                                `YYYY-DD-MM`
                              )}
                              disabled={true}
                            />
                          </Box>
                        </Box>

                        <Box display={"flex"} paddingLeft={"7px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Bill Date
                          </Typography>
                          <Box width={"200px"}>
                            <FormControl fullWidth required={true}>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    required={true}
                                    id={"bill-date"}
                                    onChange={(newValue) => {
                                      formik.setFieldValue(
                                        "billDate",
                                        dayjs(newValue).format("YYYY-MM-DD")
                                      );
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingTop={"15px"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"12px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            P.O./S.O.
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}

                              <TextField
                                disabled
                                id="sopo-no"
                                label="SO/PO"
                                variant="outlined"
                                {...formik.getFieldProps("soPo")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 3*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Box #
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="box-number"
                                required={true}
                                label=""
                                variant="outlined"
                                {...formik.getFieldProps("boxNumber")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingLeft={"10px"}
                          paddingTop={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Note
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="bill-note"
                                required={true}
                                label=""
                                variant="outlined"
                                multiline
                                rows={"5"}
                                {...formik.getFieldProps("note")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box></Box>
              </Box>
            </Box>

            <Box
              mt={"8rem"}
              display={"flex"}
              justifyContent={"center"}
              marginBottom={"10rem"}
            >
              <Box overflow={"scroll"}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Expense Category(Purchase)</td>
                      <td>Description</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                      <td>Old Price</td>
                      <td>Price</td>
                      <td>Tax</td>
                      <td>Amount</td>
                      <td>Amount After Taxes</td>
                    </tr>
                  </thead>

                  <tbody>
                    {billItems &&
                      billItems.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            <TextField
                              id={`expense-category-${row.id}`}
                              required={true}
                              sx={{ width: 200 }}
                              type="text"
                              value={row.expense_category || null}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.expense_category`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `expense_category.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-description-${row.id}`}
                              minRows={2}
                              sx={{ width: 230 }}
                              multiline
                              value={row.description}
                              disabled
                              maxRows={10}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-${row.id}`}
                              value={row.qty}
                              disabled
                              sx={{ width: 80 }}
                              type="number"
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-type-${row.id}`}
                              value={row.qty_type}
                              disabled
                              sx={{ width: 80 }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`old-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          <td>
                            <TextField
                              id={`item-price-${row.id}`}
                              label="price"
                              sx={{ width: 80 }}
                              type="number"
                              defaultValue={0}
                              onChange={(e) => {
                                handlePriceChange(e, row.id, index, row);
                                dispatch(setTotalBillAmount());
                                dispatch(
                                  setTaxIfNoTax({ itemId: row.id, tax: 0 })
                                );
                              }}
                            />
                          </td>
                          <td>
                            {/*<Autocomplete*/}
                            {/*    options={taxes.map((tax) => tax.name)}*/}
                            {/*    sx={{ width: 240 }}*/}
                            {/*    renderInput={(params) => <TextField {...params} label="Choose" />}*/}
                            {/*    onChange={(event, value) => {*/}
                            {/*      if (value) {*/}
                            {/*        setFormValues(`tax.${index}`, value);*/}
                            {/*      } else {*/}
                            {/*        // Handle case where no value is selected (optional)*/}
                            {/*        setFormValues(`tax.${index}`, '');*/}
                            {/*      }*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <Autocomplete
                              multiple
                              disablePortal
                              key={`tax-autocomplete-${row.id}`}
                              options={taxes.map((tax, index) => ({
                                label: `${tax.name} (${tax.tax_rate}%)`,
                                value: tax.tax_rate,
                                id: tax.id,
                                key: `tax-${index}`,
                              }))}
                              sx={{ width: 240 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Choose" />
                              )}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newValue) => {
                                dispatch(
                                  setBillNoteItemPriceWithTax({
                                    itemId: row.id,
                                    tax: newValue.map((option) =>
                                      Number(option.value)
                                    ),
                                  })
                                );
                                formik.setFieldValue(
                                  `tax.${index}`,
                                  newValue.map((option) => Number(option.id))
                                );
                                dispatch(setTotalBillAmount());
                                // Update tax field with selected tax IDs
                              }}
                            />
                          </td>

                          <td style={{ marginLeft: "10px" }}>
                            <p>$ {row.Totalprice ? row.Totalprice : "0.00"}</p>
                          </td>

                          <td style={{ marginLeft: "10px" }}>
                            <p>
                              $ {row.priceAfterTax ? row.priceAfterTax : 0.0}
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <Box mr={"1rem"}>
                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>SubTotal:</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}

                  <Box display={"flex"} justifyContent={"end"}>
                    <p>Total Amount: $</p>
                    <p>{billNote.totalAmount ? billNote.totalAmount : 0.0}</p>
                  </Box>

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>Total (EUR at 0.0002351439)</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
              <Button type={"submit"}>Submit</Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};
export default AddBillPage;
