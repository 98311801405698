import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    taxes:[],
    isLoading:false
}
    
   



const taxSlice = createSlice({
    name:'TAX-SLICE',
    initialState,
    reducers:
    {
        setTaxes:(state,action)=>{
            const {taxes,isLoading} = action.payload

            state.taxes = taxes
            state.isLoading = isLoading
            
        },

        setTaxesLoading:(state,action)=>{
            const {isLoading} = action.payload

            state.isLoading = isLoading

        },

        addtax:(state,action)=>{
            const newTax = action.payload
            state.taxes.push(newTax)
            console.log(newTax);
        },
        deletetax:(state,action)=>{
            const idToRemove = action.payload
           state.taxes = state.taxes.filter((tax) => tax.id !== idToRemove)
            console.log(state.taxes);
            console.log(idToRemove);
        },
        updatetax: (state, action) => {
            const updatedTax = action.payload;
            state.taxes = state.taxes.map((tax) =>
              tax.id === updatedTax.id ? updatedTax : tax
            );
            console.log(updatedTax);
          },
    }
})

export const {setTaxes,setTaxesLoading,addtax,deletetax,updatetax} = taxSlice.actions

export default taxSlice.reducer