import {
  Box,
  Button,

  Typography,

  Tabs,
  Tab,
  Stack,
  ButtonGroup,
} from "@mui/material";


import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import GridData from "../../Components/GridData";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import ConformDeleteModal from "../../Components/ConformDeleteModal";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {toast} from "react-toastify";

const CustomerStatementPage = () => {

  const BASE_URL = process.env.REACT_APP_BASE_URL
  const {token} = useSelector((state)=> state.USER_SLICE)

  const [value, setValue] = React.useState(0);

  const [rows, setRows] = useState([]);

  const getAndSetDeliveryNotes = async () =>{
    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      const deliveryNotes = axiosRes.data.data

      for(let note of deliveryNotes){
        const {id,smo_no,vendor,created_at,due_date,file_no} = note
        const rows1 = {
          id,
          vendor_name:vendor.name,
          file_no:'7',
          total_statements:'14',
          approved:'7',
          status:'12'
        }

        setRows(preRows => [...preRows,rows1])
      }
    }catch (err){
      console.log(err)
      toast.warning('Something went wrong on Backend')

    }



  }

  useEffect(() => {
    getAndSetDeliveryNotes()

  }, []);

  const columns = [

    {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
    {field: 'vendor_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
    {field: 'total_statements', headerName: 'Total Statements',headerClassName:'columnCSS', width: 150},
    {field: 'file_no', headerName: 'Draft',headerClassName:'columnCSS', width: 110},

    {field: 'approved', headerName: 'Approve',headerClassName:'columnCSS', width: 100},


    // {field: 'AmountDue', headerName: 'Amount Date',headerClassName:'columnCSS', width: 160,},
    {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 260,
      renderCell: (params) => (
          <ButtonGroup>

            <Button onClick={()=> console.log(params.row.id)}>
              <LaunchIcon/>
            </Button>

          </ButtonGroup>
      ),
    }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let navigate = useNavigate()

  let NavigateToCreateInvoice = () =>{
    navigate('/create-invoice')
  }
  return (
      <>
        <Box display={"flex"} justifyContent={"center"}>
          <Stack width={{ xs: "95%", md: "95%" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={"33px"}>
                Customer Statements
              </Typography>

            </Box>

            {/*<Box sx={{ width: "100%", bgcolor: "background.paper" }}>*/}
            {/*  <Tabs value={value} onChange={handleChange} centered>*/}
            {/*    <Tab label="Active" />*/}
            {/*    <Tab label="Draft" />*/}
            {/*    <Tab label="All" />*/}
            {/*  </Tabs>*/}
            {/*</Box>*/}

            <Box paddingTop={'3.45rem'}>
              <GridData rowsP={rows} columnsP={columns}/>
            </Box>

          </Stack>
        </Box>
      </>
  );
};

export default CustomerStatementPage;
