import React, {useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Tab, Tabs, Typography} from "@mui/material";
import GridData from "../../Components/GridData";
import '../../App.css'
import {useNavigate, useParams} from "react-router-dom";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {useSelector} from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import ConformDeleteModal from "../../Components/ConformDeleteModal";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {toast} from "react-toastify";
import ConformApprovePurchaseBill from "./ConformApprovePurchaseBill";

const BillListPage = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=> state.USER_SLICE)
    const navigate = useNavigate()


    const [rows, setRows] = useState([]);

    const [value, setValue] = React.useState(0);
    const [runagain, setRunAgain] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState("all");

    const handleFilterChange = (newValue) =>{
        setFilterValue(newValue)
        console.log(newValue)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getAndSetPurchaseBills = async () =>{
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            if(success){
                let deliveryNotes = axiosRes.data.data

                deliveryNotes = deliveryNotes.filter(item => item.billorder !== null )
                console.log(deliveryNotes)
                    setRows([])
                for(let note of deliveryNotes){
                    const {id,customer,vendor,created_at,due_date,file_no,billorder} = note
                    if (billorder.status === "Active"){
                        billorder.status = "Approved"
                    }
                    // const VendorName = vendor.name?vendor.name:'No vendor yet'
                    const rows1 = {
                        id,
                        currency: billorder.currency_item ? billorder.currency_item.currency_name : 'null',
                        vendor_name:billorder?.vendor?.name,
                        created_at:dayjs(created_at).format(`YYYY-MM-DD`),
                        due_date,
                        file_no,
                        status:billorder.status,
                        customer_name:customer.name,
                        bill_id:billorder.id
                    }

                    setRows(preRows => [...preRows,rows1])
                    setRunAgain(false)
                }
            }

        } catch (err){
            console.log(err)
            // const errmsg = err.response.data.message
            // if (errmsg === 'DeliveryNote not found.'){
                toast.warning('No Purchase Bill Found')
            // }
        }



    }

    useEffect(() => {
        getAndSetPurchaseBills()

    }, [runagain]);

    const filteredRows = () => {
        if (filterValue === 'all') {
            return rows;
        } else {
            return rows.filter(row => row.status.toLowerCase() === filterValue);
        }
    };

    const columns = [

        {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
        {field: 'customer_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
        {field: 'vendor_name', headerName: 'Vendor',headerClassName:'columnCSS', width: 140,},
        {field: 'created_at', headerName: 'Date-Created',headerClassName:'columnCSS', width: 130},
        {field: 'currency', headerName: 'Currency',headerClassName:'columnCSS', width: 110},
        {field: 'file_no', headerName: 'File No',headerClassName:'columnCSS', width: 115},

        {field: 'due_date', headerName: 'Date',headerClassName:'columnCSS', width: 110},
        {field: 'status', headerName: 'Status', width: 110,
            cellClassName:(params)=>{
                if (params.value === 'Draft'){
                    return 'custom-status-draft'
                }else if(params.value === 'Approved'){
                    return 'custom-status-approved'
                }
                return '123123123'
            }
        },

        {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 270,
            renderCell: (params) => (
                <ButtonGroup>

                        <ConformApprovePurchaseBill
                            btn={'Approve'}
                            text={`Approve Purchase Bill With ID ${params.row.id} ?`}
                            btnText={`Approve Purchase Bill`}
                            noteID={params.row.bill_id}
                            runAgain={setRunAgain}
                            disableOrNot={params.row.status !== 'Draft'}
                            // method={}
                        />



                    <Button onClick={()=> {navigate(`/bills/view/${params.row.id}`)}}>
                        <LaunchIcon/>
                    </Button>

                    <Button  onClick={() => console.log(params.row.id)}>
                        <PictureAsPdfIcon sx={{color:'red'}}/>
                    </Button>
                </ButtonGroup>
            ),
        }
    ];

    return (
        <>
            <Box padding={'2rem'}>
                <Box display={"flex"} justifyContent={"start"}>
                    <Typography variant={'h3'}>
                        Purchase Bills
                    </Typography>


                    {/*<Button variant={"contained"} onClick={toAddBill}>*/}
                    {/*    Create Bill*/}
                    {/*</Button>*/}
                </Box>

                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                    >

                        <Tab label="All" onClick={() => handleFilterChange('all')} />
                        <Tab label="Draft" onClick={() => handleFilterChange('draft')} />
                        <Tab label="Approved" onClick={() => handleFilterChange('approved')} />
                    </Tabs>
                </Box>
                <Box paddingTop={'3.45rem'}>
                    <GridData rowsP={filteredRows()} columnsP={columns}/>
                </Box>

            </Box>
        </>
    )
}
export default BillListPage
