import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Overdue = () => {
  const invoices = [
    { name: "arab drilling & working company", bill: 300 },
    { name: "arab drilling & working company", bill: 500 },
    { name: "arab drilling & working company", bill: 600 },
    { name: "arab drilling & working company", bill: 200 },

  ];


  const Overdue = [
    { name: "blue sky purchase", bill: 300 },
    { name: "blue sky purchase", bill: 300 },
    { name: "blue sky purchase", bill: 300 },
    { name: "blue sky purchase", bill: 300 },
    { name: "blue sky purchase", bill: 300 },
 
  ];

  const state2 = {
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const [pie, setpie] = useState(state2);

  return (
    <Box maxWidth={"1400px"} px={3} mt={3}>
      <Typography variant="p" fontWeight={700} fontSize={"24px"}>
        Overdue invoices and Bills
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              border: "1px solid #00000063",
              borderRadius: "20px",
              padding:'5px'
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                padding: "7px",
              }}
            >
              <Typography
                variant="p"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"19px"}
              >
                Overdue invoices
              </Typography>

              <Typography
                variant="p"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"19px"}
                color={"blue"}
              >
                View
              </Typography>
            </Box>

            <ul>
              {invoices &&
                invoices.map((invoice) => (
                  <>
                    <li
                      key={invoice}
                      style={{
                        color: "blue",
                        textTransform: "capitalize",
                        fontSize: "20px",
                      }}
                    >
                      {invoice.name}
                    </li>
                    <Typography>${invoice.bill}</Typography>
                  </>
                ))}
            </ul>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                padding: "7px",
                marginTop:'20px'
              }}
            >
              <Typography
                variant="p"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"19px"}
              >
                Overdue Bills
              </Typography>

              <Typography
                variant="p"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"19px"}
                color={"blue"}
              >
                View
              </Typography>
            </Box>

            <ul>
              {Overdue &&
                Overdue.map((bill) => (
                  <>
                    <li
                      key={bill}
                      style={{
                        color: "blue",
                        textTransform: "capitalize",
                        fontSize: "20px",
                      }}
                    >
                      {bill.name} <span style={{color:'black'}}>   ${bill.bill}</span>
                    </li>
                    
                  </>
                ))}
            </ul>

          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box >
            <ReactApexChart
              options={pie.options}
              series={pie.series}
              type="pie"
              height={"500px"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overdue;
