import React from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography,} from "@mui/material";
import {useFormik} from "formik";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const AddUserPage = () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const {token} = useSelector((state)=> state.USER_SLICE)
    const navigate = useNavigate()

    const registerUser = async (values) =>{

        try{

            const {name,email,password,role,c_password} = values
            // const NEW_USER = {
            //     name,
            //     email,
            //     password,
            //     role,
            //     c_password
            // }
            const axiosRes = await axios.post(`${BASE_URL}/api/register?name=${name}&email=${email}&password=${password}&c_password=${c_password}&role_id=${role}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            const {success} = axiosRes.data
            if(success){
                navigate('/user-list')
                toast('Added new User Successfully')
            }else{
                toast('something went wrong try again')
            }
        }
        catch (err){
            console.log(err)
            const errorData = err.response.data.data;
            Object.keys(errorData).forEach(key => {
                const errorMessages = errorData[key];
                errorMessages.forEach(errorMessage => {
                    toast.warning(`${key}: ${errorMessage}`);
                });
            });
        }
    }


    const formik = useFormik({
        initialValues:{
            name:'',
            email:'',
            password:'',
            c_password:'',
            role:''
        },
        onSubmit:(values)=>{
            if(formik.values.password === formik.values.c_password){
                registerUser(values)
            }else {
                toast.warning('Password and confirm password do not match')
            }
        }
    })
    return (
        <>
            <Box mt={5} pl={5} mb={'5rem'}>
                <Box>
                    <Typography fontSize={"27px"} fontWeight={600}>
                        New User
                    </Typography>
                </Box>

                <Box borderTop={"1px solid black"} pt={'1rem'}>

                    <form onSubmit={formik.handleSubmit}>
                        <Box>
                            <Stack
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={1}
                                flexDirection={"column"}

                            >
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Name
                                    </Typography>
                                    <TextField
                                        id="user-Name"
                                        label="User Name"
                                        type="text"
                                        fullWidth={true}
                                        required
                                        {...formik.getFieldProps('name')}

                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Email
                                    </Typography>
                                    <TextField
                                        id="user-email"
                                        label="Email"
                                        type="text"
                                        required
                                        {...formik.getFieldProps('email')}
                                    />

                                </Box>
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        password
                                    </Typography>
                                    <TextField
                                        id="user-phone"
                                        label="Password"
                                        type="text"
                                        required
                                        {...formik.getFieldProps('password')}
                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                       Confirm password
                                    </Typography>
                                    <TextField
                                        id="user-phone"
                                        label="Confirm Pass."
                                        type="text"
                                        required
                                        {...formik.getFieldProps('c_password')}
                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Role
                                    </Typography>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Role"
                                            sx={{minWidth:'210px'}}
                                            name="role"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}

                                        >
                                            <MenuItem value={'1'}>Admin</MenuItem>
                                            <MenuItem value={'2'}>Senior Auditor</MenuItem>
                                            <MenuItem value={'3'}>Secretary</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Box>

                                {/*<Box sx={{display: {sm: "block", md: "block"}}}>*/}
                                {/*    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>*/}
                                {/*        Status*/}
                                {/*    </Typography>*/}
                                {/*    <FormControl>*/}
                                {/*        <InputLabel id="demo-simple-select-label">Status</InputLabel>*/}
                                {/*        <Select*/}
                                {/*            labelId="demo-simple-select-label"*/}
                                {/*            id="demo-simple-select"*/}
                                {/*            label="Role"*/}
                                {/*            sx={{minWidth:'210px'}}*/}
                                {/*            defaultValue={'secretary'} // or value={'secretary'} if you're controlling it with state*/}
                                {/*        >*/}
                                {/*            <MenuItem value={'secretary'}>Active</MenuItem>*/}
                                {/*            <MenuItem value={'Admin'}>Inactive</MenuItem>*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*</Box>*/}

                                <Box>
                                    <Button type={"submit"}>
                                        Save User
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </form>

                </Box>
            </Box>
        </>
    );
};

export default AddUserPage;
