import {
    Box,
    Button,

    Typography,

    Tabs,
    Tab,
    Stack,
    ButtonGroup,
} from "@mui/material";


import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import GridData from "../../Components/GridData";
import axios from "axios";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import ConformDeleteModal from "../../Components/ConformDeleteModal";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {toast} from "react-toastify";

const CompletedInvoicesList = () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=> state.USER_SLICE)

    const [value, setValue] = React.useState(0);

    const [rows, setRows] = useState([]);

    const getAndSetDeliveryNotes = async () =>{
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            let deliveryNotes = axiosRes.data.data
            deliveryNotes = deliveryNotes.filter(item => item.invoice?.status === 'Active')
            for(let note of deliveryNotes){
                const {id,smo_no,billorder,invoice,created_at,due_date,file_no} = note
                if (invoice.status === "Active"){
                    invoice.status = "Approved"
                }
                const rows1 = {
                    id,
                    smo_no,
                    vendor_name:billorder.vendor.name,
                    created_at:dayjs(created_at).format(`YYYY-MM-DD`),
                    due_date,
                    file_no,
                    status:invoice.status
                }

                setRows(preRows => [...preRows,rows1])
            }
        }catch (err){
            console.log(err)
            toast.warning('Something went wrong on Backend')
        }



    }

    useEffect(() => {
        getAndSetDeliveryNotes()

    }, []);

    const columns = [

        {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
        {field: 'vendor_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
        {field: 'created_at', headerName: 'Date-Created',headerClassName:'columnCSS', width: 130},
        {field: 'smo_no', headerName: 'SMO-No#',headerClassName:'columnCSS', width: 110},
        {field: 'file_no', headerName: 'File N0',headerClassName:'columnCSS', width: 110},

        {field: 'due_date', headerName: 'Date',headerClassName:'columnCSS', width: 100},
        {field: 'status', headerName: 'Status', width: 130,
            cellClassName:(params)=>{
                if (params.value === 'Draft'){
                    return 'custom-status-draft'
                }else if(params.value === 'Approved'){
                    return 'custom-status-approved'
                }
                return '123123123'
            }
        },

        // {field: 'AmountDue', headerName: 'Amount Date',headerClassName:'columnCSS', width: 160,},
        {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 260,
            renderCell: (params) => (
                <ButtonGroup>
                    <Button onClick={()=> console.log(params.row.id)}>
                        <LaunchIcon/>
                    </Button>

                    <Button  onClick={() => console.log(params.row.id)}>
                        <PictureAsPdfIcon sx={{color:'red'}}/>
                    </Button>
                </ButtonGroup>
            ),
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let navigate = useNavigate()

    let NavigateToCreateInvoice = () =>{
        navigate('/create-invoice')
    }
    return (
        <>
            <Box display={"flex"} justifyContent={"center"}>
                <Stack width={{ xs: "95%", md: "95%" }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography fontSize={"33px"}>
                            Completed Invoices List
                        </Typography>

                    </Box>

                    <Box paddingTop={'3.45rem'}>
                        <GridData rowsP={rows} columnsP={columns}/>
                    </Box>

                    {/*<Box*/}
                    {/*    display={"flex"}*/}
                    {/*    mt={5}*/}
                    {/*    justifyContent={"center"}*/}
                    {/*    alignItems={"center"}*/}
                    {/*    flexDirection={"column"}*/}
                    {/*>*/}
                    {/*    <Typography variant="h5" fontWeight={500}>*/}
                    {/*        Don't Underestimate it -- Create a new Invoice*/}
                    {/*    </Typography>*/}



                    {/*    <Button sx={{ borderRadius: "20px",marginTop:'2rem' }}*/}
                    {/*            variant="outlined"*/}
                    {/*            onClick={NavigateToCreateInvoice}*/}
                    {/*    >*/}
                    {/*        Create Invoice*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </Stack>
            </Box>
        </>
    );
};

export default CompletedInvoicesList;
