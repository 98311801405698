import React from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography,} from "@mui/material";
import {useFormik} from "formik";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const AddCustomer = () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const {token} = useSelector((state)=> state.USER_SLICE)
    const navigate = useNavigate()

    const addCustomer = async (values) =>{
        try {

            const {name,full_name,email,phone,addition_info} = values

            const NEW_CUSTOMER = {
                name,
                email,
                full_name,
                phone,
                addition_info
            }
            const axiosRes = await axios.post(`${BASE_URL}/api/customer`,NEW_CUSTOMER,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            if(success){
                navigate(`/customers`)
                toast.info('Added New Customer Successfully')
            }else {
                toast.warning('Something went wrong please try again')
            }
        }catch (err){
            console.log(err)
            toast('Something went wrong please try again')
        }

    }

    const formik = useFormik({
        initialValues:{
            name:'',
            full_name:'',
            email:'',
            phone:'',
            // account_number:'',
            // currency:'',
            addition_info:'',
        },
        onSubmit:(values)=>{
            console.log(values)
            addCustomer(values)
        }
    })
    return (
        <>
            <Box mt={5} pl={5}>
                <Box>
                    <Typography fontSize={"27px"} fontWeight={600}>
                        New Customer
                    </Typography>
                </Box>

                <Box borderTop={"1px solid black"}>
                    <Typography>Basic Information</Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <Box>
                            <Stack
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={1}
                                flexDirection={"column"}

                            >
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Customer
                                    </Typography>
                                    <TextField
                                        id="customer-Name"
                                        label="Customer Name"
                                        type="text"
                                        fullWidth={true}
                                        required
                                        {...formik.getFieldProps('name')}

                                    />
                                </Box>

                                <Box sx={{display: 'block'}} minWidth={'150px'}>
                                    <Typography flexWrap={"wrap"} variant={'h6'} textAlign={"center"} mt={"4.5%"}
                                                mx={'10px'}>
                                        Full Name
                                    </Typography>
                                    <TextField
                                        id="customer-full-name"
                                        label=" Full Name"
                                        type="text"
                                        required
                                        {...formik.getFieldProps('full_name')}
                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Email
                                    </Typography>
                                    <TextField
                                        id="Vendor-email"
                                        label="Email"
                                        type="text"
                                        required
                                        {...formik.getFieldProps('email')}
                                    />

                                </Box>
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Phone
                                    </Typography>
                                    <TextField
                                        id="customer-phone"
                                        label="Phone No."
                                        type="text"
                                        required
                                        {...formik.getFieldProps('phone')}
                                    />
                                </Box>

                                {/*<Box sx={{display: {sm: "block", md: "block"}}}>*/}
                                {/*    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>*/}
                                {/*        Account Number*/}
                                {/*    </Typography>*/}
                                {/*    <TextField*/}
                                {/*        id="Account-Number"*/}
                                {/*        label="Account-Number"*/}
                                {/*        type="text"*/}
                                {/*        required*/}
                                {/*        {...formik.getFieldProps('account_number')}*/}
                                {/*    />*/}
                                {/*</Box>*/}


                                {/*<Box sx={{display: {sm: "block", md: "block"}, minWidth: '200px'}}>*/}
                                {/*    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>*/}
                                {/*        Currency*/}
                                {/*    </Typography>*/}
                                {/*    <Select*/}
                                {/*        sx={{minWidth: '200px'}}*/}
                                {/*        onChange={(e)=>{*/}
                                {/*            formik.setFieldValue('currency', e.target.value || '')*/}
                                {/*        }}*/}
                                {/*        value={formik.values.currency}*/}
                                {/*    >*/}
                                {/*        <MenuItem value={'Dollar'}>Dollar</MenuItem>*/}
                                {/*        <MenuItem value={'EURO'}>EURO</MenuItem>*/}
                                {/*        <MenuItem value={'Dinar'}>Dinar</MenuItem>*/}
                                {/*        <MenuItem value={'Pound'}>Pound</MenuItem>*/}
                                {/*    </Select>*/}
                                {/*</Box>*/}


                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Additional Info
                                    </Typography>
                                    <TextField
                                        sx={{display: 'block', marginTop: '10px', marginBottom: '10px'}}
                                        label=""
                                        multiline
                                        rows={4}
                                        {...formik.getFieldProps('addition_info')}
                                    />

                                </Box>

                                <Box>
                                    <Button type={"submit"}>
                                        Save Customer
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </form>



                </Box>


            </Box>
        </>
    );
};

export default AddCustomer;
