import * as React from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { updateCurrency } from "../redux-store/Slices/currencySlice";
import {useEffect} from "react";

const style = {
  position: "absolute",
  top: "10%",
  left: "35%",
//   transform: "translate(50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000000a6",
  boxShadow: 24,
  p: 4,
  borderRadius: "27px",
  height:'70vh',
  overflow:'scroll'
};

const CurrencyUpdateModal = (props) => {
  const { btn,fetchedCurrency ,method,text,currencyID} = props;
  useEffect(() => {
    setValues()
  }, [fetchedCurrency]);
    const dispatch = useDispatch()
    


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


const {currency_name,abbreviation,bank_name,bank_address,iban, swift_code,sort_code,accountant,code,beneficiary_name,beneficiary_address,id} = fetchedCurrency


  const formik = useFormik({
    initialValues: {
      currency_name,
      abbreviation,
      bank_name,
      bank_address,
      iban,
      swift_code,
      sort_code,
      accountant,
      code,
      beneficiary_name,
      beneficiary_address,
      id,
    },

    onSubmit: (values) => {
      let aCurrency = values;
      method(aCurrency,currencyID);
      console.log(`currency update with id ${currencyID}`);
      console.log(aCurrency)
      dispatch(updateCurrency(aCurrency))
    console.log(fetchedCurrency);
      handleClose()
    },
  });


const setValues = () =>{
  formik.setValues({
    currency_name,
    abbreviation,
    bank_name,
    bank_address,
    iban,
    swift_code,
    sort_code,
    accountant,
    code,
    beneficiary_name,
    beneficiary_address,
    id:currencyID
  })

  console.log('hello')
}
  


  return (
    <div>
      <Button onClick={handleOpen}>{btn}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{height:'100vh',overflow:'scroll'}}
      >
        <Box sx={style}>
          <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>{text}</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Currency Name
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("currency_name")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"h6"} padding={"15px"}>
                          Currency Abbrivation
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("abbreviation")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          variant={"h6"}
                          mt={"15px"}
                          textAlign={"center"}
                        >
                          Bank Details
                        </Typography>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Bank Name
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("bank_name")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Bank Address
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("bank_address")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            IBAN
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("iban")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Swift Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("swift_code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Sort Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("sort_code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Account Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("accountant")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        
                        
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Benifishry Name
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("beneficiary_name")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Benifishry Address
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("beneficiary_address")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  ></Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 3*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  ></Box>
                </Grid>
              </Grid>

              <Box></Box>
            </Box>
            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
              <Button type={"submit"}>Update</Button>
            </Box>
          </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CurrencyUpdateModal;
