import React from "react";
import ReportCard from "../Components/ReportCard";
import { Box, Grid } from "@mui/material";
import GridData from "../Components/GridData";
const ReportsPage = () => {
  return (
    <>
      <Box mt={"5rem"} padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={8}>
            <ReportCard />
            <ReportCard />
            <ReportCard />
            <ReportCard />
            <ReportCard />
            <ReportCard />
            <Box mt={2}>

            <GridData />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReportsPage;
