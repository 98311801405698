import React from "react";
import {Box, Button, Stack, TextField, Typography,} from "@mui/material";
import {useFormik} from "formik";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const AddCustomer = () => {


    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const {token} = useSelector((state)=> state.USER_SLICE)
    const navigate = useNavigate()
    const createVendor = async (values)=>{
        const {vendor,fullName,email,phone,additionalInfo} = values

        const NEW_VENDOR = {
            name:vendor,
            full_name:fullName,
            email,
            phone,
            addition_info:additionalInfo
        }

        try {
            const axiosRes = await axios.post(`${BASE_URL}/api/vendor`,NEW_VENDOR,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = await axiosRes.data
            if(success === true) {
                navigate('/vendors')
                toast.success('Vendor Added Successfully')
            }else if (success === false){
                toast.info('please make sure values are correct')
            }
        }catch (err){
            const errorData = err.response.data.data;
            Object.keys(errorData).forEach(key => {
                const errorMessages = errorData[key];
                errorMessages.forEach(errorMessage => {
                    toast.warning(`${key}: ${errorMessage}`);
                });
            });
        }
    }

    const formik = useFormik({
        initialValues:{
            vendor:'',
            fullName:'',
            email:'',
            phone:'',
            additionalInfo:''
        },
        onSubmit:(values)=>{
            createVendor(values)
        }
    })
    return (
        <>
            <Box mt={5} pl={5} mb={'5rem'}>
                <Box>
                    <Typography fontSize={"27px"} fontWeight={600}>
                        New Vendor
                    </Typography>
                </Box>

                <Box borderTop={"1px solid black"} pt={'1rem'}>

                    <form onSubmit={formik.handleSubmit}>
                        <Box>
                            <Stack
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={1}
                                flexDirection={"column"}

                            >
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Vendor
                                    </Typography>
                                    <TextField
                                        id="customer-Name"
                                        label="Vendor Name"
                                        type="text"
                                        fullWidth={true}
                                        required
                                        {...formik.getFieldProps('vendor')}

                                    />
                                </Box>

                                <Box sx={{display: 'block'}} minWidth={'150px'}>
                                    <Typography flexWrap={"wrap"} variant={'h6'} textAlign={"center"} mt={"4.5%"}
                                                mx={'10px'}>
                                        Full Name
                                    </Typography>
                                    <TextField
                                        id="customer-full-name"
                                        label="Phone No."
                                        type="text"
                                        required
                                        {...formik.getFieldProps('fullName')}
                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Email
                                    </Typography>
                                    <TextField
                                        id="Vendor-email"
                                        label="Email"
                                        type="text"
                                        required
                                        {...formik.getFieldProps('email')}
                                    />

                                </Box>
                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Phone
                                    </Typography>
                                    <TextField
                                        id="customer-phone"
                                        label="Phone No."
                                        type="text"
                                        required
                                        {...formik.getFieldProps('phone')}
                                    />
                                </Box>

                                <Box sx={{display: {sm: "block", md: "block"}}}>
                                    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                        Additional Info
                                    </Typography>
                                    <TextField
                                        sx={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}
                                        label="Text"
                                        multiline
                                        rows={4}
                                        {...formik.getFieldProps('additionalInfo')}
                                    />

                                </Box>

                                <Box>
                                    <Button type={"submit"}>
                                        Save Vendor
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </form>

                </Box>
            </Box>
        </>
    );
};

export default AddCustomer;
