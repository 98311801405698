import React, {useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Tab, Tabs, Typography} from "@mui/material";
import GridData from "../../Components/GridData";
import '../../App'
import {useNavigate} from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LaunchIcon from '@mui/icons-material/Launch';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {toast} from "react-toastify";
import ConformApproveNoteModal from "./ConformApproveNoteModal";
import {setTaxes} from "../../redux-store/Slices/taxSlice";
import ConformDeleteDeliveryNote from "./ConformDeleteDeliveryNote";

const DeliveryNoteListPage = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=> state.USER_SLICE)
    const { taxes } = useSelector((state) => state.TAX_SLICE);
    const navigate = useNavigate()


    const [rows, setRows] = useState([]);
    const [value, setValue] = React.useState(0);
    const [filterValue, setFilterValue] = React.useState("all");
    const [runSetAgain, setRunSetAgain] = React.useState(false);
    
    const dispatch = useDispatch()

    const handleFilterChange = (newValue) =>{
        setFilterValue(newValue)
        console.log(newValue)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getAndSetTaxes = async () => {

        try {
            dispatch(setTaxes({ isLoading: true }));

            const axiosRes = await axios.get(`${BASE_URL}/api/tax`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                },
            });

            const { success } = axiosRes.data;
            if (success === true) {
                dispatch(setTaxes({ taxes: axiosRes.data.data, isLoading: false }));
                console.log(taxes)
            }
        }catch (err) {
            // console.log(err)
            // toast.warning(err.response.data.message)
        }

    };

    const getAndSetDeliveryNotes = async () =>{
        try{

            const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const deliveryNotes = axiosRes.data.data
            console.log(deliveryNotes)
            setRows([])

            for(let note of deliveryNotes){
                let {id,smo_no,customer,created_at,due_date,file_no,status,approve_date} = note

                if (status === "Active"){
                    status = "Approved"
                }
                const rows1 = {
                    id:id?id:'888',
                    smo_no,
                    vendor_name:customer?customer.name:'Name less',
                    created_at:dayjs(created_at).format(`YYYY-MM-DD`),
                    due_date,
                    file_no,
                    status,
                    approve_date:approve_date?dayjs(approve_date
                    ).format(`YYYY-MM-DD`) : 'Not Approved yes'

                }

                setRows(preRows => [...preRows,rows1])
                setRunSetAgain(false)
            }
        }catch (err){
            console.log(err)
            const {message} = err.response.data
            toast.warning(message)
        }


    }


    const filteredRows = () => {
        if (filterValue === 'all') {
            return rows;
        } else {
            return rows.filter(row => row.status.toLowerCase() === filterValue);
        }
    };

    useEffect(() => {
        getAndSetDeliveryNotes()
        getAndSetTaxes()

    }, [runSetAgain]);

    const columns = [

        {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
        {field: 'vendor_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
        {field: 'file_no', headerName: 'File No',headerClassName:'columnCSS', width: 130},
        {field: 'smo_no', headerName: 'SMO-NO',headerClassName:'columnCSS', width: 100},
        {field: 'due_date', headerName: 'Due-Date',headerClassName:'columnCSS', width: 120},
        {field: 'approve_date', headerName: 'Approve-Date',headerClassName:'columnCSS', width: 120},

        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'columnCSS',
            width: 120,
            cellClassName: (params) => {


                // Check the original status value
                if (params.value === 'Draft') {
                    return 'custom-status-draft';
                } else if (params.value === 'Approved') {

                    return 'custom-status-approved';
                }
                return '123123123';
            }
        }
        ,
        {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 270,
            renderCell: (params) => (
                <ButtonGroup>
                    <ConformApproveNoteModal
                    btn={'Approve'}
                    text={`Approve Delivery Note With ID ${params.row.id} ?`}
                    btnText={`Approve Delivery Note`}
                    // method={updateDeliveryNoteStatus(params.row.id)}
                    noteID={params.row.id}
                    runAgain={setRunSetAgain}
                    status={params.row.status}
                    />

                    <Button onClick={()=> {navigate(`/delivery-note/${params.row.id}`)}}>
                        <LaunchIcon/>
                    </Button>

                    <Button  onClick={() => console.log(params.row.id)}>
                        <PictureAsPdfIcon sx={{color:'red'}}/>
                    </Button>


                        <ConformDeleteDeliveryNote
                            btn={<DeleteForeverIcon sx={{color:'red'}}/>}
                            text={`Delete Delivery Note With ID ${params.row.id} ?`}
                            btnText={`Delete Delivery Note`}
                            // method={updateDeliveryNoteStatus(params.row.id)}
                            noteID={params.row.id}
                            runAgain={setRunSetAgain}
                            status={params.row.status}
                        />

                </ButtonGroup>
            ),
        }
    ];



    const toCreateDeliveryNotePage = () =>{
        navigate('/add-delivery-note')
    }

    return (
        <>
            <Box padding={'2rem'}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={'h3'}>
                        Delivery Notes
                    </Typography>
                    <Button variant={"contained"} onClick={toCreateDeliveryNotePage}>
                        Create New Delivery
                    </Button>
                </Box>

                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                    >

                        <Tab label="All" onClick={() => handleFilterChange('all')} />
                        <Tab label="Draft" onClick={() => handleFilterChange('draft')} />
                        <Tab label="Approved" onClick={() => handleFilterChange('approved')} />
                    </Tabs>
                </Box>
                <Box paddingTop={'2rem'}>
                    <GridData
                        rowsP={filteredRows()}
                        columnsP={columns}
                    />
                </Box>

            </Box>
        </>
    )
}
export default DeliveryNoteListPage
