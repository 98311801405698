import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  currencies: [],
  isLoading: false,
};

const currencySlice = createSlice({
  name: "CURRENCY-SLICE",
  initialState,
  reducers: {
    setCurrencies: (state, action) => {

    const {currencies,isLoading} = action.payload

      state.currencies = currencies;
      state.isLoading = isLoading;
    },
    addCurrency:(state,action)=>{
      const newCurrency = action.payload
      state.currencies.push(newCurrency)
      console.log(newCurrency);
  },
    deleteCurrency:(state,action)=>{
      const idToRemove = action.payload
     state.currencies = state.currencies.filter((item) => item.id !== idToRemove)
      
      console.log(`${idToRemove} currency deleted`);
  },
  updateCurrency: (state, action) => {
    const newCurrency = action.payload;
    state.currencies = state.currencies.map((cur) =>
        cur.id === newCurrency.id ? newCurrency : cur
    )
    console.log('newCurrency');
  },
  },
});

export const { setCurrencies,addCurrency,deleteCurrency,updateCurrency } = currencySlice.actions;

export default currencySlice.reducer;
