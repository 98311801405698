import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedin: false,
  isLoading: true,
  name: "",
  token: "",
  role: {
    role_name: "Admin",
  },
};

const userSlice = createSlice({
  name: "USER-SLICE",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { name, token, isLoggedin, isLoading, role } = action.payload;
      console.log("action payload is", action.payload);

      const waveUser = {
        name,
        token,
        isLoggedin,
        isLoading,
        role: role,
      };

      console.log(waveUser);

      state.name = name;
      state.token = token;
      state.isLoggedin = isLoggedin;
      state.isLoading = isLoading;
      // state.role = role;

      sessionStorage.setItem("userData", JSON.stringify(waveUser));
    },
  },
});

const userDataFromStorage = JSON.parse(sessionStorage.getItem("userData"));
if (userDataFromStorage) {
  // console.log(userDataFromStorage)
  initialState.name = userDataFromStorage.name;
  initialState.token = userDataFromStorage.token;
  // initialState.role = userDataFromStorage.role;
  initialState.isLoggedin = true;
}

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
