import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const DeliveryNoteDetailPage = () => {
  const [deliveryNote, setDeliveryNote] = useState({});
  const [products, setProducts] = useState([]);
  console.log("🚀 ~ DeliveryNoteDetailPage ~ products:", products);
  const navigate = useNavigate();
  const params = useParams();
  const [customers, setCustomers] = useState([]);

  const { token } = useSelector((state) => state.USER_SLICE);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchCustomers = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const vendorArray = data.data;
      setCustomers(vendorArray);
      console.log(customers);
    } catch (err) {
      toast.info(err.response.data.message);
    }
  };

  const fetchProducts = async () => {
    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const { success } = axiosRes.data;
      if (success === true) {
        const productArray = axiosRes.data.data;
        setProducts(productArray);
      }
    } catch (err) {
      console.log(err);
      toast.info(err.response.data.message);
      // const errorData = err.response.data.data;
      // Object.keys(errorData).forEach(key => {
      //   const errorMessages = errorData[key];
      //   errorMessages.forEach(errorMessage => {
      //     toast.warning(`${key}: ${errorMessage}`);
      //   });
      // });
    }
  };

  const setInitialValues = (values) => {
    formik.setValues({
      customer_id: values.customer.id,
      dueDate: values.due_date,
      deliveryNumber: values.id,
      smoNumber: values.smo_no,
      fileNumber: values.file_no,
      // rows: [{ id: 1, description: "", qty: 0, qtyType: "", product_id: '' }],
      rows: values.items.map((item) => ({
        id: item.id,
        description: item.description,
        qty: item.qty,
        qtyType: item.qty_type,
        product_id: item.product_id,
      })),
    });
  };

  const setDeliveryNoteFun = async () => {
    const axiosRes = await axios.get(
      `${BASE_URL}/api/get_list/delivery_note/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { success } = axiosRes.data;
    if (success === true) {
      const fetchedDeliveryNote = axiosRes.data.data[0];
      setDeliveryNote(fetchedDeliveryNote);
      console.log(fetchedDeliveryNote);
      setInitialValues(fetchedDeliveryNote);
    }
  };

  const updateDeliveryNote = async (values) => {
    const {
      deliveryNumber,
      customer_id,
      dueDate,
      smoNumber,
      fileNumber,
      rows,
    } = values;

    const payload = {
      delivery_note_id: deliveryNumber,
      customer_id,
      due_date: dueDate,
      smo_no: smoNumber,
      file_no: fileNumber,
      item_ids: rows.map((item) => item.id),
      description: rows.map((item) => item.description),
      product_id: rows.map((item) => item.product_id),
      qty: rows.map((item) => item.qty),
      qty_type: rows.map((item) => item.qtyType),
    };

    try {
      const axiosRes = await axios.post(
        `${BASE_URL}/api/update/delivery_note`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { success } = axiosRes.data;
      if (success === true) {
        toast.success("updated products successfully");
      }
    } catch (err) {
      console.log(err);
      toast.info(err.response.data.message);
      // const errorData = err.response.data.data;
      // Object.keys(errorData).forEach(key => {
      //   const errorMessages = errorData[key];
      //   errorMessages.forEach(errorMessage => {
      //     toast.warning(`${key}: ${errorMessage}`);
      //   });
      // });
    }

    console.log(payload);
  };

  useEffect(() => {
    setDeliveryNoteFun();
    fetchCustomers();
    fetchProducts();
  }, []);

  const formik = useFormik({
    initialValues: {
      customer_id: 0,
      dueDate: deliveryNote.due_date,
      deliveryNumber: deliveryNote.id,
      smoNumber: "",
      fileNumber: deliveryNote.file_no,
      rows: [{ id: 1, description: "", qty: 0, qtyType: "", product_id: "" }],
    },
    onSubmit: (values) => {
      updateDeliveryNote(values);
      console.log(values);
    },
  });

  return (
    <>
      <Box padding={"2rem"}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>Details of delivery note ID: {params.id}</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Customer
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <Autocomplete
                              id="customer_id"
                              options={customers}
                              sx={{ width: 170 }}
                              value={
                                customers.find(
                                  (customer) =>
                                    customer.id === formik.values.customer_id
                                ) || null
                              }
                              // defaultValue={formik.values.customer_id}
                              getOptionLabel={(option) => option.name || ""}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              autoComplete="off"
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "customer_id",
                                  newValue.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Customers" />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={"flex"} marginTop={"15px"}>
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Delivery No#
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <InputLabel></InputLabel>
                            <TextField
                              {...formik.getFieldProps("deliveryNumber")}
                              id="sopo-no"
                              disabled={true}
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          File No#
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              {...formik.getFieldProps("fileNumber")}
                              id="sopo-no"
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Due Date
                        </Typography>
                        <Box minWidth={"130px"}>
                          <FormControl fullWidth>
                            {/*<InputLabel>Vendor</InputLabel>*/}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  id="due-date"
                                  value={dayjs(formik.values.dueDate)}
                                  onChange={(newValue) => {
                                    formik.setFieldValue("dueDate", newValue);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        paddingTop={"15px"}
                        paddingLeft={"10px"}
                      >
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          SMO NO#
                        </Typography>
                        <Box minWidth={"130px"}>
                          <FormControl fullWidth>
                            {/*<InputLabel>Vendor</InputLabel>*/}
                            <TextField
                              {...formik.getFieldProps("smoNumber")}
                              id="sopo-no"
                              label="SO/PO"
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              mt={"4rem"}
              mb={"1rem"}
              display={"flex"}
              justifyContent={"center"}
            >
              <div style={{ overflow: "scroll" }}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Name</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                    </tr>
                  </thead>

                  <tbody>
                    {formik.values.rows.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>

                        <td>
                          <Autocomplete
                            id={`item-description-${row.id}`}
                            key={`item-description-${row.id}`}
                            options={products}
                            sx={{ width: 170 }}
                            value={
                              products.find(
                                (product) =>
                                  product?.id === Number(row?.product_id)
                              ) || null
                            }
                            getOptionLabel={(option) =>
                              `${option.id} ${option.name}` || ""
                            }
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                `rows.${index}.description`,
                                `${newValue.id} ${newValue.name}`
                              );
                              formik.setFieldValue(
                                `rows.${index}.product_id`,
                                newValue.id
                              );

                              console.log(newValue);
                              console.log(row);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Name" />
                            )}
                          />
                          {/*<TextField*/}
                          {/*    id={`item-description-${row.id}`}*/}
                          {/*    sx={{ width: 150 }}*/}
                          {/*    disabled*/}
                          {/*    // onChange={(event) =>*/}
                          {/*    //     formik.setFieldValue(*/}
                          {/*    //         `rows.${row.id}.description`,*/}
                          {/*    //         event.target.value*/}
                          {/*    //     )*/}
                          {/*    // }*/}
                          {/*    value={row.description}*/}
                          {/*/>*/}
                        </td>
                        <td>
                          <TextField
                            id={`item-qty-${row.id}`}
                            label=""
                            type="number"
                            sx={{ width: 100 }}
                            value={row.qty}
                            onChange={(event) =>
                              formik.setFieldValue(
                                `rows.${index}.qty`,
                                event.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <TextField
                            id={`item-qty-type-${row.id}`}
                            label="Qty-Type"
                            type="text"
                            sx={{ width: 100 }}
                            value={row.qtyType}
                            onChange={(event) =>
                              formik.setFieldValue(
                                `rows.${index}.qtyType`,
                                event.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
            <Box display={"flex"} justifyContent={"end"} paddingRight={"8rem"}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/delivery-notes-list");
                }}
              >
                Go Back
              </Button>

              <Button variant="contained" type={"submit"}>
                submit
              </Button>
            </Box>
          </form>
        </Box>

        {/* <Box display={"flex"} justifyContent={"end"} maxWidth={"1130px"}>
          <Button variant={"contained"}>Submit</Button>
        </Box> */}
      </Box>
    </>
  );
};
export default DeliveryNoteDetailPage;
