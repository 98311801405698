import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Tab, Tabs, Typography } from "@mui/material";
import GridData from "../../Components/GridData";
import "../../App.css";
import { useNavigate, useParams } from "react-router-dom";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import ConformDeleteModal from "../../Components/ConformDeleteModal";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { toast } from "react-toastify";
import UpdateUserPage from "./UpdateUserPage";
import EditIcon from "@mui/icons-material/Edit";

const UserListPage = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [reload, setReload] = useState(false);
  console.log("🚀 ~ UserListPage ~ reload:", reload);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAndSetAllUsers = async () => {
    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/getuser`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const allUsers = axiosRes.data.data;
      setUsers(allUsers);
      console.log(allUsers);
    } catch (err) {
      console.log(err);
      toast.warning("Something went wrong on Backend");
    }
  };

  useEffect(() => {
    getAndSetAllUsers();
  }, [reload]);

  const columns = [
    { field: "id", headerName: "ID", headerClassName: "columnCSS", width: 70 },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "columnCSS",
      width: 140,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "columnCSS",
      width: 200,
    },
    {
      field: "role_id",
      headerName: "Role",
      headerClassName: "columnCSS",
      width: 140,
      valueGetter: (params) => {
        let role = "";
        if (params.value == 1) {
          return (role = "Admin");
        } else if (params.value == 2) {
          return (role = "Senior Auditor");
        } else {
          return (role = "Sectary");
        }
      },
    },
    {
      field: "created_at",
      headerName: "Date-Created",
      headerClassName: "columnCSS",
      width: 130,
      valueGetter: (params) => {
        const date = dayjs(params.value).format("YYYY-MM-DD");
        return date;
      },
    },
    // {field: 'status', headerName: 'Status', width: 110,
    //     cellClassName:(params)=>{
    //         if (params.value === 'Inactive'){
    //             return 'custom-status-draft'
    //         }else if(params.value === 'Active'){
    //             return 'custom-status-approved'
    //         }
    //         return '123123123'
    //     }
    // },

    {
      field: "Actions",
      headerName: "Actions",
      headerClassName: "columnCSS",
      width: 270,
      renderCell: (params) => (
        <UpdateUserPage
          // method={up}
          fetchedUser={params.row}
          text={"Update User"}
          btn={<LaunchIcon />}
          userID={params.row.id}
          token={token}
          setReload={setReload}
        />
      ),
    },
  ];

  const toAddUser = () => {
    navigate("/add-user");
  };

  return (
    <>
      <Box padding={"2rem"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant={"h3"}>All Users List</Typography>

          <Button variant={"contained"} onClick={toAddUser}>
            Create User
          </Button>
        </Box>

        {/*<Box sx={{ width: "100%", bgcolor: "background.paper" }}>*/}
        {/*    <Tabs value={value} onChange={handleChange} centered>*/}
        {/*        <Tab label="Active" />*/}
        {/*        <Tab label="Inactive" />*/}
        {/*        <Tab label="All" />*/}
        {/*    </Tabs>*/}
        {/*</Box>*/}
        <Box paddingTop={"3.45rem"}>
          <GridData rowsP={users} columnsP={columns} />
        </Box>
      </Box>
    </>
  );
};
export default UserListPage;
