import {Autocomplete, Box, Button, TextField} from "@mui/material";
import React, { useState } from "react";
import '../../Components/form-data.css'

const AddInvoiceForm = () => {
    const [rows, setRows] = useState([
        { id: 1, item: "", description: "", qty: "", qtyType: "" }
    ]);

    const [inputValue, setInputValue] = useState('');

    const handleChange = (e, id, field) => {
        const updatedRows = rows.map((row) =>
            row.id === id ? { ...row, [field]: e.target.value } : row
        );
        setRows(updatedRows);
    };

    const handleBlur = (event) => {
        const value = event.target.value;

        console.log(value);

        if (value.trim() !== '' && !top100Films.includes(value)) {
            setTop100Films((prevOptions) => [...prevOptions, value]);
        }
    };

    const initialTop100Films = [
        'option1',
        'option2',
        'option3',
        'option4',
        'option5',
        'option6',
    ];

    const [top100Films, setTop100Films] = useState([...initialTop100Films]);
    const handleDeleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    return (
        <>
            <Box overflow={'scroll'}>
                <table className="form-table">
                    <thead>
                    <tr>
                        <td>Item No.</td>

                        <td>Description</td>
                        <td>Qty</td>
                        <td>Qty Type</td>
                        <td>Price of purchase</td>
                        <td>Sale %</td>
                        <td>Tax</td>
                        <td>Amount</td>
                    </tr>
                    </thead>

                    <tbody>
                    {rows.map((row) => (
                        <tr key={row.id}>
                            <td>
                                {row.id}
                            </td>

                            <td>
                                <TextField
                                    id={`item-description-${row.id}`}
                                    label="description"
                                    minRows={2}
                                    sx={{ width: 230 }}
                                    multiline
                                    disabled
                                    maxRows={10}
                                    onChange={(e) => handleChange(e, row.id, "description")}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={`item-qty-${row.id}`}
                                    label="9"
                                    disabled
                                    sx={{width: 80}}
                                    type="number"
                                    onChange={(e) => handleChange(e, row.id, "qty")}
                                />
                            </td>
                            <td>
                                <Autocomplete
                                    disablePortal
                                    id={`item-qty-type-${row.id}`}
                                    options={top100Films}
                                    sx={{ width: 100 }}
                                    disabled={true}

                                    freeSolo
                                    value={inputValue}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => setInputValue(newValue)}
                                    renderInput={(params) => (
                                        <>
                                            <TextField {...params} label="Choose" />
                                        </>
                                    )}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={`item-price-${row.id}`}
                                    sx={{width: 80}}
                                    type="number"
                                    disabled
                                    value={55}
                                    onChange={(e) => handleChange(e, row.id, "qty")}
                                />
                            </td>

                            <td>
                                <TextField
                                    id={`item-price-${row.id}`}
                                    label="%"
                                    sx={{width: 80}}
                                    type="number"
                                    onChange={(e) => handleChange(e, row.id, "qty")}
                                />
                            </td>
                            <td>

                                <Box display={'flex'}>
                                    <Autocomplete
                                        disablePortal
                                        id={`item-tax-${row.id}`}
                                        options={top100Films}

                                        sx={{width: 140}}
                                        multiple
                                        renderInput={(params) => <TextField {...params} label="Choose"/>}
                                    />
                                    <Button sx={{minWidth:0}}>
                                        +
                                    </Button>
                                </Box>



                            </td>

                            <td style={{marginLeft:'10px'}}>

                                <p>$ 0.00</p>

                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>

                <Box mr={'1rem'}>


                    <Box display={"flex"} justifyContent={"end"}>
                        <p>SubTotal:</p>
                        <p>$0.00</p>
                    </Box>

                    <Box display={"flex" } justifyContent={"end"}>
                        <p>Total (COP):</p>
                        <p>$0.00</p>
                    </Box>

                    <Box display={"flex"} justifyContent={"end"}>
                        <p>Total : </p>
                        <p>$0.00</p>
                    </Box>



                </Box>
            </Box>
        </>
    );
};

export default AddInvoiceForm;
