import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    Box,
    Button,
    FormControl,
    Grid, Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import axios from 'axios';
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius:'27px'
};





const AddProductModal = (props) => {
    const {btn,status,setRunFeProdtsAgain} = props
    const createProductOrService = async (values) =>{
        try{
            const {name,description,buyThis,price} = values
            const newPorS = {
                name,
                description,
                buy_this:buyThis,
                price
            }
            const axiosRes = await axios.post(`${BASE_URL}/api/product`,newPorS,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            if(success === true) {
                toast.success('Added New Product Successfully')
                setRunFeProdtsAgain(prevState => !prevState);
                handleClose()
            }
        } catch (err) {
            toast.warn('some something went wrong try again')
        }

    }

    const [open, setOpen] = React.useState(false);

    const {token} = useSelector((state)=> state.USER_SLICE)
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const disableOrNot = status === 'Approved'


    const formik = useFormik(({
        initialValues:{
            name:'',
            description:'',
            price:0,
            buyThis:0
        },
        onSubmit:(values)=>{
            createProductOrService(values)
            // console.log(values)
        }
    }))


    return (
        <div>
            <Button disabled={disableOrNot} onClick={handleOpen}>{btn}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box borderTop={"1px solid black"} pt={'1rem'}>


                        <Box>
                            <Stack
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                spacing={1}
                                flexDirection={"column"}

                            >
                                <form onSubmit={formik.handleSubmit}>


                                    <Box sx={{display: {sm: "block", md: "block"}}}>
                                        <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                            Name
                                        </Typography>
                                        <TextField
                                            id="customer-Name"
                                            label="Name"
                                            type="text"
                                            fullWidth={true}
                                            required
                                            {...formik.getFieldProps('name')}

                                        />
                                    </Box>

                                    <Box sx={{display: 'block'}} minWidth={'150px'}>
                                        <Typography flexWrap={"wrap"} variant={'h6'} textAlign={"center"} mt={"4.5%"}
                                                    mx={'10px'}>
                                            Description
                                        </Typography>
                                        <TextField
                                            id="customer-full-name"
                                            label="Description"
                                            type="text"
                                            multiline
                                            minRows={5}
                                            required
                                            {...formik.getFieldProps('description')}
                                        />
                                    </Box>


                                    {/*<Box sx={{display: {sm: "block", md: "block"}}}>*/}
                                    {/*    <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>*/}
                                    {/*        Price*/}
                                    {/*    </Typography>*/}
                                    {/*    <TextField*/}
                                    {/*        id="customer-phone"*/}
                                    {/*        label="Price"*/}
                                    {/*        type="number"*/}
                                    {/*        */}
                                    {/*        {...formik.getFieldProps('price')}*/}
                                    {/*    />*/}
                                    {/*</Box>*/}


                                    <Box sx={{display: {sm: "flex", md: "flex"},justifyContent:'center'}}>
                                        <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                            Buy This
                                        </Typography>
                                        <Checkbox
                                            {...formik.getFieldProps('buyThis')}
                                            onChange={(event)=>{
                                                formik.setFieldValue('buyThis', event.target.checked ? 1 : 0);
                                            }}
                                        />

                                    </Box>


                                    <Box textAlign={'center'} mt={'10px'}>
                                        <Button type={'submit'}>
                                            Save
                                        </Button>
                                    </Box>
                                </form>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default AddProductModal