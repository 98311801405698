import React, { useEffect } from 'react'
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from "react-router-dom";
import { setCurrencies,deleteCurrency } from '../../redux-store/Slices/currencySlice';
import { useDispatch ,useSelector} from 'react-redux';
import axios from 'axios';
import CurrencyUpdateModal from '../../Components/CurrencyUpdateModal';
import {toast} from "react-toastify";
import GridData from "../../Components/GridData";
import TaxUpdateModal from "../../Components/TaxUpdateModal";
import ConformDeleteModal from "../../Components/ConformDeleteModal";

const CurrencyPage = () => {
    const baseuri = process.env.REACT_APP_BASE_URL;
    const { token } = useSelector((state) => state.USER_SLICE);
    const { currencies } = useSelector((state) => state.CURRENCY_SLICE);
    const dispatch = useDispatch()

    useEffect(()=>{

        getAndSetCurrencies()

    },[])
    const getAndSetCurrencies = async () => {
        dispatch(setCurrencies({ isLoading: true }));
    
        try {
          const axiosRes = await axios.get(`${baseuri}/api/currency`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
    
          const { success } = axiosRes.data;
          if (success === false) {
              toast('no currency found please add one')
          }else if(success === true){
              dispatch(setCurrencies({ currencies: axiosRes.data.data, isLoading: false }));
          }
        } catch (error) {
          console.error('Error fetching currencies:', error);
          toast.warning(error.response.data.message)
          dispatch(setCurrencies({ isLoading: false })); // Handle error state if needed
        }
      };


      const updateCurrency = async (updatedCurrency,id) =>{
        const axiosRes = await axios.patch(
            `${baseuri}/api/currency/${id}`,
            updatedCurrency,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Adjust the content type as needed
              },
            }
          );
            const {success} = axiosRes.data
          if(success === true){
              toast.success('Updated Currency Successfully')
          }
        //   console.log(updatedCurrency);

        // const newCurrency = axiosRes.data.data
        // const {success} = axiosRes.data

        // if(success === true){
        //     dispatch(updateCurrency({newCurrency,id:newCurrency.id}))
        //     console.log(newCurrency);

        // }


      }


    const handleDeleteCurrency =async (id) => {
        const axiosres = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/currency/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
        })

        const {success} = axiosres.data
        if(success === true) {
            dispatch(deleteCurrency(id))
            toast.info('Deleted Currency Successfully')
        }

    }

    const navigate = useNavigate()
    let navigateToCreateCurrency = () =>{
        navigate('/create-currency')
    }

    const columns = [
        { field: "id", headerName: "ID", width: 50 },
        { field: "currency_name", headerName: "Currency Name", width: 120 },
        { field: "bank_name", headerName: "Bank Name", width: 180 },
        {
            field: "Actions",
            headerName: "Actions",
            width: 180,
            renderCell: (params) => (
                <ButtonGroup>
                    <CurrencyUpdateModal
                        method={updateCurrency}
                        fetchedCurrency={params.row}
                        text={'Update Currency'}
                        btn={<EditIcon/>}
                        currencyID={params.row.id}
                    />

                    <ConformDeleteModal
                        btn={<DeleteIcon sx={{color:'red'}}/>}
                    method={handleDeleteCurrency}
                    taxID={params.row.id}
                        text={`Delete Currency "${params.row.currency_name}" ?`}
                    />

                </ButtonGroup>
            ),
        },

    ];


    return (
        <>
            <Box padding={'2rem'}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={'h3'}>
                        Currency
                    </Typography>
                    <Button variant={"contained"} onClick={navigateToCreateCurrency}>
                        Create currency
                    </Button>
                </Box>
                <Box mt={'2rem'}>
                    <GridData columnsP={columns} rowsP={currencies}/>

                </Box>

            </Box>
        </>

    )
}
export default CurrencyPage
