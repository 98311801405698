import {
    Box,
    Button,

    Typography,

    Tabs,
    Tab,
    Stack,
    ButtonGroup,
} from "@mui/material";


import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import GridData from "../../Components/GridData";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {setBillNoteItems} from "../../redux-store/Slices/billNoteSlice";

const InvoicesPage = () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=> state.USER_SLICE)
    const {billItems} = useSelector((state)=> state.BILL_NOTE_SLICE)
    const dispatch = useDispatch()

    const [value, setValue] = React.useState(0);

    const [rows, setRows] = useState([]);

    const getAndSetPurchaseBills = async () => {
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            const { success } = axiosRes.data;
            if (success) {
                let deliveryNotes = axiosRes.data.data;
                console.log(deliveryNotes);
                deliveryNotes = deliveryNotes.filter(item => item.billorder?.status === 'Active' && item.invoice === null);

                if (deliveryNotes.length === 0){
                    toast.warning('No Purchase Bill available For Invoice Creation')
                }
                console.log(billItems);
                setRows([]);
                for (let note of deliveryNotes) {
                    const { id, customer,smo_no, vendor, created_at, due_date, file_no, billorder } = note;
                    if (billorder?.status === "Active") { // Using optional chaining here
                        billorder.status = "Approved";
                    }
                    const rows1 = {
                        id,
                        smo_no,
                        currency: billorder.currency_item.currency_name,
                        vendor_name: billorder?.vendor?.name,
                        created_at: dayjs(created_at).format(`YYYY-MM-DD`),
                        due_date,
                        file_no,
                        status: billorder?.status, // Using optional chaining here
                        customer_name: customer.name,
                    };

                    setRows(preRows => [...preRows, rows1]);
                }
            }
        } catch (err) {
            const errmsg = err.response.data.message
            if (errmsg === 'DeliveryNote not found.'){
                toast.warning('No Invoices Found')
            }
        }
    };

    useEffect(() => {
        getAndSetPurchaseBills()

    }, []);

    const columns = [

        {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
        {field: 'customer_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
        {field: 'vendor_name', headerName: 'Vendor',headerClassName:'columnCSS', width: 140,},
        {field: 'created_at', headerName: 'Date-Created',headerClassName:'columnCSS', width: 130},
        {field: 'smo_no', headerName: 'SMO-No#',headerClassName:'columnCSS', width: 140},
        {field: 'file_no', headerName: 'File N0',headerClassName:'columnCSS', width: 140},

        {field: 'due_date', headerName: 'Date',headerClassName:'columnCSS', width: 160},
        // {field: 'AmountDue', headerName: 'Amount Date',headerClassName:'columnCSS', width: 160,},
        {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 160,
            renderCell: (params) => (
                <ButtonGroup>
                    <Button onClick={()=> {navigate(`/invoice/edit/${params.row.id}`)}}>
                        <EditNoteOutlinedIcon/>
                    </Button>

                    {/*<Button  onClick={() => console.log(params.row.id)}>*/}
                    {/*    <DeleteForeverOutlinedIcon sx={{color:'red'}}/>*/}
                    {/*</Button>*/}
                </ButtonGroup>
            ),
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let navigate = useNavigate()

    let NavigateToCreateInvoice = () =>{
        navigate('/create-invoice')
    }
    return (
        <>
            <Box display={"flex"} justifyContent={"center"}>
                <Stack width={{ xs: "75%", md: "70%" }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography fontSize={"33px"}>
                           Create Invoice
                        </Typography>

                    </Box>

                    {/*<Box sx={{ width: "100%", bgcolor: "background.paper" }}>*/}
                    {/*    <Tabs value={value} onChange={handleChange} centered>*/}
                    {/*        <Tab label="Active" />*/}
                    {/*        <Tab label="Draft" />*/}
                    {/*        <Tab label="All" />*/}
                    {/*    </Tabs>*/}
                    {/*</Box>*/}

                    <Box paddingTop={'3.45rem'}>
                        <GridData rowsP={rows} columnsP={columns}/>
                    </Box>

                    {/*<Box*/}
                    {/*    display={"flex"}*/}
                    {/*    mt={5}*/}
                    {/*    justifyContent={"center"}*/}
                    {/*    alignItems={"center"}*/}
                    {/*    flexDirection={"column"}*/}
                    {/*>*/}
                    {/*    <Typography variant="h5" fontWeight={500}>*/}
                    {/*        Don't Underestimate it -- Create a new Invoice*/}
                    {/*    </Typography>*/}



                    {/*    <Button sx={{ borderRadius: "20px",marginTop:'2rem' }}*/}
                    {/*            variant="outlined"*/}
                    {/*            onClick={NavigateToCreateInvoice}*/}
                    {/*    >*/}
                    {/*        Create Invoice*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </Stack>
            </Box>
        </>
    );
};

export default InvoicesPage;
