import { Box } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const FormTestPage = () => {
  const formik = useFormik({
    initialValues: {
      userName: '',
      userAge: '',
    },
    validationSchema: Yup.object(
        {
            userName: Yup.string().max(5,'must be less than 5 words').required('a name is must'),
            userAge: Yup.number().max(60,'age must b less than than 60').required('age is must')
        }
    ),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <label>
          Username:
          <input
            type='text'
            {...formik.getFieldProps('userName')} 
            />
            {formik.errors.userName ? <div>{formik.errors.userName}</div> : null}

        </label>
        <br />
        <label>
          User Age:
          <input
            type="number" 
            {...formik.getFieldProps('userAge')}
          />
          {formik.errors.userAge ? <div>{formik.errors.userAge}</div> : null}
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </Box>
  );
};

export default FormTestPage;
