import {Box, Typography, TextField, Grid, Button, ButtonGroup} from "@mui/material";
import React, {useEffect} from "react";
import GridData from "../../Components/GridData";
import {useNavigate} from "react-router-dom";
import UpdateVendorModal from "../../Components/UpdateVendorModal";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ConformDeleteModal from "../../Components/ConformDeleteModal";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import {setVendors, updateSliceVendor} from "../../redux-store/Slices/vendorSlice";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setCustomers,updateSliceCustomer,deleteSliceCustomer} from "../../redux-store/Slices/customerSlice";

const CustomersPage = () => {

    const navigate = useNavigate()
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const {token} = useSelector((state)=> state.USER_SLICE)
    const {customers} = useSelector((state)=> state.CUSTOMER_SLICE)
    const dispatch = useDispatch()

    const getAndSetCustomers= async () => {
        try{
            const axiosRes = await axios.get(`${BASE_URL}/api/customer`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            const customers = axiosRes.data.data;
            console.log(axiosRes.data)
            dispatch(setCustomers({ customers:customers, isLoading: false }));
        }catch (err){
            console.log(err.response.data)
            toast.warning(err.response.data.message)

        }

    };

    useEffect(() => {
        getAndSetCustomers()
    },[]);

    const navigateaddCustomer = () =>{
        navigate('/addCustomer')
    }

    const deleteCustomer =async (id) =>{
        try {
            const axiosRes = await axios.delete(`${BASE_URL}/api/customer/${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            const {success} = axiosRes.data

            if(success){
                dispatch(deleteSliceCustomer(id))
                toast.info('Deleted Vendor Successfully')
            }
        }catch (err){
            console.log(err)
            toast.warning('something went wrong')
        }

    }

    const updateCustomer =async (values) =>{
        try {

            const axiosRes = await axios.patch(`${BASE_URL}/api/customer/${values.id}`,values,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            console.log(values)
            if(success){
                dispatch(updateSliceCustomer({updatedCustomer:values}))
                toast.success('Updated Customer Successfully')
            }
        }catch (err) {
            console.log(err)
            toast.warning('something went wrong')
        }

    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 35 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        {
          field: 'phone',
          headerName: 'Phone',
          width: 140,
        },
        // {
        //   field: 'currency',
        //   headerName: 'Currency',
        //   width: 160,
        //
        // },
        {
            field: "Actions",
            headerName: "Actions",
            width: 180,
            renderCell: (params) => (
                <ButtonGroup>
                    <UpdateVendorModal
                        btn={<EditNoteOutlinedIcon/>}
                        text={'Update Vendor'}
                        vendor1={params.row}
                        vendor_id={params.row.id}
                        method={updateCustomer}
                    />
                    {/*<Button onClick={() => console.log(params.row.id)}>*/}
                    {/*  <EditNoteOutlinedIcon/>*/}
                    {/*</Button>*/}
                    <ConformDeleteModal
                        btn={<DeleteForeverOutlinedIcon sx={{color:'red'}}/>}
                        taxID={params.row.id}
                        text={`Delete Customer "${params.row.name}" ?`}
                        method={deleteCustomer}
                    />
                    {/*<Button  onClick={() => deleteVendor(params.row.id)}>*/}
                    {/*  <DeleteForeverOutlinedIcon sx={{color:'red'}}/>*/}
                    {/*</Button>*/}
                </ButtonGroup>
            ),
        },
      ];
      

  return (
    <>
      <Box mt={5}>
        <Grid container spacing={2}>


          <Grid item xs={12} sm={12} sx={{paddingLeft:'30px'}}>
            <Box display={'flex'} justifyContent={'space-between'} sx={{paddingLeft:'30px'}}>
                <Typography variant={'h4'}>
                    Customers
                </Typography>


              <Box>

                <Button onClick={navigateaddCustomer}  variant="contained" sx={{marginLeft:'10px',marginRight:'10px',borderRadius:'30px'}}>
                    Add a Customer
                </Button>
            </Box>
            </Box>
            <Box mt={4} sx={{paddingLeft:'30px'}}>
                <GridData columnsP={columns} rowsP={customers}/>
            </Box>
            
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomersPage;
