import React, {useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import '../../App.css'
import {useNavigate} from "react-router-dom";
import GridData from "../../Components/GridData";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import ProductAndServiceUpdateModal from "../../Components/ProductAndServiceUpdateModal";
import {setProducts, updateSliceProduct} from "../../redux-store/Slices/productsSlice";
import {ToastContainer, toast, Bounce} from 'react-toastify';

const Billpage = () => {
    // const [products, setProducts] = useState([]);
    const dispatch = useDispatch();

    const { products } = useSelector((state) => state.PRODUCT_SLICE);

    useEffect(() => {
        fetchAndSetProducts()
    }, []);
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=>state.USER_SLICE)

    const fetchAndSetProducts = async () =>{
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/product`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            const fetchedProducts = axiosRes.data.data
            dispatch(setProducts({products: fetchedProducts}))
            console.log(fetchedProducts)
        }catch (err){
            console.log(err)
            const {message} = err.response.data
            toast.warning(message)
        }

    }

    const deleteProduct = async (id) =>{
       // await axios.delete(`${BASE_URL}/api/product`,{
       //      headers: {
       //          Authorization: `Bearer ${token}`,
       //          "Content-Type": "application/json",
       //      },
       //  })
        toast.warning(`deleted to be implemented ${id.id}`)
    }
    const updateProduct = async (newProduct,id) =>{
        try {
           const axiosRes = await axios.patch(`${BASE_URL}/api/product/${id}`,newProduct,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            if(success){
                toast.info('updated product successfully')
              dispatch(updateSliceProduct({updatedProduct:newProduct,nid:id}))
            }


        } catch (err){
            console.log(err)
            toast.warning('Something went wrong')
        }

    }


    const columns = [

        {field: 'id', headerName: 'ID', width: 70},
        {field: 'name', headerName: 'Name', width: 150},
        {field: 'price', headerName: 'Price', width: 100},
        {field: 'buy_this', headerName: 'Buy This ?', width: 100,
            renderCell: (params) => (
                <Typography>
                    {params.row.buy_this === 1 ? 'True' : 'False'}
                </Typography>
            ),
        },
        {
            field: "Actions",
            headerName: "Actions",
            width: 180,
            renderCell: (params) => (
                <ButtonGroup>
                    <ProductAndServiceUpdateModal
                    btn={<EditNoteOutlinedIcon/>}
                    text={'Update Product'}
                    name={params.row.name}
                    price={params.row.price}
                    buy_this={params.row.buy_this}
                    description={params.row.description}
                    product_id={params.row.id}
                    method={updateProduct}
                    />
                    {/*<Button onClick={() => console.log(params.row.id)}>*/}
                    {/*    <EditNoteOutlinedIcon/>*/}
                    {/*</Button>*/}

                </ButtonGroup>
            ),
        },
        {field: 'description', headerName: 'Description', width: 300},

    ];


    const navigate = useNavigate()

    const toAddBill = () => {
        navigate('/add-product-service')

    }

    return (
        <>


            <Box padding={'2rem'}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={'h5'}>
                        Products and Services
                    </Typography>
                    <Button variant={"contained"} onClick={toAddBill}>
                        Create
                    </Button>
                </Box>
                <Box paddingTop={'3.45rem'} display={'flex'} justifyContent={'center'}>
                    <GridData rowsP={products} columnsP={columns}/>
                    {/*<table style={*/}
                    {/*    {*/}

                    {/*        width: '100%'*/}
                    {/*    }*/}
                    {/*}>*/}
                    {/*    <thead>*/}
                    {/*    <tr>*/}
                    {/*        <td>Name</td>*/}
                    {/*        <td>Price</td>*/}
                    {/*        <td>Action</td>*/}
                    {/*    </tr>*/}
                    {/*    </thead>*/}
                    {/*    <tbody>*/}
                    {/*    <tr style={{*/}
                    {/*        borderBottom: '1px solid black',*/}
                    {/*    }}>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} fontSize={'18px'}>*/}
                    {/*                A4 Paper (Box of 5)*/}
                    {/*            </Typography>*/}
                    {/*            <Typography color={'#00000047'}>*/}
                    {/*                SMO: T-280*/}
                    {/*            </Typography>*/}
                    {/*        </td>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} variant={'h6'}>*/}
                    {/*                $52.6*/}
                    {/*            </Typography>*/}

                    {/*        </td>*/}

                    {/*        <td>*/}
                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <EditRoadIcon/>*/}
                    {/*            </Button>*/}

                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <DeleteIcon/>*/}
                    {/*            </Button>*/}


                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} fontSize={'18px'}>*/}
                    {/*                A4 Paper (Box of 5)*/}
                    {/*            </Typography>*/}
                    {/*            <Typography color={'#00000047'}>*/}
                    {/*                SMO: T-280*/}
                    {/*            </Typography>*/}
                    {/*        </td>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} variant={'h6'}>*/}
                    {/*                $52.6*/}
                    {/*            </Typography>*/}

                    {/*        </td>*/}

                    {/*        <td>*/}
                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <EditRoadIcon/>*/}
                    {/*            </Button>*/}

                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <DeleteIcon/>*/}
                    {/*            </Button>*/}


                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} fontSize={'18px'}>*/}
                    {/*                A4 Paper (Box of 5)*/}
                    {/*            </Typography>*/}
                    {/*            <Typography color={'#00000047'}>*/}
                    {/*                SMO: T-280*/}
                    {/*            </Typography>*/}
                    {/*        </td>*/}
                    {/*        <td>*/}
                    {/*            <Typography textTransform={'capitalize'} variant={'h6'}>*/}
                    {/*                $52.6*/}
                    {/*            </Typography>*/}

                    {/*        </td>*/}

                    {/*        <td>*/}
                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <EditRoadIcon/>*/}
                    {/*            </Button>*/}

                    {/*            <Button sx={{margin: '0', padding: '0', minWidth: 'auto'}}>*/}
                    {/*                <DeleteIcon/>*/}
                    {/*            </Button>*/}


                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                </Box>

            </Box>
        </>
    )
}
export default Billpage
