import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as YUP from "yup";
import axios from "axios";
import { setUser } from "../redux-store/Slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginUser = async (email, password) => {
    try {
      dispatch(setUser({ isLoading: true }));

      const axiosRes = await axios.post(
        `${BASE_URL}/api/login?email=${email}&password=${password}`
      );
      console.log(axiosRes.data);

      const { success } = axiosRes.data;

      if (success === true) {
        const { name, token, role } = axiosRes.data.data;
        console.log("🚀 ~ loginUser ~ axiosRes.data.data:", axiosRes.data.data);
        await dispatch(
          setUser({ name, token, role, isLoggedin: true, isLoading: false })
        );
        navigate("/");
      } else {
        console.log("Invalid email or password");
      }
    } catch (err) {
      console.log(err);
      toast.warning("User not found");
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: YUP.object({
      email: YUP.string().email("invalid email"),
      password: YUP.string(),
    }),
    onSubmit: (values) => {
      console.log(values);
      loginUser(values.email, values.password);
    },
  });
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
      >
        <Box
          border={"1px solid #0000002b"}
          sx={{ background: "#E8EFFD", width: "30rem" }}
          height={"70vh"}
          borderRadius={"56px"}
          padding={"2.25rem"}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box mt={"3rem"}>
              <Box>
                <Typography variant="h4" mb={"20px"} fontWeight={700}>
                  Sign in
                </Typography>

                <Box m={"10px 0"} sx={{}}>
                  <TextField
                    variant="outlined"
                    error={formik.errors.email}
                    fullWidth={true}
                    label="Email"
                    required
                    {...formik.getFieldProps("email")}
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </Box>
                <Box m={"10px 0"} sx={{}}>
                  {/* <TextField
                    variant="outlined"
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    {...formik.getFieldProps("password")}
                  /> */}

                  <FormControl fullWidth required variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      {...formik.getFieldProps("password")}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Box>
                <Box textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: "40px", width: "27rem", mt: "10px" }}
                  >
                    Sign in
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
