import React, {useEffect, useState} from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import AddProductModal from "./AddProductModal";


const AddDeliveryNote = () => {

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [lastDelNo, setLastDelNo] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [runFetchProducts, setRunFetchProducts] = useState(false);
  const navigate = useNavigate()

  const setSubmittingTrue =()=>{
    setSubmitting(true)

    setTimeout(() => {
      // After submission is complete, reset the state
      setSubmitting(false);
    }, 3000);
  }

  const { token } = useSelector((state) => state.USER_SLICE);
  // const { products } = useSelector((state) => state.PRODUCT_SLICE);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch()


  const fetchCustomers = async ()=>{
    try {
      const {data} = await axios.get(`${BASE_URL}/api/customer`,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      const vendorArray = data.data
      setCustomers(vendorArray)
      console.log(customers)
    }catch (err){
      toast.info(err.response.data.message)
    }


  }

  const fetchAndSetLastID = async () =>{
    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/last_id/delivery_note`,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      const LastDeliveryNo = axiosRes.data.data.last_inserted_id
      setLastDelNo(LastDeliveryNo + 1)
    }catch (err){
      toast.info(err.response.data.message)
    }


  }

  const fetchProducts = async ()=>{
    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/product`,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      const {success} = axiosRes.data
      if(success === true){
        const productArray = axiosRes.data.data
       setProducts(productArray)
        console.log(products)
      }

    } catch (err){
      console.log(err)
      toast.info(err.response.data.message)
      // const errorData = err.response.data.data;
      // Object.keys(errorData).forEach(key => {
      //   const errorMessages = errorData[key];
      //   errorMessages.forEach(errorMessage => {
      //     toast.warning(`${key}: ${errorMessage}`);
      //   });
      // });
    }


  }

  useEffect(()=>{
    fetchCustomers()
    fetchProducts()

  },[])


    useEffect(()=>{

    fetchProducts()
      console.log('ran fetch products')

  },[runFetchProducts])




  const createDeliveryNote = async (values) => {

    const { customer_id, dueDate, deliveryNumber, smoNumber, fileNumber, rows } = values;

    let description = rows.map((value)=> value.description)
    let qty = rows.map((value)=> value.qty)
    let qty_type = rows.map((value)=> value.qtyType)
    let product_id = rows.map((value)=> value.product_id)
    
    const deliveryNoteData = {
      // vendor_id,
      customer_id,
      due_date: dayjs(dueDate).format(`YYYY-MM-DD`),
      smo_no: smoNumber,
      file_no: fileNumber,
      description,
      qty,
      qty_type,
      product_id
    };
    
    try {
      const axiosRes = await axios.post(
        `${BASE_URL}/api/store/delivery_note`,
        deliveryNoteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(axiosRes)
      const {success} = axiosRes.data
      if(success === true){
        navigate('/delivery-notes-list')
        toast.success('Created Delivery Note Successfully')
      }
    } catch (err) {
      const errorData = err.response.data.data;
      Object.keys(errorData).forEach(key => {
        const errorMessages = errorData[key];
        errorMessages.forEach(errorMessage => {
          toast.warning(`${key}: ${errorMessage}`);
        });
      });
    }
  };

  useEffect(() => {
    const setInitialValues = async () => {
      await fetchAndSetLastID();
      formik.setValues({
        dueDate: "",
        customer_id: null,
        deliveryNumber: lastDelNo,
        smoNumber: "",
        fileNumber: "",
        rows: [{ id: 1, description: "", qty: 0, qtyType: "", product_id: '' }],
      });
    };
    setInitialValues();
  }, [lastDelNo]);


  const formik = useFormik({
    initialValues: {
      customer_id: null,
      dueDate: "",
      deliveryNumber: lastDelNo,
      smoNumber: "",
      fileNumber: "",
      rows: [{ id: 1, description: "", qty: 0, qtyType: "" ,product_id:''}],
    },
    onSubmit: (values) => {
      setSubmittingTrue()
      createDeliveryNote(values);
      console.log(values)
    },
  });


  const handleAddRow = () => {
    const newRow = {
      id: formik.values.rows.length + 1,
      description: "",
      qty: "",
      qtyType: "",
    };
    formik.setValues((prevValues) => ({
      ...prevValues,
      rows: [...prevValues.rows, newRow],
    }));
  };

  const handleDeleteRow = (id) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      rows: prevValues.rows.filter((row) => row.id !== id),
    }));
    console.log('hello')
  };


  return (
    <>
      <Box padding={"2rem"}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>Add Delivery Note</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Customer
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <Autocomplete
                                id="customer_id"
                                options={customers}
                                sx={{ width: 170 }}
                                value={customers.find(customer => customer.id === formik.values.customer_id) || null}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) => option.id === value.id}
                                autoComplete="off"
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("customer_id", newValue.id);
                                }}

                                renderInput={(params) => (
                                    <TextField {...params} label="Customers" />
                                )}
                            />



                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={"flex"} marginTop={"15px"}>
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Delivery No#
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <InputLabel></InputLabel>
                            <TextField
                              {...formik.getFieldProps("deliveryNumber")}
                              disabled={true}
                              id="sopo-no"
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          File No#
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              {...formik.getFieldProps("fileNumber")}
                              id="sopo-no"
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          Due Date
                        </Typography>
                        <Box minWidth={"130px"}>
                          <FormControl fullWidth>
                            {/*<InputLabel>Vendor</InputLabel>*/}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  id="due-date"
                                  onChange={(newValue) => {
                                    formik.setFieldValue("dueDate", newValue);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        paddingTop={"15px"}
                        paddingLeft={"10px"}
                      >
                        <Typography
                          variant={"h6"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          SMO NO#
                        </Typography>
                        <Box minWidth={"130px"}>
                          <FormControl fullWidth>
                            {/*<InputLabel>Vendor</InputLabel>*/}
                            <TextField
                              {...formik.getFieldProps("smoNumber")}
                              id="sopo-no"
                              label="SO/PO"
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                          display={"flex"}
                          paddingTop={"15px"}
                          paddingLeft={"10px"}
                          justifyContent={'center'}
                          alignItems={'center'}
                      >
                        <AddProductModal
                            setRunFeProdtsAgain={setRunFetchProducts}
                            btn={'Add Product'}
                            text={`Approve Delivery Note With ID ?`}
                            btnText={`Approve Delivery Note`}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              mt={"4rem"}
              mb={"1rem"}
              display={"flex"}
              justifyContent={"center"}
            >
              <div style={{ overflow: "scroll" }}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Name</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                    </tr>
                  </thead>

                  <tbody>
                    {formik.values.rows.map((row) => (
                      <tr key={row.id}>
                        <td>{row.id}</td>

                        <td>
                          <Autocomplete
                              id={`item-description-${row.id}`}
                              key={`item-description-${row.id}`}
                              options={products}
                              sx={{ width: 170 }}
                              // value={products.find(product => product.id === formik.values.vendor_id) || null}
                              getOptionLabel={(option) => `${option.id} ${option.name}` || ""}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                formik.setFieldValue(`rows.${row.id - 1}.description`, newValue ? newValue.name : "");

                                formik.setFieldValue(`rows.${row.id - 1}.product_id`, newValue ? newValue.id : "");
                              }}
                              renderInput={(params) => (
                                  <TextField {...params} label="Name" />
                              )}
                          />

                        </td>
                        <td>
                          <TextField
                            id={`item-qty-${row.id}`}
                            label=""
                            type="number"
                            sx={{ width: 100 }}
                            onChange={(event) =>
                              formik.setFieldValue(
                                `rows.${row.id - 1}.qty`,
                                event.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <TextField
                            id={`item-qty-type-${row.id}`}
                            label="Qty-Type"
                            type="text"
                            sx={{ width: 100 }}
                            onChange={(event) =>
                              formik.setFieldValue(
                                `rows.${row.id - 1}.qtyType`,
                                event.target.value
                              )
                            }
                          />

                        </td>
                        <td>
                          <Button onClick={() => handleDeleteRow(row.id)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <tr>
                      {/* button to add one more row */}
                      <td colSpan="4">
                        <Button type="button" onClick={handleAddRow}>
                          Add one more
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </Box>
            <Box display={'flex'} justifyContent={'end'} paddingRight={'8rem'}>

            <Button disabled={submitting} variant="contained" type={"submit"}>
              {submitting ? '...Loading' : 'SAVE'}
            </Button>
            </Box>
          </form>
        </Box>

        {/* <Box display={"flex"} justifyContent={"end"} maxWidth={"1130px"}>
          <Button variant={"contained"}>Submit</Button>
        </Box> */}
      </Box>
    </>
  );
};
export default AddDeliveryNote;
