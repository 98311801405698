import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius:'27px'
};



const UpdateVendorModal = (props) => {
    const {text,method,btn,vendor1} = props
    const { name, full_name, email, phone, addition_info,id} = vendor1


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const formik = useFormik({
        initialValues: {
            name,
            full_name,
            email,
            phone,
            addition_info,
            id
        },

        onSubmit: (values) => {
            //   createTax(values.taxName,values.taxPercentage)
            // method(values.taxName,values.taxPercentage,taxid)
            handleClose()
            method(values);
        },
    });


    return (
        <div>
            <Button onClick={handleOpen}>{btn}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <form onSubmit={formik.handleSubmit}>
                            <Box>
                                <Box mb={"2rem"}>
                                    <h1>{text}</h1>
                                </Box>

                                <Grid
                                    container
                                    spacing={1}
                                    display="flex"
                                    justifyContent={"center"}
                                >
                                    <Grid item xs={"auto"}>
                                        {/*    Box 1*/}
                                        <Box
                                            display={"flex"}
                                            justifyContent={"start"}
                                            flexDirection={"row"}
                                        >
                                            <Box>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography
                                                        variant={"h6"}
                                                        paddingLeft={"16px"}
                                                        paddingTop={"11px"}
                                                        paddingRight={"13px"}
                                                    >
                                                        Name
                                                    </Typography>
                                                    <Box minWidth={"170px"}>
                                                        <FormControl fullWidth>
                                                            <TextField

                                                                {...formik.getFieldProps("name")}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography
                                                        variant={"h6"}
                                                        paddingLeft={"16px"}
                                                        paddingTop={"11px"}
                                                        paddingRight={"13px"}
                                                    >
                                                        Full Name
                                                    </Typography>
                                                    <Box minWidth={"170px"}>
                                                        <FormControl fullWidth>
                                                            <TextField

                                                                {...formik.getFieldProps("full_name")}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography
                                                        variant={"h6"}
                                                        paddingLeft={"16px"}
                                                        paddingTop={"11px"}
                                                        paddingRight={"13px"}
                                                    >
                                                        Email
                                                    </Typography>
                                                    <Box minWidth={"170px"}>
                                                        <FormControl fullWidth>
                                                            <TextField

                                                                {...formik.getFieldProps("email")}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography
                                                        variant={"h6"}
                                                        paddingLeft={"16px"}
                                                        paddingTop={"11px"}
                                                        paddingRight={"13px"}
                                                    >
                                                        Name
                                                    </Typography>
                                                    <Box minWidth={"170px"}>
                                                        <FormControl fullWidth>
                                                            <TextField

                                                                {...formik.getFieldProps("phone")}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>

                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography
                                                        variant={"h6"}
                                                        paddingLeft={"16px"}
                                                        paddingTop={"11px"}
                                                        paddingRight={"13px"}
                                                    >
                                                        Additional Info
                                                    </Typography>
                                                    <Box minWidth={"170px"}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                multiline
                                                                rows={4}
                                                                {...formik.getFieldProps("addition_info")}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={"auto"}>
                                        {/*    Box 2*/}
                                        <Box
                                            display={"flex"}
                                            sx={{ justifyContent: { xs: "start", md: "center" } }}
                                            flexDirection={"row"}
                                        ></Box>
                                    </Grid>

                                    <Grid item xs={"auto"}>
                                        {/*    Box 3*/}
                                        <Box
                                            display={"flex"}
                                            justifyContent={"start"}
                                            flexDirection={"row"}
                                        ></Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                                <Button type={"submit"}>Save</Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default UpdateVendorModal