import React, {useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import GridData from "../../Components/GridData";
import '../../App.css'
import {useNavigate, useParams} from "react-router-dom";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {useSelector} from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import {toast} from "react-toastify";

const Billpage = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {token} = useSelector((state)=> state.USER_SLICE)
    const navigate = useNavigate()


    const [rows, setRows] = useState([]);

    const ToEditBillPageWithID = (id) =>{
        navigate(`/bills/edit/${id}`)
    }

    const getAndSetDeliveryNotes = async () =>{
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/get_list/delivery_note`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            let deliveryNotes = axiosRes.data.data;
            console.log(deliveryNotes)
            deliveryNotes = deliveryNotes.filter(item => item.status === "Active" && item.billorder === null);

            if(deliveryNotes.length === 0){
                toast.warning('No delivery notes to create Purchase Bill')
            }else{
                for(let note of deliveryNotes){
                    const {id,smo_no,customer,vendor,created_at,due_date} = note
                    const rows1 = {
                        id,
                        smo_no,
                        vendor_name:vendor?vendor.name:customer.name,
                        created_at:dayjs(created_at).format(`YYYY-MM-DD`),
                        due_date
                    }

                    setRows(preRows => [...preRows,rows1])
                }
            }



        }catch (err){
            // console.log(err)
            const errmsg = err.response.data.message
            if (errmsg === 'DeliveryNote not found.'){
                toast.warning('No Purchase Bill Found')
            }

        }



    }

    useEffect(() => {
        getAndSetDeliveryNotes()

    }, []);

    const columns = [

        {field: 'id', headerName: 'ID',headerClassName:'columnCSS', width: 70},
        {field: 'vendor_name', headerName: 'Customer',headerClassName:'columnCSS', width: 140,},
        {field: 'created_at', headerName: 'Date-Created',headerClassName:'columnCSS', width: 130},
        {field: 'smo_no', headerName: 'SMO-No#',headerClassName:'columnCSS', width: 140},

        {field: 'due_date', headerName: 'Date',headerClassName:'columnCSS', width: 160},
        // {field: 'AmountDue', headerName: 'Amount Date',headerClassName:'columnCSS', width: 160,},
        {field: 'Actions', headerName: 'Actions',headerClassName:'columnCSS', width: 160,
            renderCell: (params) => (
                <ButtonGroup>
                    <Button onClick={()=> ToEditBillPageWithID(params.row.id)}>
                        <EditNoteOutlinedIcon/>
                    </Button>

                    {/*<Button  onClick={() => console.log(params.row.id)}>*/}
                    {/*    <DeleteForeverOutlinedIcon sx={{color:'red'}}/>*/}
                    {/*</Button>*/}
                </ButtonGroup>
            ),
        }
    ];



    const toAddBill = () =>{
        navigate('/add-bills')
    }

    return (
        <>
        <Box padding={'2rem'}>
            <Box display={"flex"} justifyContent={"start"}>
                <Typography variant={'h3'}>
                   Create Purchase Bill
                </Typography>
                {/*<Typography >*/}
                {/*   These are delivery notes that has been approved*/}
                {/*</Typography>*/}


                {/*<Button variant={"contained"} onClick={toAddBill}>*/}
                {/*    Create Bill*/}
                {/*</Button>*/}
            </Box>
            <Box paddingTop={'3.45rem'}>
                <GridData rowsP={rows} columnsP={columns}/>
            </Box>

        </Box>
    </>
    )
}
export default Billpage
