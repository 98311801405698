import React from "react";
import {Alert, Box, Button, Stack, TextField, Typography,} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {useFormik} from "formik";
import axios from "axios";
import {useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";


const AddCustomer = () => {
    const {token} = useSelector((state) => state.USER_SLICE)
    const navigate = useNavigate()

    const BASE_URL = process.env.REACT_APP_BASE_URL
    const createProductOrService = async (values) =>{
        try{
            const {name,description,buyThis,price} = values
            const newPorS = {
                name,
                description,
                buy_this:buyThis,
                price
            }
            const axiosRes = await axios.post(`${BASE_URL}/api/product`,newPorS,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })

            const {success} = axiosRes.data
            if(success === true) {
                navigate('/products-&-services')
                toast.success('Created New Product Successfully')
            }
        } catch (err) {

        }

    }

    const formik = useFormik(({
        initialValues:{
            name:'',
            description:'',
            price:0,
            buyThis:0
        },
        onSubmit:(values)=>{
            createProductOrService(values)
        }
    }))




    return (
        <>
            {/*<Alert severity="success" onClose={() => {}}>This is a success Alert.</Alert>*/}

            {/*<ToastContainer/>*/}
            <Box mt={5} pl={5} mb={'5rem'}>
                <Box>
                    <Typography fontSize={"27px"} fontWeight={600}>
                        Add a Product or Service
                    </Typography>
                </Box>

                <Box borderTop={"1px solid black"} pt={'1rem'}>


                    <Box>
                        <Stack
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            spacing={1}
                            flexDirection={"column"}

                        >
                            <form onSubmit={formik.handleSubmit}>


                            <Box sx={{display: {sm: "block", md: "block"}}}>
                                <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                    Name
                                </Typography>
                                <TextField
                                    id="customer-Name"
                                    label="Name"
                                    type="text"
                                    fullWidth={true}
                                    required
                                    {...formik.getFieldProps('name')}

                                />
                            </Box>

                            <Box sx={{display: 'block'}} minWidth={'150px'}>
                                <Typography flexWrap={"wrap"} variant={'h6'} textAlign={"center"} mt={"4.5%"}
                                            mx={'10px'}>
                                    Description
                                </Typography>
                                <TextField
                                    id="customer-full-name"
                                    label="Description"
                                    type="text"
                                    multiline
                                    minRows={5}
                                    required
                                    {...formik.getFieldProps('description')}
                                />
                            </Box>


                            <Box sx={{display: {sm: "block", md: "block"}}}>
                                <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                    Price
                                </Typography>
                                <TextField
                                    id="customer-phone"
                                    label="Price"
                                    type="number"

                                    {...formik.getFieldProps('price')}
                                />
                            </Box>


                            <Box sx={{display: {sm: "flex", md: "flex"},justifyContent:'center'}}>
                                <Typography variant={'h6'} textAlign={"center"} mt={"4.5%"} mx={'10px'}>
                                    Buy This
                                </Typography>
                                <Checkbox
                                    {...formik.getFieldProps('buyThis')}
                                    onChange={(event)=>{
                                        formik.setFieldValue('buyThis', event.target.checked ? 1 : 0);
                                    }}
                                />

                            </Box>


                            <Box textAlign={'center'} mt={'10px'}>
                                <Button type={'submit'}>
                                    Save
                                </Button>
                            </Box>
                            </form>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AddCustomer;
