import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const AdditionalThings = () => {
  const things = [
    "add a costomer",
    "add a vendor",
    "customize your invoices",
    "invite a guest collaborator",
    "professional accounting help",
  ];

  const invoices = [
    { text: "comming due", amount: 500 },
    { text: "1-30 days overdue", amount: 500 },
    { text: "31-60", amount: 500 },
    { text: "61-90", amount: 500 },
  ];

  const dummyData = [
    { id: 2021, name: "9922333", age: 254293694878 },
    { id: 2022, name: "9922333", age: 9922333 },
    { id: 2023, name: "9922333", age: 9922333 },
  ];


  const pie = {
      
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  
  
  };

  return (
    <>
      <Box maxWidth={"1400px"} px={3} mt={3}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Box>
              <Typography
                variant="h5"
                textTransform={"capitalize"}
                color={"black"}
                fontWeight={"700"}
              >
                Things you can do
              </Typography>

              <Box mt={2}>
                {things &&
                  things.map((item, index) => (
                    <Typography
                      key={index}
                      textTransform={"capitalize"}
                      color={"blue"}
                      my={1}
                    >
                      {item}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </Grid>

          <Grid item sm={8} xs={12}>
            <Typography
              variant="h5"
              textTransform={"capitalize"}
              color={"black"}
              fontWeight={"700"}
            >
              Payable and owning
            </Typography>

            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h5"
                    textTransform={"capitalize"}
                    color={"black"}
                    fontWeight={"400"}
                    borderBottom={"1px solid black"}
                  >
                    Invoices payable to you
                  </Typography>

                  {invoices &&
                    invoices.map((invoice, index) => (
                      <Box
                        key={index}
                        display={"flex"}
                        borderBottom={"1px solid #00000045"}
                        my={"10px"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          fontWeight={400}
                          fontSize={"20px"}
                          textTransform={"capitalize"}
                        >
                          {invoice.text}
                        </Typography>

                        <Typography
                          fontWeight={400}
                          fontSize={"20px"}
                          textTransform={"capitalize"}
                        >
                          ${invoice.amount}
                        </Typography>
                      </Box>
                    ))}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h5"
                    textTransform={"capitalize"}
                    color={"black"}
                    fontWeight={"400"}
                    borderBottom={"1px solid black"}
                  >
                    Bills you owe
                  </Typography>

                  {invoices &&
                    invoices.map((invoice, index) => (
                      <Box
                        key={index}
                        display={"flex"}
                        borderBottom={"1px solid #00000045"}
                        my={"10px"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          fontWeight={400}
                          fontSize={"20px"}
                          textTransform={"capitalize"}
                        >
                          {invoice.text}
                        </Typography>

                        <Typography
                          fontWeight={400}
                          fontSize={"20px"}
                          textTransform={"capitalize"}
                        >
                          ${invoice.amount}
                        </Typography>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
                <Typography
                textTransform={'capitalize'}
                variant="h5"
                fontWeight={700}
                >
                    net income
                </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontSize:'14px'}}>Fiscal year</TableCell>
                      <TableCell sx={{fontSize:'14px'}}>Previous</TableCell>
                      <TableCell sx={{fontSize:'14px'}}>Current</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dummyData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{fontSize:'18',fontWeight:'500'}}>{row.id}</TableCell>
                        <TableCell sx={{fontSize:'18',fontWeight:'500'}}>{row.name}</TableCell>
                        <TableCell sx={{fontSize:'18',fontWeight:'500'}}>{row.age}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box mt={2}>
                <Typography
                  textTransform={'capitalize'}
                  variant="h5"
                  fontWeight={700}
                >
                    Expense Breakdown
                </Typography>

            <ReactApexChart options={pie.options} series={pie.series} type="donut"/>

            </Box>

          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdditionalThings;
