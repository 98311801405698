import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputProps,
} from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UpdateUserPage = (props) => {
  const style = {
    position: "absolute",
    top: "10%",
    left: "35%",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000000a6",
    boxShadow: 24,
    p: 4,
    borderRadius: "27px",
    height: "50vh", // Adjusted height to accommodate new fields
    overflow: "scroll",
  };

  const { fetchedUser, btn, text, token, setReload } = props;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const updateTax = async (name, tax_rate, id) => {
  //   const axiosRes = await axios.patch(
  //     `${baseuri}/api/tax/${id}`,
  //     { name, tax_rate },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json", // Adjust the content type as needed
  //       },
  //     }
  //   );

  //   const {success} = axiosRes.data

  //   if(success === true) {
  //     const updatedTax = axiosRes.data.data; // Assuming the API response contains the updated tax

  //     dispatch(updatetax(updatedTax));
  //     toast.success('Tax Updated Successfully')
  //   }

  // };

  const updateUser = async (values) => {
    try {
      const id = fetchedUser.id;
      const { name, email, role, password } = values;
      const axiosRes = await axios.put(
        `${BASE_URL}/api/users/${id}?name=${name}&email=${email}&role_id=${role}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = await axiosRes.data;
      if (success) {
        navigate("/user-list");
        toast.success("Updated User Successfully");
        handleClose();
        setReload((prev) => !prev);
      } else {
        toast("Something went wrong, try again");
      }
    } catch (err) {
      console.error(err);
      toast.warning(err.response?.data?.message || "An error occurred");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: fetchedUser.name,
      email: fetchedUser.email,
      role: fetchedUser.role_id,
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (values.password === values.confirmPassword) {
        updateUser(values);
      }
      console.log(token);
    },
  });

  return (
    <>
      <Button onClick={handleOpen}>{btn}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ height: "100vh", overflow: "scroll" }}
      >
        <Box mt={5} pl={5} mb={"5rem"} sx={style}>
          <Box pt={"1rem"}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                flexDirection={"column"}
              >
                <Box sx={{ display: { sm: "block", md: "block" } }}>
                  <Typography
                    variant={"h6"}
                    textAlign={"center"}
                    mt={"4.5%"}
                    mx={"10px"}
                  >
                    Name
                  </Typography>
                  <TextField
                    id="user-name"
                    label="User Name"
                    type="text"
                    fullWidth
                    required
                    {...formik.getFieldProps("name")}
                  />
                </Box>

                <Box sx={{ display: { sm: "block", md: "block" } }}>
                  <Typography
                    variant={"h6"}
                    textAlign={"center"}
                    mt={"4.5%"}
                    mx={"10px"}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="user-email"
                    label="Email"
                    type="text"
                    required
                    {...formik.getFieldProps("email")}
                  />
                </Box>

                <Box sx={{ display: { sm: "block", md: "block" } }}>
                  <Typography
                    variant={"h6"}
                    textAlign={"center"}
                    mt={"4.5%"}
                    mx={"10px"}
                  >
                    Role
                  </Typography>
                  <FormControl>
                    <InputLabel id="role-select-label">Role</InputLabel>
                    <Select
                      required
                      labelId="role-select-label"
                      id="role-select"
                      label="Role"
                      sx={{ minWidth: "210px" }}
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={"1"}>Admin</MenuItem>
                      <MenuItem value={"2"}>Senior Auditor</MenuItem>
                      <MenuItem value={"3"}>Secretary</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ display: { sm: "block", md: "block" } }}>
                  <Typography
                    variant={"h6"}
                    textAlign={"center"}
                    mt={"4.5%"}
                    mx={"10px"}
                  >
                    Password
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>

                <Box sx={{ display: { sm: "block", md: "block" } }}>
                  <Typography
                    variant={"h6"}
                    textAlign={"center"}
                    mt={"4.5%"}
                    mx={"10px"}
                  >
                    Confirm Password
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      id="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      {...formik.getFieldProps("confirmPassword")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {formik.errors.confirmPassword ? (
                    <Typography color="error" variant="body2">
                      {formik.errors.confirmPassword}
                    </Typography>
                  ) : null}
                </Box>

                <Box>
                  <Button type={"submit"}>Save User</Button>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateUserPage;
