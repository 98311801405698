import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customers:[],
    isLoading:false
}

const customerSlice = createSlice({
    name:"Customer-SLICE",
    initialState,
    reducers:{
        setCustomers:(state,action)=>{
            const {customers,isLoading} = action.payload

            state.customers = customers
            state.isLoading = isLoading
        },
        deleteSliceCustomer: (state, action) => {
            const idToRemove = action.payload;
            state.customers = state.customers.filter((customers) => customers.id !== idToRemove);
        },
        updateSliceCustomer: (state, action) => {
            const {updatedCustomer} = action.payload

            state.customers = state.customers.map((customer) =>
                customer.id === updatedCustomer.id ? updatedCustomer : customer
            );
            console.log(updatedCustomer)
        }
    }
})

export const {
    setCustomers,
    updateSliceCustomer,
    deleteSliceCustomer
} = customerSlice.actions

export default customerSlice.reducer