import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setBillNoteLoading } from "../../redux-store/Slices/billNoteSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const PurchaseBillDetailPage = () => {
  // const { taxes,isLoading } = useSelector((state) => state.TAX_SLICE);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const { billNote, loading } = useSelector((state) => state.BILL_NOTE_SLICE);
  // const { vendors } = useSelector((state) => state.VENDOR_SLICE);
  const [customers, setCustomers] = useState([]);
  const [vendors, seVendors] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const deliveryNoteId = params.id;

  const getAndSetDeliveryNote = async () => {
    dispatch(setBillNoteLoading({ loading: true }));

    try {
      const axiosRes = await axios.get(
        `${BASE_URL}/api/get_list/delivery_note/${deliveryNoteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;

      if (success === true) {
        const fetchedDeliveryNote = axiosRes.data.data[0];

        if (fetchedDeliveryNote && taxes) {
          setFormValues(fetchedDeliveryNote);
        }
      }

      dispatch(setBillNoteLoading({ loading: false }));
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching delivery note:", error);
      dispatch(setBillNoteLoading({ loading: false }));
    }
  };

  const fetchCustomers = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const vendorArray = data.data;
      setCustomers(vendorArray);
      console.log(customers);
    } catch (err) {
      toast.info(err.response.data.message);
    }
  };
  const getAndSetTaxes = async () => {
    try {
      // dispatch(setTaxes({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/tax`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        // dispatch(setTaxes({ taxes: axiosRes.data.data}));
        setTaxes(axiosRes.data.data);
        console.log(axiosRes.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.warning(err.response.data.message);
    }
  };

  const getAndSetVendors = async () => {
    try {
      // dispatch(setVendors({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        // dispatch(setVendors({ vendors : axiosRes.data.data, isLoading:false}));
        seVendors(axiosRes.data.data);
      }
    } catch (err) {
      // toast.warning(err.response.data.message)
      console.log(err);
    }
  };

  const updatePurchaseBill = async (values) => {
    try {
      // dispatch(setVendors({ isLoading: true }));
      const {
        bill_note_id,
        currency,
        vendor,
        billDate,
        delivery_note_id,
        boxNumber,
        note,
        items,
        item_tax_ids,
      } = values;

      const payload = {
        bill_note_id,
        currency_id: currency,
        vendor_id: vendor,
        bill_date: dayjs(billDate).format(`YYYY-MM-DD`),
        delivery_note_id,
        box: boxNumber,
        note,
        // product_id:items.map((item) => (item.id)),
        item_ids: items.map((item) => item.id),
        expense_category: items.map((item) => item.expense_category),
        price: items.map((item) => item.price),
        products: items.map((item, index) => ({
          product_id: item.product_id,
          tax: item_tax_ids[index],
        })),
      };

      console.log("payload is =>", payload);

      const axiosRes = await axios.post(
        `${BASE_URL}/api/update/bill_note`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );

      const { success } = axiosRes.data;
      if (success === true) {
        // dispatch(setVendors({ vendors : axiosRes.data.data, isLoading:false}));
        toast.success("Updated Purchase Bill Successfully");
      }
    } catch (err) {
      // toast.warning(err.response.data.message)
      console.log(err);
    }
  };

  const getAndSetCurrencies = async () => {
    // dispatch(setCurrencies({ isLoading: true }));

    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/currency`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        // await dispatch(setCurrencies({ currencies: axiosRes.data.data, isLoading: false }));
        setCurrencies(axiosRes.data.data);
        console.log(axiosRes.data.data);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      // dispatch(setCurrencies({ isLoading: false }));
    }
  };

  useEffect(() => {
    getAndSetVendors();
    const getValuesAndSetThem = async () => {
      await getAndSetDeliveryNote();
    };
    getAndSetTaxes();
    getValuesAndSetThem();

    fetchCustomers();
    getAndSetCurrencies();
  }, []);

  const navigate = useNavigate();

  const setFormValues = (values) => {
    formik.setValues({
      ...formik.values,
      vendor: values.billorder?.vendor.id,
      customer: values.customer.id,
      currency: values.billorder.currency_item.id,
      bill_note_id: values.billorder?.id,
      dueDate: dayjs(values.due_date),
      billDate: dayjs(values.bill_date),
      delivery_note_id: values.id,
      item_tax_ids: values.items.map((item) =>
        item.tax.split(",").map((tax) => Number(tax))

        // const taxIds = item.tax?.split(',').map((id) => parseInt(id, 10) ?? []);
        //
        // return taxIds?.map((taxId) => taxes.find((tax) => tax.id === taxId)?.name).join(',') ?? [];
      ),
      product_id: values.items.map((item) => item.product_id),
      fileNo: values.file_no,
      soPo: values.smo_no,
      items: values.items,
      price: values.items.map((item) => item.price),
      note: values.billorder.note,
      boxNumber: values.billorder.box,
      totalPrice: values.items.reduce((total, item) => {
        return total + item.price * item.qty;
      }, 0),
    });

    console.log("values setted");
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      vendor: billNote.vendor_id,
      customer: billNote.customer_id,
      currency: "",
      dueDate: billNote.due_date,
      fileNo: billNote.file_no,
      billDate: "",
      soPo: billNote.smo_no,
      boxNumber: "",
      note: "",
      // items: billItems,
      expense_category: [],
      price: [],
      tax: [],
    },
    onSubmit: (values) => {
      updatePurchaseBill(values);
      console.log(values);
    },
  });

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <Box padding={"2rem"}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <Box mb={"2rem"}>
                  <h1>Create a new Bill</h1>
                </Box>

                <Grid
                  container
                  spacing={1}
                  display="flex"
                  // justifyContent={"center"}
                >
                  <Grid item xs={"auto"}>
                    {/*    Box 1*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            // paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Customer
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <Autocomplete
                                disabled={true}
                                id="customer"
                                options={customers}
                                sx={{ width: 170 }}
                                value={
                                  customers.find(
                                    (customer) =>
                                      customer.id === formik.values.customer
                                  ) || null
                                }
                                // defaultValue={formik.values.customer_id}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("customer", newValue.id);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Customers" />
                                )}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency
                          </Typography>
                          <Box minWidth={"170px"}>
                            <Autocomplete
                              id="customer"
                              options={currencies}
                              sx={{ width: 170 }}
                              value={
                                currencies.find(
                                  (currency) =>
                                    currency.id === formik.values.currency
                                ) || null
                              }
                              // defaultValue={formik.values.customer_id}
                              getOptionLabel={(option) =>
                                option.abbreviation || ""
                              }
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              autoComplete={false}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("currency", newValue.id);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Currency" />
                              )}
                            />
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            File NO#
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                {...formik.getFieldProps("fileNo")}
                                disabled={true}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 2*/}

                    <Box
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      mb={"15px"}
                    >
                      <Typography
                        variant={"h6"}
                        paddingLeft={"16px"}
                        paddingTop={"11px"}
                        paddingRight={"13px"}
                      >
                        Vendor
                      </Typography>
                      <Box minWidth={"170px"}>
                        <FormControl fullWidth>
                          <Autocomplete
                            id="vendors"
                            options={vendors}
                            sx={{ width: 170 }}
                            value={
                              vendors.find(
                                (vendor) => vendor.id === formik.values.vendor
                              ) || null
                            }
                            // defaultValue={formik.values.customer_id}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            autoComplete={false}
                            onChange={(event, newValue) => {
                              formik.setFieldValue("vendor", newValue.id);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="vendors" />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <Box display={"flex"} justifyContent={"space-around"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    id="due-date"
                                    value={dayjs(formik.values.dueDate)}
                                    onChange={(newValue) => {
                                      formik.setFieldValue("dueDate", newValue);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display={"flex"} paddingLeft={"7px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Bill Date
                          </Typography>
                          <Box width={"200px"} mt={"20px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    id="due-date"
                                    value={dayjs(formik.values.billDate)}
                                    onChange={(newValue) => {
                                      formik.setFieldValue(
                                        "billDate",
                                        newValue
                                      );
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingTop={"15px"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"12px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            SMO NO#
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}

                              <TextField
                                disabled
                                id="sopo-no"
                                variant="outlined"
                                {...formik.getFieldProps("soPo")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 3*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Box #
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="box-number"
                                // disabled={true}
                                label=""
                                variant="outlined"
                                {...formik.getFieldProps("boxNumber")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingLeft={"10px"}
                          paddingTop={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Note
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="bill-note"
                                label=""
                                // disabled={true}
                                variant="outlined"
                                multiline
                                rows={"5"}
                                {...formik.getFieldProps("note")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box></Box>
              </Box>
            </Box>

            <Box
              mt={"8rem"}
              display={"flex"}
              justifyContent={"center"}
              marginBottom={"10rem"}
            >
              <Box overflow={"scroll"}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Expense Category</td>
                      <td>Description</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                      <td>Old Price</td>
                      <td>Price</td>
                      <td>Tax</td>
                      <td>Amount</td>
                      <td>Amount After Taxes</td>
                    </tr>
                  </thead>

                  <tbody>
                    {formik.values.items &&
                      formik.values.items.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            <TextField
                              id={`expense-category-${row.id}`}
                              sx={{ width: 200 }}
                              type="text"
                              value={row.expense_category || null}
                              // disabled={true}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.expense_category`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `expense_category.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-description-${row.id}`}
                              minRows={2}
                              sx={{ width: 230 }}
                              multiline
                              // value={row.description}
                              disabled
                              {...formik.getFieldProps(
                                `items.${index}.description`
                              )}
                              maxRows={10}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-${row.id}`}
                              {...formik.getFieldProps(`items.${index}.qty`)}
                              disabled
                              sx={{ width: 80 }}
                              type="number"
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-type-${row.id}`}
                              {...formik.getFieldProps(
                                `items.${index}.qty_type`
                              )}
                              disabled
                              sx={{ width: 80 }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`old-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          <td>
                            <TextField
                              id={`item-price-${row.id}`}
                              label="price"
                              // disabled={true}
                              sx={{ width: 80 }}
                              type="number"
                              {...formik.getFieldProps(`items.${index}.price`)}
                            />
                          </td>
                          <td>
                            {/*<TextField*/}
                            {/*    id={`item-tax`}*/}
                            {/*    sx={{ width: 100 }}*/}
                            {/*    // defaultValue={formik.values.item_tax_ids}*/}
                            {/*    disabled={true}*/}
                            {/*    type={'text'}*/}
                            {/*    multiline={true}*/}
                            {/*    rows={3}*/}
                            {/*    {...formik.getFieldProps('item_tax_ids')}*/}
                            {/*/>*/}

                            {Array.isArray(taxes) && (
                              // <Autocomplete
                              //     // multiple
                              //     disablePortal
                              //     key={`tax-autocomplete-${row.id}`}
                              //     value={taxes.find(tax => tax.id === 1) || null}
                              //     options={taxes.map((tax, index) => ({
                              //         label: `${tax.name} (${tax.tax_rate}%)`,
                              //         value: tax.tax_rate,
                              //         id: tax.id,
                              //         key: `tax-${index}`,
                              //     }))}
                              //     sx={{ width: 240 }}
                              //     renderInput={(params) => <TextField {...params} label="Choose" />}
                              //     getOptionLabel={(option) => option.label}
                              //     // onChange={(event, newValue) => {
                              //     //     formik.setFieldValue(`tax.${index}`, newValue.map(  (option) => Number(option.id)));
                              //     // }}
                              // />
                              <Autocomplete
                                id="taxes"
                                multiple
                                options={taxes}
                                sx={{ width: 170 }}
                                value={
                                  formik.values.item_tax_ids
                                    ? formik.values.item_tax_ids[index]
                                        .map((id) =>
                                          taxes.find((tax) => tax.id === id)
                                        )
                                        .filter(Boolean)
                                    : []
                                }
                                getOptionLabel={(option) =>
                                  `${option.name} (${option.tax_rate}%)` || ""
                                }
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                autoComplete={false}
                                onChange={(event, newValue) => {
                                  formik.setFieldValue(
                                    `item_tax_ids[${index}]`,
                                    newValue.map((option) => option.id)
                                  );

                                  // formik.setFieldValue(`item_tax_ids`, newValue.map(option => option.id));
                                  console.log(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="taxes" />
                                )}
                              />
                            )}
                          </td>

                          <td style={{ marginLeft: "10px" }}>
                            <p>$ {row.price * row.qty}</p>
                          </td>

                          <td style={{ marginLeft: "10px" }}>
                            <p>$ {row.priceAfterTax ? row.priceAfterTax : 0}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <Box mr={"1rem"}>
                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>SubTotal:</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*    <p>Total Amount: $</p>*/}
                  {/*    <p>{formik.values.totalPrice}</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>Total (EUR at 0.0002351439)</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  navigate("/bills-list");
                }}
              >
                Go Back
              </Button>

              <Button type={"submit"}>submit</Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};
export default PurchaseBillDetailPage;
