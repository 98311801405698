
import React from "react";

import GridData from "../Components/GridData";
import Charts from "../Components/Charts";
import Overdue from "../Components/Overdue";
import AdditionalThings from "../Components/AdditionalThings";


const HomePage = () => {
 

  return (
    <div>
     <Charts/>
     {/* <GridData/> */}
     <Overdue/>
     <AdditionalThings/>
    </div>
  );
};

export default HomePage;
