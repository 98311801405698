import React, { useEffect } from "react";
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { setTaxes , addtax, deletetax,updatetax} from "../../redux-store/Slices/taxSlice";
import { useDispatch, useSelector } from "react-redux";
import TaxUpdateModal from "../../Components/TaxUpdateModal";
import {toast} from 'react-toastify'
import GridData from "../../Components/GridData";
import ConformDeleteModal from "../../Components/ConformDeleteModal";


const TaxPage = () => {
  const { token } = useSelector((state) => state.USER_SLICE);
  const { taxes } = useSelector((state) => state.TAX_SLICE);
  const dispatch = useDispatch();
  const baseuri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getAndSetTaxes();
  }, []);

  const getAndSetTaxes = async () => {

      try {
          dispatch(setTaxes({ isLoading: true }));

          const axiosRes = await axios.get(`${baseuri}/api/tax`, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json", // Adjust the content type as needed
              },
          });

          const { success } = axiosRes.data;
          if (success === true) {
              dispatch(setTaxes({ taxes: axiosRes.data.data, isLoading: false }));
              console.log(taxes)
          }
      }catch (err) {
          console.log(err)
          toast.warning(err.response.data.message)
      }

  };

  const deleteTax = async (id) => {
   const axiosRes =  await axios.delete(`${baseuri}/api/tax/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Adjust the content type as needed
      },
    });
    const {success} = axiosRes.data
    if (success === true) {
      dispatch(deletetax(id))

      toast.info('Deleted Tax Successfully')
    }
    

  };

  const createTax = async (name, tax_rate) => {
    const axiosRes = await axios.post(
      `${baseuri}/api/tax`,
      { name, tax_rate },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      }
    );

    const {success} = axiosRes.data

    if(success === true){
      const newtax = axiosRes.data.data

      dispatch(addtax(newtax))

      toast.success(' New Tax Added Successfully')
    }

  };

  const updateTax = async (name, tax_rate, id) => {
    const axiosRes = await axios.patch(
      `${baseuri}/api/tax/${id}`,
      { name, tax_rate },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      }
    );

    const {success} = axiosRes.data

    if(success === true) {
      const updatedTax = axiosRes.data.data; // Assuming the API response contains the updated tax

      dispatch(updatetax(updatedTax));
      toast.success('Tax Updated Successfully')
    }

  };


  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Tax Name", width: 120 },
    { field: "tax_rate", headerName: "Tax %", width: 180 },
    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
          <ButtonGroup>
            <TaxUpdateModal
                btn={<EditIcon />}
                text={"Edit Tax"}
                taxName={params.row.name}
                tax_rate={params.row.tax_rate}
                taxid={params.row.id}
                // TAX={params.row}
                method={updateTax}
                // method={()=>{
                //   console.log(params.row)}}
            />
            {/*<Button onClick={() => console.log(params.row.id)}>*/}
            {/*  <EditNoteOutlinedIcon/>*/}
            {/*</Button>*/}
              <ConformDeleteModal
              btn={<DeleteIcon sx={{color:'red'}}/>}
              text={`Delete Tax "${params.row.name}" ?`}
              method={deleteTax}
              taxID={params.row.id}
              />

          </ButtonGroup>
      ),
    },

  ];

  return (
    <>
      <Box padding={"2rem"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant={"h3"}>Taxes</Typography>
          {/* <Button variant={"contained"} onClick={navigateToCreateCurrency}>
                        Create Tax
                    </Button> */}
          <TaxUpdateModal

            btn={"Create Tax"}
            text={"Create a new tax"}
            taxName={""}
            tax_rate={0}
            method={createTax}
          />
        </Box>
        <Box>
            {taxes ? <GridData columnsP={columns} rowsP={taxes} /> :
                <h3>No Tax found Please Add Tax to show table</h3>

            }

        </Box>
      </Box>
    </>
  );
};
export default TaxPage;
