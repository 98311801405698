import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import axios from 'axios';
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius:'27px'
};



const ConformApprovePurchaseBill = (props) => {
    const {text,noteID,btn,btnText,disableOrNot,runAgain} = props
    const navigate = useNavigate()

    const [open, setOpen] = React.useState(false);

    const {token} = useSelector((state)=> state.USER_SLICE)
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const updateDeliveryNoteStatus = async (noteID) =>{
        console.log(noteID)
        try {
            const axiosRes = await axios.get(`${BASE_URL}/api/update_status/${noteID}/bill_orders/Active`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            const {success} = axiosRes.data
            if(success){
                console.log(axiosRes.data)
                toast.success(`Approved Delivery Note ${noteID} Successfully`)
                runAgain(true)
                handleClose()
            }

        }catch (err){
            console.log(err)
            toast.warning(err.response.data.message)
            handleClose()
        }
    }


    return (
        <div>
            <Button disabled={disableOrNot} onClick={handleOpen}>{btn}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>

                        <Box>
                            <Box mb={"2rem"} display={"flex"} justifyContent={"center"}>
                                <h3>{text}</h3>
                            </Box>


                        </Box>
                        <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                            <Button
                                onClick={()=>{updateDeliveryNoteStatus(noteID)}}
                                // onClick={()=>{console.log(noteID)}}


                                color={"warning"}

                            >
                                {btnText?btnText: 'Delete'}
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ConformApprovePurchaseBill