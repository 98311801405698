import React, { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { addCurrency } from "../../redux-store/Slices/currencySlice";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";

const AddBillPage = () => {
  const { token } = useSelector((state) => state.USER_SLICE);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const createCurrency = async (newCurrency) => {
    try {
      const axoisres = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/currency`,
          newCurrency,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
      );
        const {success} = axoisres.data
      if(success === true){
        dispatch(addCurrency(axoisres.data.data))

        navigate('/currency')

        toast.success('Created Currency Successsfully')
      }else if (success === false){
        toast.info('please make sure values are correct')
      }
    } catch (err) {
      const errorData = err.response.data.data;
      Object.keys(errorData).forEach(key => {
        const errorMessages = errorData[key];
        errorMessages.forEach(errorMessage => {
          toast.warning(`${key}: ${errorMessage}`);
        });
      });
    }

  };

  const formik = useFormik({
    initialValues: {
      currency_name: "",
      abbreviation: "",
      bank_name: "",
      bank_address: "",
      iban: "",
      swift_code: "",
      sort_code: "",
      accountant: "",
      code: "",
      beneficiary_name: "",
      beneficiary_address: "",
    },
    onSubmit: (values) => {
      let aCurrency = values;
      createCurrency(aCurrency);
      console.log(aCurrency);
    },
  });

  return (
    <>
      <Box padding={"2rem"}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>Create a new Currency</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}

                        >
                          Currency Name
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                                required
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("currency_name")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"h6"} padding={"15px"}>
                          Currency Abbrivation
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                                required
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("abbreviation")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          variant={"h6"}
                          mt={"15px"}
                          textAlign={"center"}
                        >
                          Bank Details
                        </Typography>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Bank Name
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                  required
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("bank_name")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Bank Address
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("bank_address")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            IBAN
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                  required
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("iban")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Swift Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("swift_code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Sort Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("sort_code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Account Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("accountant")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        
                        
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Code
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("code")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Benifishry Name
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("beneficiary_name")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"15px"}
                          >
                            Benifishry Address
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                sx={{ width: 170 }}
                                {...formik.getFieldProps("beneficiary_address")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  ></Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 3*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  ></Box>
                </Grid>
              </Grid>

              <Box></Box>
            </Box>
            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
              <Button type={"submit"}>Save</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default AddBillPage;
