import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  Select,
  Typography,
  Badge,
  Tabs,
  Tab,
  Stack,
  collapseClasses,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import React, { useState } from "react";

const EstimatesPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <Stack width={{ xs: "75%", md: "70%" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize={"33px"}>Estimates</Typography>

            <Button>Create Estimate</Button>
          </Box>

          <Box display={"flex"} justifyContent={"center"} mt={3}>
            <Box>
              <Typography>
                {/* <Badge badgeContent={4} color="primary" sx={{marginRight:'10px'}}>
                  <MailIcon color="action" />
                </Badge> */}
                0 Filters active
              </Typography>

              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl sx={{ minWidth: "200px" }}>
                    <InputLabel>Customer</InputLabel>
                    <Select>
                      <MenuItem>Customer 1</MenuItem>
                      <MenuItem>Customer 2</MenuItem>
                      <MenuItem>Customer 3</MenuItem>
                      <MenuItem>Customer 4</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl sx={{ minWidth: "200px" }}>
                    <InputLabel>Type</InputLabel>
                    <Select>
                      <MenuItem>Type 1</MenuItem>
                      <MenuItem>Type 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        width: { xs: "200px", md: "200px" },
                        padding: "0",
                      }}
                      components={["DatePicker"]}
                    >
                      <DatePicker label="From" />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        width: { xs: "200px", md: "200px" },
                        padding: "0",
                      }}
                      components={["DatePicker"]}
                    >
                      <DatePicker label="To" />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label="Enter estimate #"
                    variant="outlined"
                    sx={{ padding: "0", marginTop: "8px" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Active" />
              <Tab label="Draft" />
              <Tab label="All" />
            </Tabs>
          </Box>

          <Box
            display={"flex"}
            mt={5}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Typography variant="h5" fontWeight={500}>
              Don't Underestimate it -- Create a new estimate
            </Typography>

            <Typography my={2}>
              Create and share a new estimate, then convert it to an invoice in
              record time
            </Typography>

            <Button sx={{ borderRadius: "20px" }} variant="outlined">
              Create estimate
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default EstimatesPage;
