import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Tab, Tabs, Typography } from "@mui/material";
import GridData from "../../Components/GridData";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { toast } from "react-toastify";
import ConformInvoiceModal from "./ConformInvoiceModal";

const InvoiceListPage = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  const [value, setValue] = React.useState(0);
  const [runagain, setRunAgain] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState("all");

  const handleFilterChange = (newValue) => {
    setFilterValue(newValue);
    console.log(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAndSetPurchaseBills = async () => {
    try {
      const axiosRes = await axios.get(
        `${BASE_URL}/api/get_list/delivery_note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;
      if (success) {
        let deliveryNotes = axiosRes.data.data;

        deliveryNotes = deliveryNotes.filter((item) => item.invoice !== null);
        console.log(deliveryNotes);
        setRows([]);
        for (let note of deliveryNotes) {
          const {
            id,
            customer,
            vendor,
            smo_no,
            created_at,
            due_date,
            file_no,
            invoice,
            billorder,
          } = note;
          if (invoice.status === "Active") {
            invoice.status = "Approved";
          }
          // const VendorName = vendor.name?vendor.name:'No vendor yet'
          const rows1 = {
            id,
            currency: billorder.currency_item.currency_name,
            vendor_name: billorder?.vendor?.name,
            created_at: dayjs(created_at).format(`YYYY-MM-DD`),
            due_date,
            file_no,
            smo_no,
            status: invoice.status,
            customer_name: customer.name,
            invoice_id: invoice.id,
          };

          setRows((preRows) => [...preRows, rows1]);
          setRunAgain(false);
        }
      }
    } catch (err) {
      const errmsg = err.response?.data.message;
      if (errmsg === "DeliveryNote not found.") {
        toast.warning("No Invoice Bill Found");
      }
    }
  };

  useEffect(() => {
    getAndSetPurchaseBills();
  }, [runagain]);

  const filteredRows = () => {
    if (filterValue === "all") {
      return rows;
    } else {
      return rows.filter((row) => row.status.toLowerCase() === filterValue);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", headerClassName: "columnCSS", width: 70 },
    {
      field: "vendor_name",
      headerName: "Customer",
      headerClassName: "columnCSS",
      width: 140,
    },
    {
      field: "customer_name",
      headerName: "Vendor",
      headerClassName: "columnCSS",
      width: 140,
    },
    {
      field: "created_at",
      headerName: "Date-Created",
      headerClassName: "columnCSS",
      width: 130,
    },
    {
      field: "smo_no",
      headerName: "SMO-No#",
      headerClassName: "columnCSS",
      width: 110,
    },
    {
      field: "file_no",
      headerName: "File N0",
      headerClassName: "columnCSS",
      width: 110,
    },

    {
      field: "due_date",
      headerName: "Date",
      headerClassName: "columnCSS",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      cellClassName: (params) => {
        if (params.value === "Draft") {
          return "custom-status-draft";
        } else if (params.value === "Approved") {
          return "custom-status-approved";
        }
        return "123123123";
      },
    },

    // {field: 'AmountDue', headerName: 'Amount Date',headerClassName:'columnCSS', width: 160,},
    {
      field: "Actions",
      headerName: "Actions",
      headerClassName: "columnCSS",
      width: 260,
      renderCell: (params) => (
        <ButtonGroup>
          <ConformInvoiceModal
            btn={"Approve"}
            text={`Approve Purchase Bill With ID ${params.row.id} ?`}
            btnText={`Approve Purchase Bill`}
            noteID={params.row.invoice_id}
            runAgain={setRunAgain}
            disableOrNot={params.row.status !== "Draft"}
            // method={}
          />

          <Button onClick={() => navigate(`/invoice-list/${params.row.id}`)}>
            <LaunchIcon />
          </Button>

          <Button onClick={() => console.log(params.row.id)}>
            <PictureAsPdfIcon sx={{ color: "red" }} />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <Box padding={"2rem"}>
        <Box display={"flex"} justifyContent={"start"}>
          <Typography variant={"h3"}>Invoice List</Typography>

          {/*<Button variant={"contained"} onClick={toAddBill}>*/}
          {/*    Create Bill*/}
          {/*</Button>*/}
        </Box>

        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="All" onClick={() => handleFilterChange("all")} />
            <Tab label="Draft" onClick={() => handleFilterChange("draft")} />
            <Tab
              label="Approved"
              onClick={() => handleFilterChange("approved")}
            />
          </Tabs>
        </Box>
        <Box paddingTop={"3.45rem"}>
          <GridData rowsP={filteredRows()} columnsP={columns} />
        </Box>
      </Box>
    </>
  );
};
export default InvoiceListPage;
