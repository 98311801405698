import { configureStore,combineReducers } from "@reduxjs/toolkit";
import userSlice from './Slices/userSlice'  
import taxSlice from './Slices/taxSlice'  
import vendorSlice from './Slices/vendorSlice'  
import currencySlice from "./Slices/currencySlice";
import billNoteSlice from "./Slices/billNoteSlice";
import customerSlice from "./Slices/customerSlice";
import productSlice from "./Slices/productsSlice";


const rootReducer = combineReducers({
        USER_SLICE:userSlice,
        TAX_SLICE:taxSlice,
        CURRENCY_SLICE:currencySlice,
        VENDOR_SLICE:vendorSlice,
        BILL_NOTE_SLICE:billNoteSlice,
        CUSTOMER_SLICE:customerSlice,
        PRODUCT_SLICE:productSlice
})

const reduxStore = configureStore({
    reducer:rootReducer
})

export default reduxStore