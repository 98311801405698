import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    billNote: {},
    loading: true,
    billItems: []
}


const billNoteSlice = createSlice({
    name: 'BILL_NOTE_SLICE',
    initialState,
    reducers: {
        setBillNote: (state, action) => {

            state.billNote = action.payload


        },
        setBillNoteLoading: (state, action) => {
            const {loading} = action.payload
            state.loading = loading

        },
        setBillNoteItems: (state, action) => {
            state.billItems = action.payload
        },
        setBillNoteItemPrice: (state, action) => {
            const { itemId, price } = action.payload;

                const itemIndex = state.billItems.findIndex(item => item.id === itemId);

                if (itemIndex !== -1) {
                    let QTY = state.billItems[itemIndex].qty;
                    state.billItems[itemIndex].price = price;
                    state.billItems[itemIndex].Totalprice = (price * QTY);
                }
        },
        setTaxIfNoTax:(state,action)=>{
          const {itemId,tax} = action.payload
            const itemIndex = state.billItems.findIndex(item => item.id === itemId)
            // const totalTaxRate = tax.reduce((acc, cur) => acc + parseFloat(cur), 0) ;
            if(tax === 0){
              state.billItems[itemIndex].priceAfterTax = state.billItems[itemIndex].price * state.billItems[itemIndex].qty
          }
            const items = state.billItems;
            let totalAmount = 0; // Initialize totalAmount outside the loop

            items.forEach(item => {
                item.priceAfterTax = item.priceAfterTax ? item.priceAfterTax : 0;
                item.price = item.price ? item.price : 0;

                console.log(`price is ${item.price}`);
                console.log(`price tax is ${item.priceAfterTax}`);

                // Accumulate the price and priceAfterTax for each item
                totalAmount += ((item.priceAfterTax * 1));
            });

            // Update state with the accumulated totalAmount
            state.billNote.totalAmount = totalAmount;
            console.log(`total price is ${state.billNote.totalAmount}`);
        },
        setBillNoteItemPriceWithTax: (state, action) => {
            const { itemId, tax } = action.payload;
            console.log(tax);

            const itemIndex = state.billItems.findIndex(item => item.id === itemId);
            const originalPrice = state.billItems[itemIndex].Totalprice;

            if (itemIndex !== -1) {
                let totalTaxRate = 0;

                // Calculate the total tax rate from the array of tax values
                if (Array.isArray(tax) && tax.length > 0) {
                    totalTaxRate = tax.reduce((acc, cur) => acc + parseFloat(cur), 0);
                }

                // Calculate the price after tax
                if (totalTaxRate === 0) {
                    state.billItems[itemIndex].priceAfterTax = originalPrice;
                } else {
                    const taxedAmount = (originalPrice / 100) * totalTaxRate;
                    state.billItems[itemIndex].priceAfterTax = originalPrice + taxedAmount;
                }
            }

        },


        setTotalBillAmount: (state, action) => {
            const items = state.billItems;
            let totalAmount = 0; // Initialize totalAmount outside the loop

            items.forEach(item => {
                item.priceAfterTax = item.priceAfterTax ? item.priceAfterTax : 0;
                item.price = item.price ? item.price : 0;

                console.log(`price is ${item.price}`);
                console.log(`price tax is ${item.priceAfterTax}`);

                // Accumulate the price and priceAfterTax for each item
                totalAmount += ((item.priceAfterTax * 1));
            });

            // Update state with the accumulated totalAmount
            state.billNote.totalAmount = totalAmount;
            console.log(`total price is ${state.billNote.totalAmount}`);
        }


    }
})


export const {
    setBillNote
    , setBillNoteLoading
    , setBillNoteItemPrice
    , setBillNoteItems
   , setTotalBillAmount
    , setBillNoteItemPriceWithTax
    , setTaxIfNoTax
} = billNoteSlice.actions

export default billNoteSlice.reducer