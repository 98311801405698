import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vendors:[],
    isLoading:false
}

const vendorSlice = createSlice({
    name:"VENDOR-SLICE",
    initialState,
    reducers:{
        setVendors:  (state,action)=>{
            const {vendors,isLoading} = action.payload

            state.vendors = vendors
            state.isLoading = isLoading

            console.log(state.vendors)
        },
        deleteSliceVendor: (state, action) => {
            const idToRemove = action.payload;
            state.vendors = state.vendors.filter((vendor) => vendor.id !== idToRemove);
        },
        updateSliceVendor: (state, action) => {
            const {updatedVendor} = action.payload

            state.vendors = state.vendors.map((vendor) =>
                vendor.id === updatedVendor.id ? updatedVendor : vendor
            );
            console.log(updatedVendor)
        }
    }
})

export const {
    setVendors,
    updateSliceVendor,
    deleteSliceVendor} = vendorSlice.actions

export default vendorSlice.reducer