import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import bankimg from "../images/pplbank.png";

const Charts = () => {
  const state = {
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "pie",
        height: 390,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
    },
  };


  const [bar, setbar] = useState(state);
  return (
    <Box mt={"70px"} maxWidth={"1400px"} px={3}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          textTransform={"capitalize"}
          variant="p"
          fontWeight={600}
          fontSize={"34px"}
        >
          DashBoard
        </Typography>

        <Button variant="contained">Create new</Button>
      </Box>
      <Grid container spacing={2} sx={{marginTop:'30px'}}>
        {/* Bar Chart */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              background: "#0000ff26",
              border: "1px solid #0000004a",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius:'50px',
              
            }}
          >
            <img
              src={bankimg}
              alt="bank-icon"
              height={"250px"}
              width={"250px"}
            />
            <Typography
              variant="h5"
              textTransform={"capitalize"}
              textAlign={"center"}
              fontWeight={500}
              mt={1}
            >
              connect your bank account or credit card
            </Typography>
            <Typography
              variant="p"
              fontWeight={400}
              textAlign={"center"}
              textTransform={"capitalize"}
              my={2}
              fontSize={'18px'}
            >
              automate your bookkeeopingby importing transections automatically
            </Typography>

            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                color: "black",
                borderColor: "black",
                marginBottom:'15px'
              }}
              
            >
              Connect to my account
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ReactApexChart
            options={bar.options}
            series={bar.series}
            type="bar"
          />
        </Grid>
        {/* Pie Chart */}
        
      </Grid>
    </Box>
  );
};

export default Charts;
