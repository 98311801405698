import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import axios from 'axios';
import {useEffect} from "react";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius:'27px'
  };



const TaxUpdateModal = (props) => {
    const {text,taxName,tax_rate,method,taxid,btn,TAX} = props

    useEffect(() => {
        setValues()
    }, [taxName,tax_rate]);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

   

    const formik = useFormik({
        initialValues: {
          taxName:taxName,
          taxPercentage:tax_rate,
        },
        validationSchema: YUP.object({
          taxName: YUP.string(),
          taxPercentage: YUP.number()
            .typeError("tax must be a number")
            .positive("tax % must be positive"),
        }),
        onSubmit: (values) => {
        //   createTax(values.taxName,values.taxPercentage)
          method(values.taxName,values.taxPercentage,taxid)
          handleClose()

          // console.log(values);
        },
      });

    const setValues = () =>{
        formik.setValues({
            taxName:taxName,
            taxPercentage:tax_rate,
        })
    }

  return (
    <div>
         <Button onClick={handleOpen}>{btn}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>{text}</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          TAX Name
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("taxName")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"h6"} padding={"15px"}>
                          TAX %
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              type={"number"}
                              error={
                                formik.touched.taxPercentage &&
                                Boolean(formik.errors.taxPercentage)
                              }
                              helperText={
                                formik.touched.taxPercentage &&
                                formik.errors.taxPercentage
                              }
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("taxPercentage")}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  ></Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 3*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
              <Button type={"submit"}>Save</Button>
            </Box>
          </form>
        </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default TaxUpdateModal