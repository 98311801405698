import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  List,
  ListItemIcon,
  Drawer,
  AccordionDetails,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { setUser } from "../redux-store/Slices/userSlice";

const Header = () => {
  const { name, token,role } = useSelector((state) => state.USER_SLICE);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutUser = async () => {
    dispatch(setUser({isLoading:true}))
    dispatch(setUser({name:'',token:'',isLoggedin:false,isLoading:false}))
  };

  const Adminlinks = [
    {
      name: "Dash Board",
      icon: <DashboardIcon />,
      to: "/",
    },
    {
      name: "Sale & Payments",
      icon: <WalletIcon />,
      subsets: [
        { name: "Estimates", toLink: "/estimates" },
        { name: "Create Invoice", toLink: "/create-invoices" },
        { name: "Invoice List", toLink: "/invoice-list" },
        { name: "Completed Invoice List", toLink: "/completed-invoice-list" },
        { name: "Customer Statements", toLink: "/customer-statements" },
        { name: "Customer Statement Detail Page", toLink: "/customer-statements/detail" },
        // { name: "Customers", toLink: "/customers" },
      ],
    },
    {
      name: "Purchases",
      icon: <ShoppingCartIcon />,
      subsets: [
        { name: "Create Purchase Bill", toLink: "/create-bill" },
        { name: "Purchase Bill Listing", toLink: "/bills-list" },
        { name: "Vendors", toLink: "/vendors" },
        { name: "Products & Services", toLink: "/products-&-services" },
      ],
    },
    {
      name: "Delivery",
      icon: <DeliveryDiningIcon />,
      subsets: [
          { name: "Delivery Notes", toLink: "/delivery-notes-list" },
          { name: "Customers", toLink: "/customers" },
      ],
    },
    {
      name: "Others",
      icon: <AltRouteIcon />,
      subsets: [
        { name: "Currency", toLink: "/currency" },
        { name: "Taxes", toLink: "/taxes" },
      ],
    },

    {
      name: "Users",
      icon: <SupervisedUserCircleIcon />,
      subsets: [
        { name: "Add User", toLink: "/add-user" },
        { name: "All Users", toLink: "/user-list" },
      ],
    },
  ];

  const SeniorAuditorlinks = [
    {
      name: "Dash Board",
      icon: <DashboardIcon />,
      to: "/",
    },

    {
      name: "Purchases",
      icon: <ShoppingCartIcon />,
      subsets: [
        { name: "Create Purchase Bill", toLink: "/create-bill" },
        { name: "Purchase Bill Listing", toLink: "/bills-list" },
        { name: "Vendors", toLink: "/vendors" },
        { name: "Products & Services", toLink: "/products-&-services" },
      ],
    },
    {
      name: "Delivery",
      icon: <DeliveryDiningIcon />,
      subsets: [
          { name: "Delivery Notes", toLink: "/delivery-notes-list" },
          { name: "Customers", toLink: "/customers" },
      ],
    },
    {
      name: "Others",
      icon: <AltRouteIcon />,
      subsets: [
        { name: "Currency", toLink: "/currency" },
        { name: "Taxes", toLink: "/taxes" },
      ],
    },


  ];

  const Sectarylinks = [
    {
      name: "Dash Board",
      icon: <DashboardIcon />,
      to: "/",
    },

    {
      name: "Delivery",
      icon: <DeliveryDiningIcon />,
      subsets: [
          { name: "Delivery Notes", toLink: "/delivery-notes-list" },
          // { name: "Customers", toLink: "/customers" },
      ],
    },
    // {
    //   name: "Others",
    //   icon: <AltRouteIcon />,
    //   subsets: [
    //     { name: "Currency", toLink: "/currency" },
    //     { name: "Taxes", toLink: "/taxes" },
    //   ],
    // },

  ];

  let links = []

  switch (role.role_name) {
    case 'Admin':
      links = Adminlinks;
      break;
    case 'Senior Auditor':
      links = SeniorAuditorlinks;
      break;
    case 'Sectary':
      links = Sectarylinks;
      break;
    default:
      links = [];
  }

  const list = (
    <Box sx={{ width: 250 }} >
      <List sx={{ paddingTop: "50px" }}>
        {links.map((text, index) => (
          <React.Fragment key={text.name}>
            {text.subsets ? (
              <Accordion
                sx={{ padding: "0px", margin: 0, boxShadow: 0, border: "none" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${text.name}-content`}
                  id={`${text.name}-header`}
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <Link
                    to={text.to ? text.to : "#"}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      primary={text.name}
                      style={{ color: "black" }}
                    />
                  </Link>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: "0" }}>
                  {text.subsets.map((subset, index) => (
                    <List key={subset.name}>
                      <Link
                        to={subset.toLink}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <ListItem sx={{ padding: 0, color: "black" }}>
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={subset.name} />
                        </ListItem>
                      </Link>
                    </List>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <List>
                <Link
                  to={text.to ? text.to : ""}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem>
                    <ListItemIcon>{text.icon}</ListItemIcon>
                    <ListItemText primary={text.name} />
                  </ListItem>
                </Link>
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "##0000ff7d" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textDecoration: "none" }}
            >
              <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
                Finance
              </Link>
            </Typography>
            <Box>
              <Button
                color="inherit"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {name}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={logOutUser}>Logout</MenuItem>
                <MenuItem >{role.role_name}</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <div>
        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          {list}
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
