
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Root from './Pages/Root';
import HomePage from './Pages/HomePage';
import BillPage from './Pages/Purchase_Bills/Billpage.js';
import ReportsPage from './Pages/ReportsPage';
import CustomersPage from './Pages/customers/CustomersPage';
import AddCustomer from './Pages/customers/AddCustomer';
import CustomerStatementPage from './Pages/Invoice/CustomerStatementPage';
import EstimatesPage from './Pages/EstimatesPage.js';
import AddBillPage from './Pages/Purchase_Bills/AddBillPage.js';
import AddDeliveryNote from "./Pages/Delivery_notes/AddDeliveryNote";
import Vendors from "./Pages/vendor/Vendors";
import AddVendor from "./Pages/vendor/AddVendor";
import ProductsServicesPage from "./Pages/productservices/ProductsServicesPage";
import AddProductService from "./Pages/productservices/AddProductService";
import InvoicesPage from "./Pages/Invoice/InvoicesPage";
import CurrencyPage from "./Pages/currency/CurrencyPage";
import CreateCurrency from "./Pages/currency/CreateCurrency";
import CreateInvoice from "./Pages/Invoice/CreateInvoice";
import TaxPage from "./Pages/tax/TaxPage";
import CreateTaxPage from "./Pages/tax/CreateTaxPage";
import FormTestPage from './Pages/1FormTestPage.js';
import LoginPage from './Pages/LoginPage.js';
import {ToastContainer} from "react-toastify";
import React from "react";
import DeliveryNoteListPage from "./Pages/Delivery_notes/DeliveryNoteListPage";
import BillListPage from "./Pages/Purchase_Bills/BillListPage";
import InvoiceListPage from "./Pages/Invoice/InvoiceListPage";
import CompletedInvoicesList from "./Pages/Invoice/CompletedInvoicesList";
import CreateInvoiceFormPage from "./Pages/Invoice/CreateInvoiceFormPage";
import CustomerStatementDetailPage from "./Pages/Invoice/CustomerStatementDetailPage";
import AddUserPage from "./Pages/user/AddUserPage";
import UserListPage from "./Pages/user/UserListPage";
import DeliveryNoteDetailPage from "./Pages/Delivery_notes/DeliveryNoteDetailPage";
import PurchaseBillDetailPage from "./Pages/Purchase_Bills/PurchaseBillDetailPage";
import InvoiceListDetailPage from "./Pages/Invoice/InvoiceListDetailPage";

function App() {

  
  const isLoggedin = useSelector((state) => state.USER_SLICE.isLoggedin);


  const router = createBrowserRouter([
    {
      path:'/',
      element:isLoggedin ? <Root/> : <LoginPage/> ,
      
      children:[
        {
          index:true,
          element:<HomePage/>
        },
        {
          path:'/report',
          element:<ReportsPage/>
        },
        {
          path:'/customers',
          element:<CustomersPage/>
        },
        {
          path:'/addCustomer',
          element:<AddCustomer/>
        },
        {
          path:'/customer-statements',
          element:<CustomerStatementPage/>
        },
        {
          path:'/customer-statements/detail',
          element:<CustomerStatementDetailPage/>
        },
        {
          path:'/estimates',
          element:<EstimatesPage/>
        },
        {
          path:'/create-bill',
          element:<BillPage/>
        },

        {
          path:'/bills-list',
          element:<BillListPage/>
        },

        {
          path:'/bills/edit/:id',
          element:<AddBillPage/>
        },

        {
          path:'/bills/view/:id',
          element:<PurchaseBillDetailPage/>
        },
        {
          path:'/invoice/edit/:id',
          element:<CreateInvoiceFormPage/>
        },
        {
          path:'/add-bills',
          element:<AddBillPage/>
        },
        {
          path:'/create-invoices',
          element:<InvoicesPage/>
        },
        {
          path:'/invoice-list',
          element:<InvoiceListPage/>
        },

        {
          path:'/invoice-list/:id',
          element:<InvoiceListDetailPage/>
        },
        {
          path:'/completed-invoice-list',
          element:<CompletedInvoicesList/>
        },

        {
          path:'/vendors',
          element:<Vendors/>
        },
        {
          path:'/delivery-notes-list',
          element:<DeliveryNoteListPage/>
        },
        {
          path:'/delivery-note/:id',
          element:<DeliveryNoteDetailPage/>
        },
        {
          path:'/add-delivery-note',
          element:<AddDeliveryNote/>
        },

        {
          path:'/add-vendor',
          element:<AddVendor/>
        },
        {
          path:'/add-user',
          element:<AddUserPage/>
        },
        {
          path:'/user-list',
          element:<UserListPage/>
        },


        {
          path:'/products-&-services',
          element:<ProductsServicesPage/>
        },

        {
          path:'/add-product-service',
          element:<AddProductService/>
        },

        {
          path:'/currency',
          element:<CurrencyPage/>
        },

        {
          path:'/create-currency',
          element:<CreateCurrency/>
        },
        {
          path:'/create-invoice',
          element:<CreateInvoice/>
        },
        {
          path:'/taxes/*',
          // element:<TaxPage/>,
          element:<TaxPage/>
        },
        {
          path:'/create-tax/:id',
          element:<CreateTaxPage/>
        },
        {
          path:'/create-tax',
          element:<CreateTaxPage/>
        },
        
        {
          path:'/test',
          element:<FormTestPage/>
        },
       
      ]

      },
      {
        path:'/login',
        element: <LoginPage/>
      },
      
  ])
  return (
    <>
      <ToastContainer/>
    <RouterProvider router={router}/>
    </>
  );
}

export default App;
