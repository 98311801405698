import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  setBillNote,
  setBillNoteItems,
  setBillNoteLoading,
} from "../../redux-store/Slices/billNoteSlice";
import dayjs from "dayjs";
import { setCurrencies } from "../../redux-store/Slices/currencySlice";
import { toast } from "react-toastify";

const InvoiceListDetailPage = () => {
  // const {loading} = useSelector((state)=> state.BILL_NOTE_SLICE)

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const { currencies } = useSelector((state) => state.CURRENCY_SLICE);
  const { billNote, loading, billItems } = useSelector(
    (state) => state.BILL_NOTE_SLICE
  );
  const [customers, setCustomers] = useState([]);
  const [vendors, seVendors] = useState([]);
  // const {taxes} = useSelector((state) => state.TAX_SLICE);
  const [taxes, setTaxes] = useState([]);
  const [stateTax, setStateTax] = useState([""]);
  const [stateTaxValue, setStateTaxValue] = useState([""]);
  const [showP, setShowP] = useState([false]);
  const params = useParams();
  const dispatch = useDispatch();
  const deliveryNoteId = params.id;

  const getAndSetVendors = async () => {
    try {
      // dispatch(setVendors({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        // dispatch(setVendors({ vendors : axiosRes.data.data, isLoading:false}));
        seVendors(axiosRes.data.data);
      }
    } catch (err) {
      // toast.warning(err.response.data.message)
      console.log(err);
    }
  };

  const fetchCustomers = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const vendorArray = data.data;
      setCustomers(vendorArray);
      console.log(customers);
    } catch (err) {
      toast.info(err.response.data.message);
    }
  };

  const getAndSetTaxes = async () => {
    try {
      // dispatch(setTaxes({ isLoading: true }));

      const axiosRes = await axios.get(`${BASE_URL}/api/tax`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        // dispatch(setTaxes({ taxes: axiosRes.data.data}));
        setTaxes(axiosRes.data.data);
        console.log(axiosRes.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.warning(err.response.data.message);
    }
  };

  useEffect(() => {
    getAndSetDeliveryNote();
    getAndSetTaxes();
    getAndSetCurrencies();
    fetchCustomers();
    getAndSetVendors();
  }, []);

  useEffect(() => {
    setFormValues();
  }, [billNote]);

  const navigate = useNavigate();

  const getAndSetDeliveryNote = async () => {
    dispatch(setBillNoteLoading({ loading: true }));

    try {
      const axiosRes = await axios.get(
        `${BASE_URL}/api/get_list/delivery_note/${deliveryNoteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;
      console.log(axiosRes.data);
      if (success === true) {
        dispatch(setBillNote(axiosRes.data.data[0]));
        dispatch(setBillNoteItems(axiosRes.data.data[0].items));
      }

      dispatch(setBillNoteLoading({ loading: false }));
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching delivery note:", error);
      dispatch(setBillNoteLoading({ loading: false }));
    }
  };

  const setFormValues = () => {
    console.log("bil note os", billNote);
    formik.setValues({
      ...formik.values,
      vendor: billNote.billorder?.vendor?.id,
      dueDate: dayjs(billNote.due_date),
      billDate: dayjs(billNote.billorder?.bill_date),
      invoice_note_id: billNote.invoice?.id,
      item_tax_ids: billNote?.items?.map((item) =>
        item.tax.split(",").map((tax) => Number(tax))
      ),
      product_id: billItems.map((item) => item.product_id),

      fileNo: billNote.file_no,
      invoice: billNote?.invoice,
      soPo: billNote.smo_no,
      items: billItems,
      price: billItems.map((item) => item.price),
      customer: billNote?.customer?.id,
      boxNumber: billNote.billorder?.box,
      note: billNote.billorder?.note,
      currency: billNote.billorder?.currency_item?.id,
      InDate: dayjs(billNote.invoice?.invoice_date),
    });

    console.log("values setted");
  };

  const getAndSetCurrencies = async () => {
    dispatch(setCurrencies({ isLoading: true }));

    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/currency`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        await dispatch(
          setCurrencies({ currencies: axiosRes.data.data, isLoading: false })
        );
        console.log(currencies);
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
      dispatch(setCurrencies({ isLoading: false })); // Handle error state if needed
    }
  };

  const UpdateInvoice = async (values) => {
    const selling_price_in_number = values.items.map(
      (item) => item.selling_price_in_number * 1
    );
    const selling_percentage = values.items.map(
      (item) => item.selling_percentage * 1
    );
    const { InDate, invoice_note_id, product_id, price, invoice, items } =
      values;

    console.log("values are", values);

    const INVOICE_NOTE = {
      invoice_date: dayjs(InDate).format("YYYY-MM-DD"),
      invoice_id: invoice_note_id,
      delivery_note_id: billNote.id,
      item_ids: product_id,
      product_id,
      currency_id: invoice.currency_id,
      price,
      selling_price_in_number,
      selling_percentage,
      expense_category: items.map((item) => item.invoice_expense_category),

      // selling_price_in_number: sellPriceArray,
      // selling_percentage: sellPercentArray
    };

    console.log("invoice note is ", INVOICE_NOTE);
    const axiosRes = await axios.post(
      `${BASE_URL}/api/update/invoice_note`,
      INVOICE_NOTE,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { success } = axiosRes.data;
    if (success === true) {
      console.log(axiosRes.data);
      // navigate('/invoice-list')
      toast.success("Updated Invoice Successfully");
    }
  };

  const formik = useFormik({
    initialValues: {
      vendor: billNote.vendor_id,
      // vendor: billNote?.invoice?.id,
      currency: billNote.billorder?.currency_item.currency_name,
      dueDate: billNote.due_date,
      fileNo: billNote.file_no,
      billDate: billNote.billorder?.bill_date,
      soPo: billNote.smo_no,
      boxNumber: billNote.billorder?.box,
      note: billNote.billorder?.note,
      invoice: billNote.invoice,
      items: billItems,
    },
    onSubmit: (values) => {
      //   console.log(values);
      UpdateInvoice(values);
    },
  });

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <Box padding={"2rem"}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <Box mb={"2rem"}>
                  <h1>Invoice Detail</h1>
                </Box>

                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent={"center"}
                >
                  <Grid item xs={"auto"}>
                    {/*    Box 1*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-evenly"}
                          mb={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Vendor
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="vendors"
                                options={vendors}
                                sx={{ width: 170 }}
                                disabled={true}
                                value={
                                  vendors.find(
                                    (vendor) =>
                                      vendor.id === formik.values.vendor
                                  ) || null
                                }
                                // defaultValue={formik.values.customer_id}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                autoComplete={false}
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("vendor", newValue.id);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="vendors" />
                                )}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency (Purchase)
                          </Typography>
                          <Box minWidth={"170px"}>
                            <Autocomplete
                              id="customer"
                              options={currencies}
                              sx={{ width: 170 }}
                              disabled={true}
                              value={
                                currencies?.find(
                                  (currency) =>
                                    currency.id === formik.values.currency
                                ) || null
                              }
                              // defaultValue={formik.values.customer_id}
                              getOptionLabel={(option) =>
                                option.abbreviation || ""
                              }
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              autoComplete={false}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("currency", newValue.id);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Currency" />
                              )}
                            />
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency (Invoice)
                          </Typography>
                          <Box minWidth={"170px"}>
                            <Autocomplete
                              id="customer"
                              options={currencies}
                              sx={{ width: 170 }}
                              value={
                                currencies?.find(
                                  (currency) =>
                                    currency?.id ===
                                    formik?.values?.invoice?.currency_id
                                ) || null
                              }
                              // defaultValue={formik.values.customer_id}
                              getOptionLabel={(option) =>
                                option.abbreviation || ""
                              }
                              getOptionSelected={(option, value) =>
                                option?.id === value?.id
                              }
                              autoComplete={false}
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "invoice.currency_id",
                                  newValue?.id ?? ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Currency" />
                              )}
                            />
                          </Box>
                        </Box>

                        <Box display={"flex"} marginTop={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            File NO#
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                disabled={true}
                                {...formik.getFieldProps("fileNo")}
                                // disabled={true}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display={"flex"} mt={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            In. Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="bill-date"
                              defaultValue={dayjs(formik.values.InDate).format(
                                `YYYY-DD-MM`
                              )}
                              disabled={true}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 2*/}
                    <Box
                      display={"flex"}
                      sx={{ justifyContent: { xs: "start", md: "center" } }}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            // paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Customer
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="customer"
                                options={customers}
                                disabled={true}
                                sx={{ width: 170 }}
                                value={
                                  customers.find(
                                    (customer) =>
                                      customer.id === formik.values.customer
                                  ) || null
                                }
                                // defaultValue={formik.values.customer_id}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, newValue) => {
                                  formik.setFieldValue("customer", newValue.id);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Customers" />
                                )}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Due Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="due-date"
                              defaultValue={dayjs(formik.values.dueDate).format(
                                `YYYY-DD-MM`
                              )}
                              disabled={true}
                            />
                          </Box>
                        </Box>

                        <Box display={"flex"} mt={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Bill Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="bill-date"
                              defaultValue={dayjs(
                                formik.values.billDate
                              ).format(`YYYY-DD-MM`)}
                              disabled={true}
                            />
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingTop={"15px"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"12px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            SMO NO#
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}

                              <TextField
                                disabled
                                id="sopo-no"
                                label="SO/PO"
                                variant="outlined"
                                {...formik.getFieldProps("soPo")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 3*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Box #
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                disabled={true}
                                id="box-number"
                                label=""
                                variant="outlined"
                                {...formik.getFieldProps("boxNumber")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingLeft={"10px"}
                          paddingTop={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Note
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="bill-note"
                                disabled={true}
                                label=""
                                variant="outlined"
                                multiline
                                rows={"5"}
                                {...formik.getFieldProps("note")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box></Box>
              </Box>
            </Box>

            <Box
              mt={"8rem"}
              display={"flex"}
              justifyContent={"center"}
              marginBottom={"10rem"}
            >
              <Box overflow={"scroll"}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Expense Category(purchase)</td>
                      <td>Expense Category(invoice)</td>
                      <td>Description</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                      <td>Old Purchase Price</td>
                      <td>Old Sell Price</td>
                      {<td>Current Selling Price</td>}
                      {<td>Selling %</td>}
                      <td>Tax</td>
                      {/*<td>Amount After Applying sell %</td>*/}
                    </tr>
                  </thead>

                  <tbody>
                    {formik.values.items &&
                      formik.values.items.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            <TextField
                              id={`expense-category-${row.id}`}
                              disabled={true}
                              sx={{ width: 200 }}
                              type="text"
                              value={row.expense_category || null}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.expense_category`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `expense_category.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`expense-category-invoice-${row.id}`}
                              sx={{ width: 200 }}
                              type="text"
                              value={row.invoice_expense_category || null}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.invoice_expense_category`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `invoice_expense_category.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-description-${row.id}`}
                              minRows={2}
                              sx={{ width: 230 }}
                              multiline
                              value={row.description}
                              disabled
                              maxRows={10}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-${row.id}`}
                              value={row.qty}
                              disabled
                              sx={{ width: 80 }}
                              type="number"
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-type-${row.id}`}
                              value={row.qty_type}
                              disabled
                              sx={{ width: 80 }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`old-purchase-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          <td>
                            <TextField
                              id={`old-selling-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          {
                            <td>
                              <TextField
                                id={`current-selling-price-${row.id}`}
                                sx={{ width: 80 }}
                                type="number"
                                value={row.selling_price_in_number}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `items.${index}.selling_price_in_number`,
                                    e.target.value
                                  );

                                  // formik.setFieldValue(`selling_price_in_number.${index}`, e.target.value)
                                }}
                                // disabled={true}
                                /*{...formik.getFieldProps(`current_sellprice.${index}`)}*/
                              />
                            </td>
                          }

                          {
                            <td>
                              <TextField
                                id={`current-selling-percent-${row.id}`}
                                sx={{ width: 80 }}
                                type="number"
                                label={"Add %"}
                                value={row.selling_percentage}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `items.${index}.selling_percentage`,
                                    e.target.value
                                  );

                                  // formik.setFieldValue(`selling_price_in_number.${index}`, e.target.value)
                                }}
                                // disabled={true}
                                /*{...formik.getFieldProps(`current_sellpercent.${index}`)}*/
                              />
                            </td>
                          }

                          <td>
                            <Autocomplete
                              id="taxes"
                              multiple
                              options={taxes}
                              disabled={true}
                              sx={{ width: 170 }}
                              value={
                                formik.values.item_tax_ids
                                  ? formik.values.item_tax_ids[index]
                                      .map((id) =>
                                        taxes.find((tax) => tax.id === id)
                                      )
                                      .filter(Boolean)
                                  : []
                              }
                              getOptionLabel={(option) =>
                                `${option.name} (${option.tax_rate}%)` || ""
                              }
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              autoComplete={false}
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  `item_tax_ids[${index}]`,
                                  newValue.map((option) => option.id)
                                );

                                // formik.setFieldValue(`item_tax_ids`, newValue.map(option => option.id));
                                console.log(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="taxes" />
                              )}
                            />
                          </td>

                          {/*<td style={{marginLeft: '10px'}}>*/}

                          {/*    <p>$ {row.Totalprice ? row.Totalprice : '0.00'}</p>*/}

                          {/*</td>*/}
                        </tr>
                      ))}
                  </tbody>
                </table>

                <Box mr={"1rem"}>
                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>SubTotal:</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*    <p>Total Amount: $</p>*/}
                  {/*    <p>{billNote.totalAmount ? billNote.totalAmount : 0.00}</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>Total (EUR at 0.0002351439)</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
              <Button type={"submit"}>Submit</Button>
              <Button
                onClick={() => {
                  navigate("/invoice-list");
                }}
              >
                Go Back
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};
export default InvoiceListDetailPage;
