import * as React from 'react';
import {useSelector} from 'react-redux';
import Modal from '@mui/material/Modal';
import {Box, Button,} from "@mui/material";
import axios from 'axios';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius: '27px'
};


const ConformDeleteDeliveryNote = (props) => {
    const {text, noteID, btn, btnText, runAgain,status} = props
    const navigate = useNavigate()

    const [open, setOpen] = React.useState(false);

    const {token} = useSelector((state) => state.USER_SLICE)
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const deleteDeliveryNote = async (noteID) => {
        console.log(noteID)
        try {
            const axiosRes = await axios.post(`${BASE_URL}/api/delete/delivery_note`, {delivery_note_id: noteID}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            const {success} = axiosRes.data
            if (success) {
                console.log(axiosRes.data)
                toast.success(`Deleted Delivery Note ${noteID} Successfully`)
                handleClose()
            }

        } catch (err) {
            console.log(err)
            toast.warning(err.response.data.message)
            handleClose()
        }
    }

    const disableOrNot = status === 'Approved'


    return (
        <div>
            <Button disabled={disableOrNot} onClick={handleOpen}>{btn}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>

                        <Box>
                            <Box mb={"2rem"} display={"flex"} justifyContent={"center"}>
                                <h3>{text}</h3>
                            </Box>


                        </Box>
                        <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                            <Button
                                onClick={() => {
                                    deleteDeliveryNote(noteID)
                                }} color={"warning"}

                            >
                                {btnText ? btnText : 'Delete'}
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ConformDeleteDeliveryNote