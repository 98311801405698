import React, {  useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddBillForm from "../../Components/AddBillForm";
import AddInvoiceForm from "./AddInvoiceForm";

const CreateInvoive = () => {
    const [rows, setRows] = useState([]);

    const [vendor, setVendor] = useState("");
    const [currency, setCurrency] = useState("");
    const [dueDate, setDueDate] = useState(null);
    const [billDate, setBillDate] = useState(null);
    const [soPo, setSoPo] = useState("");
    const [boxNumber, setBoxNumber] = useState("");
    const [note, setNote] = useState("");

    const handleVendorChange = (event, newValue) => {
        setVendor(newValue);
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    const handleDueDateChange = (newDate) => {
        setDueDate(newDate);
    };

    const handleBillDateChange = (newDate) => {
        setBillDate(newDate);
    };

    const handleSoPoChange = (event) => {
        setSoPo(event.target.value);
    };

    const handleBoxNumberChange = (event) => {
        setBoxNumber(event.target.value);
    };

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSubmit1 = (event) => {
        event.preventDefault();

        // Log the form values
        console.log("Vendor:", vendor);
        console.log("Currency:", currency);
        console.log("Due Date:", dueDate);
        console.log("Bill Date:", billDate);
        console.log("P.O./S.O.:", soPo);
        console.log("Box #:", boxNumber);
        console.log("Note:", note);
    };

    const vendors = [
        "vendor 1",
        "vendor 2",
        "vendor 3",
        "vendor 4",
        "vendor 5",
        "vendor 6",
        "vendor 7",
        "vendor 8",
        "vendor 9",
        "vendor 10",
        "vendor 11",
        "vendor 12",
        "vendor 13",
        "vendor 14",
        "vendor 15",
        "vendor 16",
        "vendor 17",
        "vendor 18",
        "vendor 19",
        "vendor 20",
        "vendor 21",
        "vendor 22",
    ];

    return (
        <>
            <Box padding={"2rem"}>
                <Box>
                    <form onSubmit={handleSubmit1}>
                        <Box>
                            <Box mb={"2rem"}>
                                <h1>Create a new Sale Bill (Invoice) </h1>
                            </Box>

                            <Grid
                                container
                                spacing={1}
                                display="flex"
                                justifyContent={"center"}
                            >
                                <Grid item xs={"auto"}>
                                    {/*    Box 1*/}
                                    <Box
                                        display={"flex"}
                                        justifyContent={"start"}
                                        flexDirection={"row"}
                                    >
                                        <Box>
                                            <Box display={"flex"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"16px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Vendor
                                                </Typography>
                                                <Box minWidth={"170px"}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id="vendor-auto-complete"
                                                            options={["", ...vendors]}
                                                            value={vendor || null}
                                                            onChange={handleVendorChange}
                                                            sx={{ width: 170 }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Vendors" />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>

                                            <Box display={"flex"} marginTop={"15px"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Currency
                                                </Typography>
                                                <Box minWidth={"170px"}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>Currency</InputLabel>
                                                        <Select
                                                            id={"currency-select"}
                                                            value={currency || ""}
                                                            onChange={handleCurrencyChange}
                                                            label="Currency"
                                                        >
                                                            <MenuItem value="Euro 1">Euro 1</MenuItem>
                                                            <MenuItem value="USD 2">USD 2</MenuItem>
                                                            <MenuItem value="Pound 3">Pound 3</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>

                                            <Box display={"flex"} marginTop={"15px"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    File No#
                                                </Typography>
                                                <Box minWidth={"170px"}>
                                                    <FormControl fullWidth>
                                                      <TextField value={566} disabled={true}/>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={"auto"}>
                                    {/*    Box 2*/}
                                    <Box
                                        display={"flex"}
                                        sx={{ justifyContent: { xs: "start", md: "center" } }}
                                        flexDirection={"row"}
                                    >
                                        <Box>
                                            <Box display={"flex"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"16px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Due Date
                                                </Typography>
                                                <Box minWidth={"130px"}>
                                                    <FormControl fullWidth>
                                                        {/*<InputLabel>Vendor</InputLabel>*/}
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={["DatePicker"]}>
                                                                <DatePicker
                                                                    id={"due-date"}
                                                                    value={billDate}
                                                                    onChange={handleBillDateChange}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Box>
                                            </Box>

                                            <Box display={"flex"} paddingLeft={"7px"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"16px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Bill Date
                                                </Typography>
                                                <Box minWidth={"130px"}>
                                                    <FormControl fullWidth>
                                                        {/*<InputLabel>Vendor</InputLabel>*/}
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={["DatePicker"]}>
                                                                <DatePicker
                                                                    id={"bill-date"}
                                                                    value={dueDate}
                                                                    onChange={handleDueDateChange}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Box>
                                            </Box>

                                            <Box
                                                display={"flex"}
                                                paddingTop={"15px"}
                                                paddingLeft={"10px"}
                                            >
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"12px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    P.O./S.O.
                                                </Typography>
                                                <Box minWidth={"130px"}>
                                                    <FormControl fullWidth>
                                                        {/*<InputLabel>Vendor</InputLabel>*/}
                                                        <TextField
                                                            value={soPo}
                                                            onChange={handleSoPoChange}
                                                            id="sopo-no"
                                                            label="SO/PO"
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={"auto"}>
                                    {/*    Box 3*/}
                                    <Box
                                        display={"flex"}
                                        justifyContent={"start"}
                                        flexDirection={"row"}
                                    >
                                        <Box>
                                            <Box display={"flex"}>
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"16px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Box #
                                                </Typography>
                                                <Box minWidth={"130px"}>
                                                    <FormControl fullWidth>
                                                        {/*<InputLabel>Vendor</InputLabel>*/}
                                                        <TextField
                                                            value={boxNumber}
                                                            onChange={handleBoxNumberChange}
                                                            id="box-number"
                                                            label=""
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>

                                            <Box
                                                display={"flex"}
                                                paddingLeft={"10px"}
                                                paddingTop={"10px"}
                                            >
                                                <Typography
                                                    variant={"h6"}
                                                    paddingLeft={"16px"}
                                                    paddingTop={"11px"}
                                                    paddingRight={"13px"}
                                                >
                                                    Note
                                                </Typography>
                                                <Box minWidth={"130px"}>
                                                    <FormControl fullWidth>
                                                        {/*<InputLabel>Vendor</InputLabel>*/}
                                                        <TextField
                                                            value={note}
                                                            onChange={handleNoteChange}
                                                            id="bill-note"
                                                            label=""
                                                            variant="outlined"
                                                            multiline
                                                            rows={"5"}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box></Box>
                        </Box>

                    </form>
                </Box>

                <Box mt={"8rem"} display={"flex"} justifyContent={"center"} marginBottom={'10rem'}>
                    <AddInvoiceForm />
                </Box>
                {/* <Button type={"submit"}>Submit</Button> */}
            </Box>
        </>
    );
};
export default CreateInvoive;
