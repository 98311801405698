import * as React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import axios from 'axios';
import {useEffect} from "react";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000000a6',
    boxShadow: 24,
    p: 4,
    borderRadius:'27px'
};



const ConformDeleteModal = (props) => {
    const {text,method,taxID,btn,btnText,disableOrNot} = props

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <Button disabled={disableOrNot} onClick={handleOpen}>{btn}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>

                            <Box>
                                <Box mb={"2rem"} display={"flex"} justifyContent={"center"}>
                                    <h3>{text}</h3>
                                </Box>


                            </Box>
                            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                                <Button
                                    onClick={()=>{method(taxID)}} color={"warning"}

                                >
                                    {btnText?btnText: 'Delete'}
                                </Button>
                            </Box>

                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ConformDeleteModal