import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ReportCard = () => {
  return (
    <>
      <Box py={2}>
        <Box
          border={"1px solid #00000045"}
          borderRadius={"40px"}
          padding={"2rem"}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">Customers</Typography>
              <Typography mt={2}>
                Stay on top of overdue invoices and identify high-value or
                late-paying customers
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box borderBottom={"1px solid #00000045"}>
                  <Typography
                    variant="h6"
                    fontSize={"16px"}
                    textTransform={"capitalize"}
                    color={"blue"}
                  >
                    Income by customers
                  </Typography>
                  <Typography mb={1}>
                    A breakdown of paid and unpaid customers
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <ArrowForwardIosIcon />
                </Box>
              </Box>

              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Box>
                  <Typography
                    variant="h6"
                    fontSize={"16px"}
                    textTransform={"capitalize"}
                    color={"blue"}
                  >
                    Income by customers
                  </Typography>
                  <Typography>
                    A breakdown of paid and unpaid customers
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <ArrowForwardIosIcon />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReportCard;
