import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GridData from "../../Components/GridData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setVendors,deleteSliceVendor,updateSliceVendor } from "../../redux-store/Slices/vendorSlice";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import UpdateVendorModal from "../../Components/UpdateVendorModal";
import {toast} from "react-toastify";
import ConformDeleteModal from "../../Components/ConformDeleteModal";

const Vendors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const { vendors } = useSelector((state) => state.VENDOR_SLICE);


  useEffect(() => {
    getAndSetVendors();
  }, []);


  const deleteVendor =async (id) =>{
    const axiosRes = await axios.delete(`${BASE_URL}/api/vendor/${id}`,{
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    })
        const {success} = axiosRes.data

    if(success){
      dispatch(deleteSliceVendor(id))
      toast.info('Deleted Vendor Successfully')
    }
  }


  const updateVendor =async (values) =>{
    const axiosRes = await axios.patch(`${BASE_URL}/api/vendor/${values.id}`,values,{
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    })

    const {success} = axiosRes.data
    console.log(values)
    if(success){
      dispatch(updateSliceVendor({updatedVendor:values}))
      toast.success('Updated Vendor Successfully')
    }

  }

  const getAndSetVendors = async () => {
    try{
      const { data } = await axios.get(`${BASE_URL}/api/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const fetchedVendors = data.data;

      dispatch(setVendors({ vendors:fetchedVendors, isLoading: false }));
    }catch (err){
      console.log(err)
      toast.warning(err.response.data.message)

    }

  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Name", width: 120 },
    { field: "email", headerName: "Email", width: 180 },
    {
      field: "phone",
      headerName: "Phone",
      width: 140,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <ButtonGroup>
          <UpdateVendorModal
              btn={<EditNoteOutlinedIcon/>}
              text={'Update Vendor'}
              vendor1={params.row}
              vendor_id={params.row.id}
              method={updateVendor}
          />
          {/*<Button onClick={() => console.log(params.row.id)}>*/}
          {/*  <EditNoteOutlinedIcon/>*/}
          {/*</Button>*/}
          <ConformDeleteModal
          btn={<DeleteForeverOutlinedIcon sx={{color:'red'}}/>}
          taxID={params.row.id}
          text={`Delete Vendor "${params.row.name}" ?`}
          method={deleteVendor}
          />
          {/*<Button  onClick={() => deleteVendor(params.row.id)}>*/}
          {/*  <DeleteForeverOutlinedIcon sx={{color:'red'}}/>*/}
          {/*</Button>*/}
        </ButtonGroup>
      ),
    },
    { field: "addition_info", headerName: "Addition Info", width: 300 },
  ];


  const navigateToAddVendor = () => {
    navigate("/add-vendor");
  };

  return (
    <>
      <Box mt={5}>
        <Grid container>
          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={10}>
            <Box display={"flex"} pr={'40px'} justifyContent={"space-between"}>
              <Box>
                <Typography variant={'h3'}>Vendors</Typography>
                {/*<TextField id="find-customer" placeholder="Search Vendor" />*/}
                {/*<Typography>3 Vendors found</Typography>*/}
              </Box>

              <Box>
                {/*<Button*/}
                {/*  sx={{*/}
                {/*    marginLeft: "10px",*/}
                {/*    marginRight: "10px",*/}
                {/*    borderRadius: "30px",*/}
                {/*  }}*/}
                {/*  variant="contained"*/}
                {/*>*/}
                {/*  Import from CVS*/}
                {/*</Button>*/}
                <Button
                  onClick={navigateToAddVendor}
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    borderRadius: "30px",
                  }}
                >
                  Add a Vendor
                </Button>
              </Box>
            </Box>
            <Box mt={4}>
              <GridData columnsP={columns} rowsP={vendors} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Vendors;
