import React from 'react'
import Header from '../layout/Header';
import { Outlet } from 'react-router-dom';
import {ToastContainer, Zoom} from "react-toastify";


const Root = () => {
  


  return (
    <>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Zoom}
        />

        <Header/>
        <Outlet/>
    </>
  )
  }

export default Root