import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  setBillNote,
  setBillNoteItemPrice,
  setBillNoteItems,
  setBillNoteLoading,
  setTaxIfNoTax,
  setTotalBillAmount,
} from "../../redux-store/Slices/billNoteSlice";
import dayjs from "dayjs";
import { setTaxes, setTaxesLoading } from "../../redux-store/Slices/taxSlice";
import { setCurrencies } from "../../redux-store/Slices/currencySlice";
import { toast } from "react-toastify";

const CreateInvoiceFormPage = () => {
  // const {loading} = useSelector((state)=> state.BILL_NOTE_SLICE)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.USER_SLICE);
  const { currencies } = useSelector((state) => state.CURRENCY_SLICE);
  const { billNote, loading, billItems } = useSelector(
    (state) => state.BILL_NOTE_SLICE
  );
  const { taxes } = useSelector((state) => state.TAX_SLICE);
  const [stateTax, setStateTax] = useState([""]);
  const [stateTaxValue, setStateTaxValue] = useState([""]);
  const [showP, setShowP] = useState([false]);
  const params = useParams();
  const dispatch = useDispatch();
  const deliveryNoteId = params.id;

  useEffect(() => {
    getAndSetDeliveryNote();
    getAndSetTaxes();
    getAndSetCurrencies();
  }, []);

  useEffect(() => {
    setFormValues();
  }, [billNote]);

  const navigate = useNavigate();

  const getAndSetDeliveryNote = async () => {
    dispatch(setBillNoteLoading({ loading: true }));

    try {
      const axiosRes = await axios.get(
        `${BASE_URL}/api/get_list/delivery_note/${deliveryNoteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { success } = axiosRes.data;
      if (success === true) {
        dispatch(setBillNote(axiosRes.data.data[0]));
        dispatch(setBillNoteItems(axiosRes.data.data[0].items));
      }

      dispatch(setBillNoteLoading({ loading: false }));
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching delivery note:", error);
      dispatch(setBillNoteLoading({ loading: false }));
    }
  };

  const setFormValues = () => {
    console.log(billNote);
    formik.setValues({
      ...formik.values,
      vendor: billNote.billorder?.vendor?.name,
      dueDate: dayjs(billNote.due_date),
      delivery_note_id: billNote.id,
      product_id: billItems.map((item) => item.product_id),
      fileNo: billNote.file_no,
      soPo: billNote.smo_no,
      items: billItems,
      price: billItems.map((item) => item.price),
      boxNumber: billNote.billorder?.box,
      note: billNote.billorder?.note,
      currency: billNote.billorder?.currency_item.currency_name,
    });

    console.log("values setted");
  };

  const getAndSetTaxes = async () => {
    dispatch(setTaxesLoading({ isLoading: true }));

    const axiosRes = await axios.get(`${BASE_URL}/api/tax`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Adjust the content type as needed
      },
    });

    const { success } = axiosRes.data;
    if (success === true) {
      dispatch(setTaxes({ taxes: axiosRes.data.data, isLoading: false }));
    }

    for (let tax of taxes) {
      setStateTax((prevState) => [...prevState, tax.name]);
      setStateTaxValue((prevState) => [...prevState, tax.tax_rate]);
    }
  };

  const getAndSetCurrencies = async () => {
    dispatch(setCurrencies({ isLoading: true }));

    try {
      const axiosRes = await axios.get(`${BASE_URL}/api/currency`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { success } = axiosRes.data;
      if (success === true) {
        await dispatch(
          setCurrencies({ currencies: axiosRes.data.data, isLoading: false })
        );
        console.log(currencies);
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
      dispatch(setCurrencies({ isLoading: false })); // Handle error state if needed
    }
  };

  const createBillNote = async (values) => {
    const {
      billDate,
      delivery_note_id,
      product_id,
      price,
      currency_sale,
      current_sellprice,
      current_sellpercent,
      expense_category_sale,
    } = values;

    const sellPriceArray = [current_sellprice];
    const sellPercentArray = [current_sellpercent];

    const INVOICE_NOTE = {
      invoice_date: billDate,
      delivery_note_id,
      product_id,
      price,
      selling_price_in_number: sellPriceArray[0],
      currency_id: currency_sale,
      selling_percentage: sellPercentArray,
      expense_category: expense_category_sale,
    };

    console.log(INVOICE_NOTE);
    const axiosRes = await axios.post(
      `${BASE_URL}/api/store/invoice_note`,
      INVOICE_NOTE,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const { success } = axiosRes.data;
    if (success === true) {
      console.log(axiosRes.data);
      navigate("/invoice-list");
      toast.success("Created Invoice Successfully");
    }
  };

  const formik = useFormik({
    initialValues: {
      vendor: billNote.vendor_id,
      currency: billNote.billorder?.currency_item.currency_name,
      currency_sale: "",
      current_sellpercent: 0,
      current_sellprice: 0,
      dueDate: billNote.due_date,
      fileNo: billNote.file_no,
      billDate: billNote.billorder?.bill_date,
      soPo: billNote.smo_no,
      boxNumber: billNote.billorder?.box,
      note: billNote.billorder?.note,
      items: billItems,
      expense_category_sale: [],
      expense_category_purchase: [],
      price: [],
      tax: [],
    },
    onSubmit: (values) => {
      console.log(values);
      createBillNote(values);
    },
  });

  const handlePriceChange = (e, id, index, item) => {
    const price = e.target.value;

    dispatch(setBillNoteItemPrice({ itemId: id, price }));
    dispatch(setTaxIfNoTax({ itemId: id, tax: [0] }));
    dispatch(setTotalBillAmount());
    formik.setFieldValue(`price.${index}`, item.price);
    console.log(item.price);
  };

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <Box padding={"2rem"}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <Box mb={"2rem"}>
                  <h1>Create an Invoice</h1>
                </Box>

                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent={"center"}
                >
                  <Grid item xs={"auto"}>
                    {/*    Box 1*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Vendor
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                {...formik.getFieldProps("vendor")}
                                // value={billNote.vendor.name}
                                disabled={true}
                                InputLabelProps={{ style: { color: "red" } }}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display={"flex"} marginTop={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency (Purchase)
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                {...formik.getFieldProps("currency")}
                                disabled={true}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={"15px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Currency (Sale)
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <InputLabel>Currency (Sale)</InputLabel>
                              <Select
                                id={"currency-select"}
                                // required
                                {...formik.getFieldProps("currency_sale")}
                                label="Currency"
                              >
                                {currencies &&
                                  currencies.map((item) => (
                                    <MenuItem value={item.id}>
                                      {item.currency_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display={"flex"} marginTop={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            File NO#
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                {...formik.getFieldProps("fileNo")}
                                disabled={true}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box display={"flex"} paddingLeft={"7px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            In. Date
                          </Typography>
                          <Box minWidth={"200px"} maxWidth={"200px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    id={"bill-date"}
                                    onChange={(newValue) => {
                                      formik.setFieldValue(
                                        "billDate",
                                        dayjs(newValue).format("YYYY-MM-DD")
                                      );
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 2*/}
                    <Box
                      display={"flex"}
                      sx={{ justifyContent: { xs: "start", md: "center" } }}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"} mb={"10px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Customer
                          </Typography>
                          <Box minWidth={"170px"}>
                            <FormControl fullWidth>
                              <TextField
                                /*{...formik.getFieldProps('vendor')}*/
                                value={billNote.customer.name}
                                disabled={true}
                                InputLabelProps={{ style: { color: "red" } }}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Due Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="due-date"
                              defaultValue={dayjs(formik.values.dueDate).format(
                                `YYYY-DD-MM`
                              )}
                              disabled={true}
                            />
                          </Box>
                        </Box>

                        <Box display={"flex"} mt={"15px"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Bill Date
                          </Typography>
                          <Box minWidth={"130px"}>
                            <TextField
                              id="bill-date"
                              defaultValue={dayjs(formik.values.dueDate).format(
                                `YYYY-DD-MM`
                              )}
                              disabled={true}
                            />
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingTop={"15px"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"12px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            P.O./S.O.
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}

                              <TextField
                                disabled
                                id="sopo-no"
                                label="SO/PO"
                                variant="outlined"
                                {...formik.getFieldProps("soPo")}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={"auto"}>
                    {/*    Box 3*/}
                    <Box
                      display={"flex"}
                      justifyContent={"start"}
                      flexDirection={"row"}
                    >
                      <Box>
                        <Box display={"flex"}>
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Box #
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                disabled={true}
                                id="box-number"
                                label=""
                                variant="outlined"
                                {...formik.getFieldProps("boxNumber")}
                              />
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingLeft={"10px"}
                          paddingTop={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Note
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <TextField
                                id="bill-note"
                                disabled={true}
                                label=""
                                variant="outlined"
                                multiline
                                rows={"5"}
                                {...formik.getFieldProps("note")}
                              />
                            </FormControl>
                          </Box>
                          <Button type={"submit"}>Submit</Button>
                        </Box>

                        <Box
                          display={"flex"}
                          paddingLeft={"10px"}
                          paddingTop={"10px"}
                        >
                          <Typography
                            variant={"h6"}
                            paddingLeft={"16px"}
                            paddingTop={"11px"}
                            paddingRight={"13px"}
                          >
                            Use %
                          </Typography>
                          <Box minWidth={"130px"}>
                            <FormControl fullWidth>
                              {/*<InputLabel>Vendor</InputLabel>*/}
                              <Switch
                                onChange={(e) => {
                                  setShowP(!showP);
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box></Box>
              </Box>
            </Box>

            <Box
              mt={"8rem"}
              display={"flex"}
              justifyContent={"center"}
              marginBottom={"10rem"}
            >
              <Box overflow={"scroll"}>
                <table className="form-table">
                  <thead>
                    <tr>
                      <td>Item No.</td>
                      <td>Expense Category (Purchase)</td>
                      <td>Expense Category (Sale)</td>
                      <td>Description</td>
                      <td>Qty</td>
                      <td>Qty Type</td>
                      <td>Old Purchase Price</td>
                      <td>Old Sell Price</td>
                      {showP && <td>Current Selling Price</td>}
                      {!showP && <td>Selling %</td>}
                      <td>Tax</td>
                      {/*<td>Amount After Applying sell %</td>*/}
                    </tr>
                  </thead>

                  <tbody>
                    {billItems &&
                      billItems.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            <TextField
                              id={`expense-category-${row.id}`}
                              disabled={true}
                              sx={{ width: 200 }}
                              type="text"
                              value={row.expense_category || null}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.expense_category`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `expense_category.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`expense-category-sale-${row.id}`}
                              sx={{ width: 200 }}
                              type="text"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `items.${index}.expense_category_sale`,
                                  e.target.value
                                );

                                formik.setFieldValue(
                                  `expense_category_sale.${index}`,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-description-${row.id}`}
                              minRows={2}
                              sx={{ width: 230 }}
                              multiline
                              value={row.description}
                              disabled
                              maxRows={10}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-${row.id}`}
                              value={row.qty}
                              disabled
                              sx={{ width: 80 }}
                              type="number"
                            />
                          </td>
                          <td>
                            <TextField
                              id={`item-qty-type-${row.id}`}
                              value={row.qty_type}
                              disabled
                              sx={{ width: 80 }}
                            />
                          </td>
                          <td>
                            <TextField
                              id={`old-purchase-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          <td>
                            <TextField
                              id={`old-selling-price-${row.id}`}
                              sx={{ width: 80 }}
                              type="number"
                              disabled
                              value={row.product.old_price}
                            />
                          </td>

                          {showP && (
                            <td>
                              <TextField
                                id={`current-selling-price-${row.id}`}
                                sx={{ width: 80 }}
                                type="number"
                                {...formik.getFieldProps(
                                  `current_sellprice.${index}`
                                )}
                              />
                            </td>
                          )}

                          {!showP && (
                            <td>
                              <TextField
                                id={`current-selling-percent-${row.id}`}
                                sx={{ width: 80 }}
                                type="number"
                                label={"Add %"}
                                {...formik.getFieldProps(
                                  `current_sellpercent.${index}`
                                )}
                              />
                            </td>
                          )}

                          <td>
                            {/*<Autocomplete*/}
                            {/*    options={taxes.map((tax) => tax.name)}*/}
                            {/*    sx={{ width: 240 }}*/}
                            {/*    renderInput={(params) => <TextField {...params} label="Choose" />}*/}
                            {/*    onChange={(event, value) => {*/}
                            {/*      if (value) {*/}
                            {/*        setFormValues(`tax.${index}`, value);*/}
                            {/*      } else {*/}
                            {/*        // Handle case where no value is selected (optional)*/}
                            {/*        setFormValues(`tax.${index}`, '');*/}
                            {/*      }*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <Autocomplete
                              disablePortal
                              disabled
                              key={`tax-autocomplete-${row.id}`}
                              options={taxes.map((tax, index) => ({
                                label: tax.name,
                                value: tax.tax_rate,
                                id: tax.id,
                                key: `tax-${index}`,
                              }))}
                              sx={{ width: 240 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Choose" />
                              )}
                            />
                          </td>

                          {/*<td style={{marginLeft: '10px'}}>*/}

                          {/*    <p>$ {row.Totalprice ? row.Totalprice : '0.00'}</p>*/}

                          {/*</td>*/}
                        </tr>
                      ))}
                  </tbody>
                </table>

                <Box mr={"1rem"}>
                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>SubTotal:</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*    <p>Total Amount: $</p>*/}
                  {/*    <p>{billNote.totalAmount ? billNote.totalAmount : 0.00}</p>*/}
                  {/*</Box>*/}

                  {/*<Box display={"flex"} justifyContent={"end"}>*/}
                  {/*  <p>Total (EUR at 0.0002351439)</p>*/}
                  {/*  <p>$0.00</p>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};
export default CreateInvoiceFormPage;
