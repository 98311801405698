import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as YUP from "yup";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";


const AddBillPage = () => {
  const { token } = useSelector((state) => state.USER_SLICE);
  const params = useParams()
  const id = params.id
  const BASE_URL = process.env.REACT_APP_BASE_URL;
 
  const dispatch = useDispatch()

  const getOneTaxDataToUpdate = async (id) =>{
    const axiosres = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/tax/${id}`,{
      headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
  })
    const {success} = axiosres.data
        
    if(success === true){
      const {name,tax_rate} = axiosres.data.data
      // dispatch(setOneTax({taxName:name,taxRate:tax_rate}))

      
    }

  }

 const createTax = async (name, tax_rate) => {
    const axiosRes = await axios.post(
      `${BASE_URL}/api/tax`,
      { name, tax_rate },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      }
    );
  };

  useEffect(()=>{
    
      if(id){
       getOneTaxDataToUpdate(id)
       
      }else if(!id){
        // dispatch(setOneTax({taxName:'',taxRate:0}))
      }
    
  })

  const formik = useFormik({
    initialValues: {
      taxName: '',
      taxPercentage:0,
    },
    validationSchema: YUP.object({
      taxName: YUP.string(),
      taxPercentage: YUP.number()
        .typeError("tax must be a number")
        .positive("tax % must be positive"),
    }),
    onSubmit: (values) => {
      createTax(values.taxName,values.taxPercentage)
    },
  });

  return (
    <>
      <Box padding={"2rem"}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box mb={"2rem"}>
                <h1>Create a new Tax</h1>
              </Box>

              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent={"center"}
              >
                <Grid item xs={"auto"}>
                  {/*    Box 1*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  >
                    <Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          variant={"h6"}
                          paddingLeft={"16px"}
                          paddingTop={"11px"}
                          paddingRight={"13px"}
                        >
                          TAX Name
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("taxName")}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        marginTop={"15px"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"h6"} padding={"15px"}>
                          TAX %
                        </Typography>
                        <Box minWidth={"170px"}>
                          <FormControl fullWidth>
                            <TextField
                              type={"number"}
                              error={
                                formik.touched.taxPercentage &&
                                Boolean(formik.errors.taxPercentage)
                              }
                              helperText={
                                formik.touched.taxPercentage &&
                                formik.errors.taxPercentage
                              }
                              sx={{ width: 170 }}
                              {...formik.getFieldProps("taxPercentage")}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 2*/}
                  <Box
                    display={"flex"}
                    sx={{ justifyContent: { xs: "start", md: "center" } }}
                    flexDirection={"row"}
                  ></Box>
                </Grid>

                <Grid item xs={"auto"}>
                  {/*    Box 3*/}
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDirection={"row"}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
              <Button type={"submit"}>Save</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default AddBillPage;
