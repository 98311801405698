import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products:[],
    isLoading:false
}

const productSlice = createSlice({
    name:"PRODUCTS-SLICE",
    initialState,
    reducers:{
        setProducts:(state,action)=>{
            const {products,isLoading} = action.payload

            state.products = products
            state.isLoading = isLoading
        },
        deleteSliceproduct: (state, action) => {
            const idToRemove = action.payload;
            state.products = state.products.filter((product) => product.id !== idToRemove);
        },
        updateSliceProduct: (state, action) => {
            const {updatedProduct,nid} = action.payload

            state.products = state.products.map((product) =>
                product.id === nid ? updatedProduct : product
            );
            console.log(updatedProduct)
        }
    }
})

export const {
    setProducts,
    updateSliceProduct,
    deleteSliceproduct
} = productSlice.actions

export default productSlice.reducer